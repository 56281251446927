import classes from "./SavedSearchItemGroup.module.css";
import roundtripArr from "src/assets/img/roundtripArr.png";
import onewayArr from "src/assets/img/onewayArr.png";
import historyIcon from "src/assets/img/historyIcon.png";
import { getDisplayDateValueNoYear } from "src/utils/date-utils";
import { TRIP_TYPE } from "src/constants";
import { useTenantContext } from "src/context/tenant-context";

export function SavedSearchItem({ item, onSelect }) {
  const tripTypeIcon = item.tripType === TRIP_TYPE.roundtrip ? roundtripArr : onewayArr;
  const { shortDateFormat } = useTenantContext();

  return (
    <li className={classes.savedSearchItem} onClick={onSelect}>
      <img height={"18px"} alt="" src={historyIcon} />
      <div className={classes.container}>
        <p>
          {item.srcLocation.city}
          <img height={"12px"} alt="" src={tripTypeIcon} />
          {item.destLocation.city}
        </p>
        <p>
          {getDisplayDateValueNoYear(item.startDate, shortDateFormat)}
          {item.endDate && <span>|</span>}
          {getDisplayDateValueNoYear(item.endDate, shortDateFormat)}
        </p>
      </div>
      <svg
        style={{ transform: "scale(1.2)" }}
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="Vector"
          d="M1.49976 1.16669L6.49976 7.00002L1.49976 12.8334"
          stroke="var(--ui-5)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </li>
  );
}
