import { SORT } from ".";

export const SORT_TYPE_MAP = {
  [SORT.best]: "res.sort.best",
  [SORT.cheapest]: "res.sort.cheapest",
  [SORT.fastest]: "res.sort.fastest",
  [SORT.earlyDeparture]: "res.sort.departure",
  [SORT.earlyArrival]: "res.sort.arrival",
};

export const AIRLINE_CABIN_TYPE = {
  E: "sf.cabin.type.economy",
  B: "sf.cabin.type.business",
  F: "sf.cabin.type.first",
  W: "sf.cabin.type.comfort",
  A: "sf.cabin.type.all",
};

export const WEEKDAY_SHORT_STR_MAP = [
  "calendar.weekday.mon",
  "calendar.weekday.tue",
  "calendar.weekday.wed",
  "calendar.weekday.thu",
  "calendar.weekday.fri",
  "calendar.weekday.sat",
  "calendar.weekday.sun",
];

export const ALL_MONTHS_MAP = [
  "calendar.month.jan",
  "calendar.month.feb",
  "calendar.month.mar",
  "calendar.month.apr",
  "calendar.month.may",
  "calendar.month.jun",
  "calendar.month.jul",
  "calendar.month.aug",
  "calendar.month.sep",
  "calendar.month.oct",
  "calendar.month.nov",
  "calendar.month.dec",
];

export const STRING_RES_KEYS_ALL = [
  "home.footer.text",
  "main.nav.airtickets",
  "main.nav.cheapflights",
  "main.nav.hotels",
  "main.nav.insurance",
  "main.nav.business",
  "main.nav.sidebar.text",
  "main.nav.login",
  "main.nav.account.create",
  "main.nav.language",
  "main.nav.discover",
  "main.nav.terms",
  "main.nav.company",
  "main.nav.help",
  "res.baggage.cabin.buttonText",
  "res.baggage.cabin.suggestionText",
  "res.baggage.checked.buttonText",
  "res.baggage.checked.suggestionText",
  "res.filter.active",
  "res.filter.active.clear",
  "res.filter.airlines.only",
  "res.filter.baggage.cabin",
  "res.filter.baggage.checked",
  "res.filter.button.cancel",
  "res.filter.button.done",
  "res.filter.duration.all",
  "res.filter.duration.any",
  "res.filter.duration.hours",
  "res.filter.price.norange",
  "res.filter.stops.any",
  "res.filter.stops.direct",
  "res.filter.stops.one",
  "res.filter.stops.two",
  "res.filter.times.allday",
  "res.filter.times.from",
  "res.filter.times.to",
  "res.filter.title.airlines",
  "res.filter.title.airports",
  "res.filter.title.baggage",
  "res.filter.title.duration",
  "res.filter.title.duration.maxTravel",
  "res.filter.title.duration.stopover",
  "res.filter.title.price",
  "res.filter.title.stops",
  "res.filter.title.times",
  "res.filter.title.times.arrival",
  "res.filter.title.times.departure",
  "res.filter.title.times.departureTrip",
  "res.filter.title.times.returnTrip",
  "res.mobile.summary.button",
  "res.notfound.filters.button",
  "res.notfound.filters.message.heading",
  "res.notfound.filters.message.text",
  "res.notfound.network.button",
  "res.notfound.network.message.heading",
  "res.notfound.network.message.text",
  "res.notfound.search.button",
  "res.notfound.search.message.heading",
  "res.notfound.search.message.text",
  "res.panel.filter.flights",
  "res.panel.filter.of",
  "res.result.button.more",
  "res.result.button.select",
  "res.result.fee.service",
  "res.result.price.final",
  "res.result.price.perPerson",
  "res.result.seatsLeft",
  "link.text.showDetails",
  "res.result.stop.many",
  "res.result.stop.none",
  "res.result.stop.one",
  "res.result.tag.best",
  "res.result.tag.cheapest",
  "res.result.operatedBy",
  "res.sort.arrival",
  "res.sort.best",
  "res.button.close",
  "res.sort.cheapest",
  "res.sort.departure",
  "res.sort.fastest",
  "res.sort.other.label",
  "res.sort.title",
  "sf.button.search",
  "sf.travelType.roundtrip",
  "sf.travelType.oneway",
  "sf.travelType.multicity",
  "sf.desktop.multi.button.add",
  "sf.desktop.title",
  "sf.field.date.depart",
  "sf.field.date.return",
  "sf.field.passengers",
  "sf.input.placeholder",
  "sf.label.from",
  "sf.label.to",
  "sf.mobile.button.back",
  "sf.mobile.button.modify",
  "sf.mobile.input.label.from",
  "sf.mobile.input.label.to",
  "sf.mobile.subtitle.from",
  "sf.mobile.subtitle.to",
  "sf.mobile.title",
  "sf.mobile.title.from",
  "sf.mobile.title.to",
  "sf.multi.button.add",
  "sf.multi.flight",
  "sf.opt.accommodation",
  "sf.opt.direct",
  "sf.passenger.opt.button.cancel",
  "sf.passenger.opt.button.done",
  "sf.passenger.opt.title.cabin",
  "sf.passenger.opt.title.passengers",
  "sf.passenger.type.adults",
  "sf.passenger.type.children",
  "sf.passenger.type.infants",
  "sf.passenger.age.adults",
  "sf.passenger.age.children",
  "sf.passenger.age.infants",
  "sf.cabin.type.economy",
  "sf.cabin.type.premiumEconomy",
  "sf.cabin.type.business",
  "sf.cabin.type.first",
  "sf.cabin.type.comfort",
  "sf.cabin.type.all",
  "calendar.month.jan",
  "calendar.month.feb",
  "calendar.month.mar",
  "calendar.month.apr",
  "calendar.month.may",
  "calendar.month.jun",
  "calendar.month.jul",
  "calendar.month.aug",
  "calendar.month.sep",
  "calendar.month.oct",
  "calendar.month.nov",
  "calendar.month.dec",
  "calendar.weekday.mon",
  "calendar.weekday.tue",
  "calendar.weekday.wed",
  "calendar.weekday.thu",
  "calendar.weekday.fri",
  "calendar.weekday.sat",
  "calendar.weekday.sun",
  "calendar.weekday.mon.fullname",
  "calendar.weekday.tue.fullname",
  "calendar.weekday.wed.fullname",
  "calendar.weekday.thu.fullname",
  "calendar.weekday.fri.fullname",
  "calendar.weekday.sat.fullname",
  "calendar.weekday.sun.fullname",
  "sf.result.empty",
  "sf.savedSearch.title",
  "booking.indicator.step.results",
  "booking.indicator.step.fares",
  "booking.indicator.step.passengers",
  "booking.indicator.step.guarantee",
  "booking.indicator.step.seats",
  "booking.indicator.step.payment",
  "booking.title.step.passengers",
  "booking.title.step.guarantee",
  "booking.title.step.insurance",
  "booking.title.step.extras",
  "booking.title.step.seats",
  "booking.title.step.support",
  "booking.title.step.checkout",
  "booking.passengers.contact.title",
  "booking.passengers.contact.label.email",
  "booking.passengers.contact.label.phone",
  "booking.passengers.contact.sms",
  "booking.passengers.contact.newsletter",
  "booking.passengers.signin.title",
  "booking.passengers.signin.feature1",
  "booking.passengers.signin.feature2",
  "booking.passengers.signin.feature3",
  "booking.passengers.signin.continueWith",
  "booking.connection.guarantee.title",
  "booking.connection.guarantee.subtitle",
  "booking.connection.guarantee.description",
  "booking.connection.guarantee.desc.guaranteeName",
  "booking.connection.guarantee.option.no",
  "booking.connection.guarantee.option.no.desc",
  "booking.connection.guarantee.option.yes",
  "booking.connection.guarantee.option.yes.feature1",
  "booking.connection.guarantee.option.yes.feature2",
  "booking.connection.guarantee.option.yes.feature3",
  "booking.checkin.title",
  "booking.checkin.description",
  "booking.checkin.learnMore",
  "booking.checkin.feature1",
  "booking.checkin.feature2",
  "booking.checkin.feature3",
  "booking.checkin.add.button",
  "booking.checkin.note.prePerPassenger",
  "booking.form.passenger.title",
  "booking.form.passenger.info.names",
  "booking.form.passenger.name",
  "booking.form.passenger.surname",
  "booking.form.passenger.nationality",
  "booking.form.passenger.gender",
  "booking.form.passenger.dob",
  "input.form.field.eg",
  "booking.form.passenger.field.select",
  "booking.form.passenger.field.day",
  "booking.form.passenger.field.month",
  "booking.form.passenger.field.year",
  "booking.form.baggage.popular",
  "booking.form.baggage.cabin.subtitle",
  "booking.form.baggage.cabin.personal",
  "booking.form.baggage.cabin.personal.info",
  "booking.form.baggage.cabin",
  "booking.form.baggage.priority",
  "booking.form.baggage.cabin.bundle",
  "booking.form.baggage.checked.subtitle",
  "booking.form.baggage.checked.single",
  "booking.form.baggage.checked.double",
  "booking.form.baggage.protection",
  "booking.form.baggage.protection.name",
  "booking.form.baggage.protection.feature1",
  "booking.form.baggage.protection.feature2",
  "booking.form.baggage.protection.feature3",
  "booking.form.baggage.without",
  "booking.form.insurance.subtitle",
  "booking.form.insurance.providedBy",
  "booking.form.passenger.info.appliesTo",
  "booking.form.passenger.info.covid",
  "booking.form.added.travelPlus",
  "booking.form.change.travelPlus",
  "booking.form.passenger.gender.male",
  "booking.form.passenger.gender.female",
  "booking.form.passenger.child.title",
  "booking.form.passenger.infant.title",
  "booking.form.passenger.infant.fare",
  "booking.form.passenger.infant.fare.feature1",
  "booking.form.passenger.infant.fare.feature2",
  "booking.form.passenger.secondary.title",
  "booking.form.baggage.cabin.unavailable",
  "booking.form.baggage.checked.unavailable",
  "booking.form.passenger.add.title",
  "booking.form.passenger.add.button",
  "booking.form.passenger.remove.button",
  "booking.connection.guarantee.option.warn",
  "booking.order.tooltip.text",
  "booking.order.tooltip.text.total",
  "booking.order.tooltip.text.baseFare",
  "booking.order.tooltip.text.serviceFee",
  "booking.order.tooltip.text.otherFees",
  "booking.order.title",
  "booking.order.breakdown",
  "booking.order.breakdown.adult",
  "booking.order.breakdown.child",
  "booking.order.breakdown.baggage.checked",
  "booking.order.breakdown.baggage.cabinBundle",
  "booking.order.breakdown.baggage.personal",
  "booking.order.breakdown.serviceFee",
  "booking.order.breakdown.total",
  "booking.order.ticketsLeft.ticket.singular",
  "booking.order.ticketsLeft.ticket.plural",
  "calendar.month.jan.short",
  "calendar.month.feb.short",
  "calendar.month.mar.short",
  "calendar.month.apr.short",
  "calendar.month.may.short",
  "calendar.month.jun.short",
  "calendar.month.jul.short",
  "calendar.month.aug.short",
  "calendar.month.sep.short",
  "calendar.month.oct.short",
  "calendar.month.nov.short",
  "calendar.month.dec.short",
  "booking.order.breakdown.cabinBundle",
  "booking.order.breakdown.connectionProtection",
  "booking.order.breakdown.changeProtection",
  "booking.order.breakdown.onlineCheckin",
  "booking.order.breakdown.travelInsurance",
  "booking.order.breakdown.seatType",
  "booking.order.breakdown.supportService",
  "booking.order.breakdown.airHelpService",
  "booking.order.breakdown.flightsSMSService",
  "booking.order.breakdown.checkedBaggageProtection",
  "booking.order.breakdown.infant",
  "booking.order.breakdown.bookingSMSService",
  "booking.order.breakdown.seatTypeWindow",
  "booking.order.breakdown.seatTypeLegroom",
  "booking.order.breakdown.seatTypeAisle",
  "booking.order.breakdown.seatTypeTogether",
  "booking.order.price.lockin.title",
  "booking.order.price.lockin.description",
  "booking.order.price.lockin.button",
  "booking.order.breakdown.lockin",
  "booking.breakdown.class",
  "booking.title.step.mob.passengers",
  "booking.title.step.mob.guarantee",
  "booking.title.step.mob.insurance",
  "booking.title.step.mob.extras",
  "booking.title.step.mob.seats",
  "booking.title.step.mob.support",
  "booking.title.step.mob.checkout",
  "booking.description.step.mob.passengers",
  "booking.description.step.mob.guarantee",
  "booking.description.step.mob.seats",
  "booking.description.step.mob.extras",
  "booking.description.step.mob.support",
  "booking.description.step.mob.checkout",
  "booking.mob.order.title",
  "booking.mob.order.showDetails",
  "booking.mob.connection.guarantee.conditions",
  "booking.mob.connection.guarantee.description",
  "booking.mob.form.baggage.protection",
  "booking.mob.form.baggage.protection.add",
  "booking.continue.button",
  "booking.mob.secure.title",
  "booking.mob.secure.description",
  "booking.mob.bottom.paySecurely",
  "booking.mob.bottom.feesIncluded",
  "res.modal.refresh.title",
  "res.modal.refresh.description",
  "res.modal.refresh.button",
  "res.modal.signin.title",
  "res.modal.signin.description",
  "res.modal.signin.button",
  "res.modal.signin.or",
  "res.modal.signin.guest.button",
  "booking.indicator.step.additional",
  "booking.indicator.step.insurance",
  "booking.indicator.step.support",
  "sf.button.remove",
  "login.modal.title",
  "login.modal.description",
  "login.modal.button.email",
  "login.modal.button.facebook",
  "login.modal.button.google",
  "login.modal.button.apple",
  "login.modal.button.incorrectEmail",
  "login.modal.terms.text",
  "login.modal.terms.linkText",
  "login.modal.privacy.text",
  "login.modal.privacy.linkText",
  "res.email.subscribe.title",
  "res.email.subscribe.button",
  "res.fee.disclaimer",
  "res.agent.title",
  "res.agent.text1",
  "res.agent.text2",
  "res.agent.tel",
  "booking.back.button",
  "booking.change.guarantee.title",
  "booking.change.guarantee.description",
  "booking.change.guarantee.desc.learnMore",
  "booking.change.guarantee.desc.guaranteeName",
  "booking.change.guarantee.option.no",
  "booking.change.guarantee.option.yes",
  "booking.change.guarantee.option.yes.feature1",
  "booking.change.guarantee.option.yes.feature2",
  "booking.change.guarantee.option.yes.feature3",
  "booking.change.guarantee.tag",
  "booking.change.guarantee.option.warn",
  "booking.mob.change.guarantee.description",
  "booking.mob.change.guarantee.conditions",
  "booking.mob.change.guarantee.option.features",
  "booking.mob.service.add.button",
  "booking.mob.service.added.button",
  "booking.email.tooltip.info",
  "booking.tel.tooltip.info",
  "booking.email.tooltip.warning.required",
  "booking.tel.tooltip.warning.required",
  "booking.email.tooltip.warning.invalid",
  "booking.tel.tooltip.warning.invalid",
  "booking.insurance.title",
  "booking.insurance.description",
  "booking.insurance.option.plus",
  "booking.insurance.option.basic",
  "booking.insurance.option.none",
  "booking.insurance.option.warn",
  "booking.insurance.note",
  "booking.insurance.option.plus.features",
  "booking.insurance.option.basic.features",
  "booking.service.price.perDay",
  "booking.service.price.day",
  "booking.service.price.perPassenger",
  "booking.insurance.mob.note",
  "res.result.info.daysOffset.one",
  "res.result.info.daysOffset.many",
  "res.result.info.pinned.departure",
  "res.result.info.pinned.return",
  "res.result.info.layover",
  "res.result.info.differentAirports",
  "booking.seats.title",
  "booking.seats.features",
  "booking.seats.note.title",
  "booking.seats.note.text",
  "booking.seats.seatMap.none.title",
  "booking.seats.choose.text",
  "booking.seats.option.price.free",
  "booking.seats.option.random",
  "booking.seats.option.window",
  "booking.seats.option.aisle",
  "booking.seats.option.legroom",
  "booking.seats.option.together",
  "booking.seats.seating",
  "booking.seats.tag.legroom",
  "booking.seats.loading",
  "booking.seats.previous.button",
  "booking.seats.next.button",
  "booking.extras.option.checkin",
  "booking.extras.option.sms",
  "booking.extras.option.airhelp",
  "booking.extras.option.checkin.features",
  "booking.extras.option.sms.features",
  "booking.extras.option.airhelp.features",
  "booking.extras.option.checkin.description",
  "booking.extras.option.sms.description",
  "booking.service.price.perPassengerPerSeg",
  "link.text.moreInformation",
  "booking.service.price.perBooking",
  "booking.service.price.perSegment",
  "booking.service.price.perAirport",
  "booking.service.price.perDirection",
  "booking.support.title",
  "booking.support.description",
  "booking.support.option.basic.title",
  "booking.support.option.plus.title",
  "booking.support.option.premium.title",
  "booking.support.mob.option.basic.title",
  "booking.support.mob.option.plus.title",
  "booking.support.mob.option.premium.title",
  "booking.support.option.basic.button",
  "booking.support.option.plus.button",
  "booking.support.mob.option.plus.button",
  "booking.support.option.premium.button",
  "booking.support.option.recommended",
  "booking.support.option.included",
  "booking.support.option.popular",
  "booking.support.option.tag.recommended",
  "booking.modal.price.refresh.title",
  "booking.modal.price.refresh.description",
  "booking.modal.price.refresh.button",
  "booking.modal.resume.title",
  "booking.modal.resume.description",
  "booking.modal.resume.button",
  "booking.modal.mob.resume.button",
  "booking.modal.resume.skip.button",
  "share.modal.title",
  "share.modal.description",
  "share.modal.copy.button",
  "share.modal.option.whatsapp",
  "share.modal.option.messenger",
  "share.modal.option.iosMessages",
  "share.modal.option.telegram",
  "share.modal.option.viber",
  "share.modal.option.email",
  "regional.modal.title",
  "regional.modal.description",
  "regional.modal.language.title",
  "regional.modal.country.title",
  "regional.modal.currency.title",
  "regional.modal.save.button",
  "booking.checkout.summary.title",
  "booking.checkout.summary.description",
  "booking.checkout.summary.category.personal",
  "booking.checkout.summary.category.onboard",
  "booking.checkout.summary.category.insurance",
  "booking.checkout.summary.category.services",
  "booking.checkout.summary.category.extras",
  "booking.checkout.summary.category.edit",
  "booking.checkout.summary.personalItem",
  "booking.checkout.summary.cabinBaggage",
  "booking.checkout.summary.fastTrack",
  "booking.checkout.summary.registeredBaggage",
  "booking.checkout.summary.baggageProtection",
  "booking.checkout.summary.randomSeats",
  "booking.checkout.summary.windowSeats",
  "booking.checkout.summary.legroomSeats",
  "booking.checkout.summary.aisleSeats",
  "booking.checkout.summary.togetherSeats",
  "booking.checkout.summary.insuranceBasic",
  "booking.checkout.summary.insurancePlus",
  "booking.checkout.summary.noInsurance",
  "booking.checkout.summary.supportBasic",
  "booking.checkout.summary.supportPlus",
  "booking.checkout.summary.supportPremium",
  "booking.checkout.summary.moneyback",
  "booking.checkout.summary.noMoneyback",
  "booking.checkout.summary.flightCheckin",
  "booking.checkout.summary.noFlightCheckin",
  "booking.checkout.summary.connectionProtection",
  "booking.checkout.summary.noConnectionProtection",
  "booking.checkout.summary.flightsSMS",
  "booking.checkout.summary.noFlightsSMS",
  "booking.checkout.summary.bookingSMS",
  "booking.checkout.summary.noBookingSMS",
  "booking.checkout.summary.airhelp",
  "booking.checkout.summary.noAirhelp",
  "booking.checkout.summary.title.mob",
  "booking.checkout.billing.title",
  "booking.checkout.billing.vat.option",
  "booking.checkout.billing.vat.company",
  "booking.checkout.billing.vat.person",
  "booking.checkout.billing.company.name",
  "booking.checkout.billing.company.address",
  "booking.checkout.billing.company.registration",
  "booking.checkout.billing.company.vat",
  "booking.checkout.billing.company.country",
  "booking.checkout.billing.person.name",
  "booking.checkout.billing.person.address",
  "booking.checkout.billing.person.surname",
  "booking.checkout.billing.person.country",
  "booking.checkout.billing.title.mob",
  "booking.checkout.payment.title",
  "booking.checkout.payment.option.bank",
  "booking.checkout.payment.option.revolut",
  "booking.checkout.payment.option.card",
  "booking.checkout.payment.option.change",
  "booking.checkout.payment.note",
  "booking.checkout.payment.terms",
  "booking.checkout.payment.terms.conditions",
  "booking.checkout.payment.terms.guarantee",
  "booking.checkout.payment.terms.refunds",
  "booking.checkout.payment.terms.privacy",
  "booking.checkout.payment.deals.optout",
  "booking.checkout.payment.deals.optout.info",
  "booking.checkout.payment.pay",
  "res.details.flight.title",
  "res.details.flight.warning.connection",
  "res.details.flight.warning.selfTransfer",
  "res.details.flight.warning.diff.transfer",
  "res.details.flight.warning.diff.departure",
  "res.details.flight.fare.details",
  "res.details.flight.selfTransfer.title",
  "res.details.flight.selfTransfer.desc",
  "res.details.flight.fare.personalItem",
  "res.details.flight.fare.carryon",
  "res.details.flight.fare.checked",
  "res.details.flight.fare.carryon.none",
  "res.details.flight.fare.checked.none",
  "res.details.flight.fare.priority",
  "res.details.flight.fare.priority.none",
  "res.details.flight.fare.seat.none",
  "res.details.flight.fare.seat",
  "res.details.flight.fare.changes.none",
  "res.details.flight.fare.refund.none",
  "res.details.flight.title.mob",
  "res.details.flight.direction.to",
  "res.details.flight.button.continue",
  "res.details.flight.price.ticket",
  "res.baggage.details.title",
  "res.baggage.details.included",
  "res.baggage.details.button.add",
  "res.result.button.save",
  "res.result.button.share",
  "res.baggage.details.unknown",
  "res.panel.filter.foundSoFar",
  "res.panel.filter.showingTrips",
  "login.modal.email.title",
  "login.modal.email.label",
  "login.modal.email.placeholder",
  "login.modal.email.continue.button",
  "login.modal.email.signin.button",
  "login.modal.password.desc",
  "login.modal.password.label",
  "login.modal.password.forgot",
  "login.modal.error.credentials",
  "menu.user.trips",
  "menu.user.passengers",
  "menu.user.invoices",
  "menu.user.priceAlerts",
  "menu.user.referral",
  "menu.user.preferences",
  "menu.user.signout",
  "regional.language.latvian",
  "regional.language.english",
  "regional.language.lithuanian",
  "regional.language.russian",
  "regional.language.estonian",
  "share.modal.copied",
  "login.modal.user.signin.exists",
  "register.modal.title",
  "register.modal.description",
  "register.modal.field.email",
  "register.modal.field.password",
  "register.modal.password.weak.title",
  "register.modal.password.weak.info",
  "register.modal.password.strength.weak",
  "register.modal.password.strength.medium",
  "register.modal.password.strength.strong",
  "register.modal.password.strength.maxstrong",
  "register.modal.back.button",
  "register.modal.create.button",
  "register.modal.terms",
  "register.modal.warning.password.length",
  "register.modal.warning.email",
  "register.modal.error.emailInUse",
  "register.modal.error.weakPassword",
  "register.modal.verify.title",
  "register.modal.verify.desc",
  "reset.password.modal.title",
  "reset.password.modal.desc",
  "button.general.details",
  "booking.form.baggage.cabin.bundle.upgrade",
  "booking.order.breakdown.cabinBundleUpgrade",
  "booking.checkout.summary.cabinBundleUpgrade",
  "booking.passenger.group.age.child",
  "booking.passenger.group.age.infant",

  "main.nav.support",
  "main.nav.platform",
  "sf.mob.datepicker.select",
  "sf.field.passenger.single",
  "sf.field.passenger.plural",
  "booking.user.anonymous.name",
  "error.general.message",
  "payment.type.card.description.display",
  "payment.type.bank.description.display",
  "order.payment.success.title",
  "order.payment.order.num",
  "order.payment.order.status",
  "order.payment.success.note",
  "button.general.homepage",
  "button.general.booking.restart",
  "button.general.order.details",
  "order.payment.error.title",
  "order.payment.error.note",
  "booking.indicator.orderID",
  "airport.search.popular.none",
  "button.general.dashboard",
  "booking.cancelled.title",
  "booking.cancelled.description",
  "booking.service.details.button.close",
  "booking.checkout.payment.option.transfer",
  "booking.checkout.payment.option.offline",
  "booking.checkout.order.place",
  "order.unpaid.received.title",
  "confirmed.order.success.title",
  "confirmed.order.payment.method.bank",
  "confirmed.order.payment.method.offline",
  "confirmed.order.bank.transfer.info",
  "confirmed.order.bank.transfer.confirm",
  "confirmed.order.bank.transfer.email",
  "confirmed.order.offline.payment.info",
  "button.download.invoice",
  "booking.form.baggage.free",
  "payment.options.print.invoice",
  "payment.options.payment.options",
  "payment.transfer.info1",
  "payment.transfer.info2",
  "prepayment.invoice.info",
  "payment.offline.visit.office.description",
  "payment.offline.form.info.description",
  "sf.airports.all",
  "main.nav.discover.newsletter",
  "main.nav.discover.stories",
  "main.nav.terms.tos",
  "main.nav.terms.privacy",
  "main.nav.terms.refund",
  "main.nav.terms.guarantee",
  "main.nav.company.about",
  "main.nav.help.faq",
  "main.nav.help.support",

  "main.nav.groups",
  "dashboard.trips.timeAfter",
  "dashboard.trips.daysToGo_0",
  "dashboard.trips.daysToGo_1",
  "dashboard.trips.daysToGo_2",
  "dashboard.trips.daysToGo_5",
  "dashboard.trips.traveled",
  "dashboard.trips.expired",
  "dashboard.trips.unpaid",
  "dashboard.trips.tripNo",
  "dashboard.trips.checkIn",
  "dashboard.invoice.name",
  "dashboard.invoice.date",
  "dashboard.invoice.price",
  "dashboard.invoice.action",
  "dashboard.invoice.download",
  "dashboard.invoice.title",
  "dashboard.invoice.word",
  "dashboard.invoice.search.placeholder",
  "dashboard.invoice.sort.dateDesc",
  "dashboard.invoice.sort.dateAsc",
  "dashboard.invoice.sort.priceDesc",
  "dashboard.invoice.sort.priceAsc",
  "dashboard.invoice.sort.date",
  "dashboard.invoice.sort.date.newest",
  "dashboard.invoice.sort.date.oldest",
  "dashboard.invoice.sort.price",
  "dashboard.invoice.sort.price.high",
  "dashboard.invoice.sort.price.low",
  "dashboard.invoice.sort.button",
  "dashboard.trips.back",
  "dashboard.trips.documents.error",
  "dashboard.trips.empty",
  "dashboard.trips.empty.description",
  "dashboard.trips.empty.cta",
  "dashboard.trips.empty.button",
  "dashboard.trips.future.title",
  "dashboard.trips.past.title",
  "dashboard.trips.title",
  "main.term.traveler",
  "menu.user.support",
  "dashboard.trips.tripsCountMore",
  "dashboard.trips.showAll",
  "dashboard.invoice.no.results",
  "dashboard.invoice.adjust.filters",
  "dashboard.invoice.reset.search",
  "trip.contact.field.contactName",
  "trip.contact.field.email",
  "trip.contact.field.phoneNumber",
  "trip.contact.title",
  "trip.contact.note",
  "dashboard.trips.documents.download.title",
  "dashboard.trips.documents.download.all.button",
  "dashboard.trips.documents.download.button",
  "dashboard.trips.trip.direct",
  "dashboard.trips.trip.withStopNum",
  "main.term.booking",
  "main.term.booking.no",
  "dashboard.trips.itinerary",
  "dashboard.trips.passengers_1",
  "dashboard.trips.passengers_2",
  "dashboard.trips.passengers_5",
  "dashboard.trips.status.unpaid",
  "dashboard.trips.view.details",
  "document.type.passport",
  "document.type.nationalId",
  "document.type.drivingLicence",
  "trip.passenger.document.title",
  "document.issueDate.error",
  "document.expiryDate.error",
  "main.term.cancel",
  "main.term.submit",
  "booking.form.passenger.name",
  "booking.form.passenger.nationality",
  "booking.form.passenger.surname",
  "booking.form.passenger.gender",
  "booking.form.passenger.dob",
  "trip.passenger.field.type",
  "trip.passenger.field.documentNumber",
  "trip.passenger.field.issueDate",
  "trip.passenger.field.expiryDate",
  "trip.passenger.field.country",
  "booking.form.passenger.gender.female",
  "booking.form.passenger.gender.male",
  "trip.passenger.details.title",
  "trip.passenger.info.appliesTo",
  "trip.passenger.info.note",
  "trip.passenger.info.note2",
  "trip.passenger.info.note3",
  "trip.passenger.info.document.cta",
  "modal.button.cancel",
  "modal.button.submit",
  "modal.button.close",
  "trip.support.modal.send",
  "trip.support.modal.heading",
  "trip.support.modal.description",
  "trip.support.modal.topic.services",
  "trip.support.modal.topic.checkin",
  "trip.support.modal.topic.insurance",
  "trip.support.modal.topic.baggage",
  "trip.support.modal.topic.payment",
  "trip.support.modal.topic.cancellation",
  "trip.support.modal.topic.other",
  "trip.support.modal.placeholder",
  "trip.support.modal.topic.error",
  "trip.support.modal.success",
  "trip.support.modal.error",
  "trip.support.block.title",
  "trip.support.block.button",
  "res.details.flight.warning.selfTransfer.readMore",
  "cheap.travel.deals.title",
  "cheap.travel.deals.description",
  "cheap.travel.deals.button.more",
  "cheap.travel.deals.button.less",
  "cheap.travel.deals.filter.50orLess",
  "cheap.travel.deals.filter.citybreaks",
  "cheap.travel.deals.filter.onlydirect",
  "cheap.travel.deals.filter.family",

  "cheap.travel.deals.item.flightsFrom",
  "business.booking.header.title",
  "business.booking.header.description",
  "business.booking.header.slogan",
  "business.booking.title",
  "business.booking.groupSizeInfo",
  "business.booking.instructions",
  "business.booking.businessInfo",
  "business.booking.company.name",
  "business.booking.company.registration.num",
  "business.booking.company.vat.num",
  "business.booking.company.address",
  "business.booking.company.country",
  "group.booking.email",
  "group.booking.phone.code",
  "group.booking.phone.number",
  "group.booking.travelInformation",
  "group.booking.travelType.roundtrip",
  "group.booking.travelType.oneway",
  "group.booking.departure.city",
  "group.booking.destination.city",
  "group.booking.departure.date",
  "group.booking.return.date",
  "group.booking.departure.date.example",
  "group.booking.return.date.example",
  "group.booking.adults",
  "group.booking.children",
  "group.booking.infants",
  "group.booking.desired.price",
  "group.booking.additionalServicesPrompt",
  "group.booking.additional.opt.registeredBaggage",
  "group.booking.additional.opt.specialBaggage",
  "group.booking.additional.opt.airportAssistance",
  "group.booking.additional.opt.hotelReservation",
  "group.booking.additional.opt.insurance",
  "group.booking.additional.opt.paymentInstallments",
  "group.booking.additional.opt.refundableTicket",
  "group.booking.message",
  "group.booking.comments.suggestions",
  "group.booking.submit.request.button",
  "group.booking.header.title",
  "group.booking.header.description",
  "group.booking.header.slogan",
  "group.booking.title",
  "group.booking.groupSizeInfo",
  "group.booking.instructions",
  "group.booking.groupInformation",
  "group.booking.group.title",
  "group.booking.contact.name",
  "group.booking.groupTypePrompt",
  "group.booking.groupType.educational",
  "group.booking.groupType.sports",
  "group.booking.groupType.tourTravel",
  "group.booking.groupType.educational.detail",
  "group.booking.groupType.sports.detail",
  "group.booking.groupType.tourTravel.detail",
  "group.booking.groupType.artsPerformance",
  "group.booking.groupType.religious",
  "group.booking.groupType.other",
  "group.booking.groupType.artsPerformance.detail",
  "group.booking.groupType.religious.detail",
  "group.booking.groupType.other.detail",
  "group.booking.submit.error"
];

export const GENDER = {
  female: "booking.form.passenger.gender.female",
  male: "booking.form.passenger.gender.male",
};
