import { useEffect } from "react";

export default function useMousedownAway(componentRef, onMousedownAway) {
  useEffect(() => {
    const mousedownAwayHandler = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        // customLog('EXECUTING MOUSEDOWN AWAY');
        onMousedownAway();
      }
    };

    const timerId = setTimeout(() => {
      document.addEventListener("mousedown", mousedownAwayHandler);
    }, 1);

    return () => {
      clearTimeout(timerId);
      document.removeEventListener("mousedown", mousedownAwayHandler);
    };
  }, [componentRef, onMousedownAway]);
}