import { PASSENGER } from ".";

export const AGE_GROUP_MAX = {
  [PASSENGER.adult]: 125,
  [PASSENGER.child]: 12,
  [PASSENGER.infant]: 2,
};

export const AGE_GROUP_MIN = {
  [PASSENGER.adult]: 12,
  [PASSENGER.child]: 2,
  [PASSENGER.infant]: 0,
};

export const DIGIT_PATTERN = /^\d+$/;

export const EMAIL_PATTERN =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;

export const LATIN_LANG_PATTERN_NAME = /^[\p{Script=Latin}0-9 ,.'-]+$/u;
