import { BOOKING } from "src/constants/booking";
import { customLog } from "../utils";

function billingInfoListenerEffect(action, listenerApi, send) {
  customLog(action);
  const bookingActionsQueue = [];

  const billingState = listenerApi.getState().booking.billing;
  const bookingAction = {
    command: BOOKING.UPDATE_BILLING_INFO,
    payload: "",
  };

  if (billingState.isCompany) {
    bookingAction.payload = { ...billingState.company };
  } else {
    bookingAction.payload = { ...billingState.person };
  }
  bookingAction.payload.isCompany = billingState.isCompany;

  if (action.type === "booking/toggleBillingRequired") {
    if (!billingState.isVATRequired) {
      bookingAction.command = BOOKING.REMOVE_BILLING_INFO;
      bookingAction.payload = null;
    } else {
      bookingAction.command = BOOKING.ADD_BILLING_INFO;
    }
  } else if (action.type === "booking/changeBillingType") {
    bookingActionsQueue.push({
      command: BOOKING.REMOVE_BILLING_INFO,
      payload: "",
    });
    bookingAction.command = BOOKING.ADD_BILLING_INFO;
  }

  bookingActionsQueue.push(bookingAction);
  bookingActionsQueue.forEach((nextAction) => send(nextAction));
}

export function getBillingInfoListenerOpts(send) {
  const types = [
    "booking/toggleBillingRequired",
    "booking/changeBillingType",
    "booking/updatePersonalBillingData",
    "booking/updateCompanyBillingData",
  ];
  const billingListenerOpts = types.map((type) => {
    return {
      type: type,
      effect: (action, listenerApi) => billingInfoListenerEffect(action, listenerApi, send),
    };
  });
  return billingListenerOpts;
}
