import { isLangAbsent, redirectWithLang } from "./utils/loader-utils";
import { bookingOrderApi } from "src/services/api";
import { customLog } from "src/utils/utils";
import { defer, json } from "react-router-dom";

export async function loader({ request, user }) {
  console.log("userInvoicesLoader");
  if (isLangAbsent(request)) return redirectWithLang(request);

  if (!user) return null;

  const trips = bookingOrderApi
    .getUserTrips(user.uid)
    .then((allTripsRes) => {
      const trips = allTripsRes.data;
      trips.sort((a, b) => a.timeStamp - b.timeStamp);
      console.log(trips);
      return trips;
    })
    .catch((err) => {
      customLog(err);
      if (err.response?.status === 404) {
        return [];
      }
      throw json({ data: err });
    });

  return defer({ trips });
}
