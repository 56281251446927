import { useNavigate } from "react-router-dom";
import {
  ERROR_MESSAGES,
  ERROR_STATUS_MESSAGE,
  TRANSALTED_STRINGS,
} from "src/assets/lang/errorMessages";
import Button from "src/components/UI/Button/Button";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";

function TemporarilyUnavailable() {
  const { isMobile } = useDeviceContext();
  const navigate = useNavigate();
  const { currentLocale } = useLocaleContext();
  const lang = currentLocale?.split("_")[0] || "en";
  const errMsg = ERROR_MESSAGES[lang].unavailable;

  const goToHomePage = () => {
    navigate(`/?lang=${lang}`);
  };

  return (
    <section id="page-not-found" className={`body ${isMobile ? "mob" : "dsk"}`}>
      <div
        style={{
          width: isMobile ? "18rem" : "24rem",
          height: isMobile ? "11rem" : "15rem",
          backgroundColor: "var(--ui-3)",
        }}></div>
      <h1>{ERROR_STATUS_MESSAGE[lang].unavailable}</h1>
      <p>
        {errMsg.map((msg) => (
          <span key={msg}>{msg}</span>
        ))}
      </p>

      <Button
        opts={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
        name={TRANSALTED_STRINGS[lang].homepage}
        onClick={goToHomePage}
      />
    </section>
  );
}

export default TemporarilyUnavailable;
