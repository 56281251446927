import { LOGIN_VENDOR } from ".";
import { lazy } from "react";

export const MODAL_TYPE = {
  documentView: "documentViewModal",
  serviceDetails: "serviceDetailsModal",
  flightDetails: "flightDetailsModal",
  refreshPrice: "refreshPriceModal",
  staleBooking: "staleBookingModal",
  resumeBooking: "resumeBookingModal",
  regionalSettings: "regionalSettingsModal",
  shareOptions: "shareOptionsModal",
  loginOptions: "loginOptionsModal",
  signInOffer: "signInOfferModal",
  createAccount: "createAccountModal",
  verifyEmail: "verifyEmailModal",
  resetLinkSent: "resetLinkSentModal",
  [LOGIN_VENDOR.apple]: LOGIN_VENDOR.apple,
  [LOGIN_VENDOR.facebook]: LOGIN_VENDOR.facebook,
  [LOGIN_VENDOR.google]: LOGIN_VENDOR.google,
  [LOGIN_VENDOR.email]: LOGIN_VENDOR.email,
  tripList: "tripListModal",
  mobileUserMenu: "mobileUserMenuModal",
  userFlightDetails: "userFlightDetailsModal",
  passengerDocument: "passengerDocumentModal",
  requestOrderHelp: "requestOrderHelpModal",
  invoiceSortMenu: "invoiceSortMenuModal",
};

//lazy loaded components
const DocumentView = lazy(() =>
  import("src/components/features/booking/Documents/DocumentView")
);
const ServiceDescription = lazy(() =>
  import("src/components/features/booking/ServiceDescription/ServiceDescription")
);
const FlightDetails = lazy(() =>
  import("src/components/features/results/FlightDetails/FlightDetails")
);
const GetFreshPricesModal = lazy(() =>
  import("src/components/features/results/GetFreshPrices/GetFreshPrices")
);
const ResumeBookingModal = lazy(() =>
  import("src/components/features/booking/ResumeBookingModal/ResumeBookingModal")
);
const UpdateBookingModal = lazy(() =>
  import("src/components/features/booking/UpdateBookingModal/UpdateBookingModal")
);
const RegionalSettings = lazy(() =>
  import("src/components/shared/modal/RegionalSettings/RegionalSettings")
);
const ShareItinerary = lazy(() =>
  import("src/components/shared/modal/ShareItinerary/ShareItinerary")
);
const LoginOptions = lazy(() => import("src/components/shared/modal/Login/LoginOptions"));
const LoginByEmail = lazy(() => import("src/components/shared/modal/Login/LoginByEmail"));
const SignInPopup = lazy(() =>
  import("src/components/features/results/SignInPopup/SignInPopup")
);
const RegisterByEmail = lazy(() =>
  import("src/components/shared/modal/Registration/Registration")
);
const VerifyNewEmail = lazy(() =>
  import("src/components/shared/modal/Registration/VerifyNewEmail")
);
const ResetLinkSent = lazy(() => import("src/components/shared/modal/Login/ResetLinkSent"));
const TripListModal = lazy(() =>
  import("src/components/features/dashboard/TripList/TripListModal")
);
const MobileUserMenu = lazy(() =>
  import("src/components/shared/menu/UserMenu/MobileUserMenu")
);
// const UserFlightDetails = lazy(() =>
//   import("src/components/features/dashboard/")
// );
const PassengerDocumentModal = lazy(() =>
  import("src/components/features/dashboard/UserTripPassengers/PassengerDocumentModal")
);

const RequestSupportModal = lazy(() =>
  import("src/components/features/dashboard/UserTripRequestHelp/RequestSupportModal")
);

const InvoiceSortModal = lazy(() =>
  import("src/components/features/dashboard/UserInvoice/InvoiceSortModal")
);

export const MODAL_TYPE_COMPONENT = {
  [MODAL_TYPE.documentView]: { component: DocumentView, isStandalone: true },
  [MODAL_TYPE.serviceDetails]: { component: ServiceDescription },
  [MODAL_TYPE.flightDetails]: { component: FlightDetails },
  [MODAL_TYPE.refreshPrice]: { component: GetFreshPricesModal },
  [MODAL_TYPE.staleBooking]: { isStandalone: true, component: UpdateBookingModal },
  [MODAL_TYPE.resumeBooking]: { isStandalone: true, component: ResumeBookingModal },
  [MODAL_TYPE.regionalSettings]: { isStandalone: true, component: RegionalSettings },
  [MODAL_TYPE.shareOptions]: { isStandalone: true, component: ShareItinerary },
  [MODAL_TYPE.loginOptions]: { component: LoginOptions },
  [MODAL_TYPE.signInOffer]: { component: SignInPopup },
  [MODAL_TYPE.createAccount]: { component: RegisterByEmail },
  [MODAL_TYPE.verifyEmail]: { component: VerifyNewEmail },
  [MODAL_TYPE.resetLinkSent]: { component: ResetLinkSent },
  [LOGIN_VENDOR.apple]: { component: null },
  [LOGIN_VENDOR.facebook]: { component: null },
  [LOGIN_VENDOR.google]: { component: null },
  [LOGIN_VENDOR.email]: { component: LoginByEmail },
  [MODAL_TYPE.tripList]: { component: TripListModal, isStandalone: true },
  [MODAL_TYPE.mobileUserMenu]: { component: MobileUserMenu },
  [MODAL_TYPE.userFlightDetails]: { component: FlightDetails },
  [MODAL_TYPE.passengerDocument]: { component: PassengerDocumentModal, isStandalone: true },
  [MODAL_TYPE.requestOrderHelp]: { component: RequestSupportModal, isStandalone: true },
  [MODAL_TYPE.invoiceSortMenu]: { component: InvoiceSortModal },
};
