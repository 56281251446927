import { BOOKING } from "src/constants/booking";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { selectCatalogueItem } from "src/store/catalogue";
import { customLog } from "../utils";

export function getBaggageProtectionListenerOpts(send) {
  return {
    type: "order/changeCheckedBaggageProtection",
    effect: (action, listenerApi) => {
      customLog(action);

      const paxData = listenerApi
        .getState()
        .booking.passengers.find((p) => p.id === action.payload.passengerId);
      if (!paxData.passenger_id) return;

      if (paxData) {
        const bookingAction = {
          command: BOOKING.ADD_SERVICE_ADDITIONAL,
          payload: "",
        };

        const _new = listenerApi
          .getState()
          .order.baggage.find((b) => b.passengerId === action.payload.passengerId);
        const _old = listenerApi
          .getOriginalState()
          .order.baggage.find((b) => b.passengerId === action.payload.passengerId);

        const catalogue = listenerApi.getState().catalogue.catalogue;
        const serviceDetails = selectCatalogueItem(catalogue, CATALOGUE_IDS.BAG_PROTECTION);
        if (
          action.payload.serviceId === SERVICE_TYPE.NOT_SELECTED ||
          action.payload.serviceId === SERVICE_TYPE.REFUSED
        ) {
          bookingAction.command = BOOKING.REMOVE_SERVICE_ADDITIONAL;
          bookingAction.payload = {
            id: serviceDetails.data.id,
            backend_id: _old.protection._id,
          };
        } else {
          bookingAction.payload = { ...serviceDetails.data, backend_id: _new.protection._id };
        }
        bookingAction.payload.passenger_id = paxData.passenger_id;
        bookingAction.payload.passenger_client_id = paxData.id;

        send(bookingAction);
      }
    },
  };
}
