import { Link, NavLink, useLocation } from "react-router-dom";

export function NavLinkWithQuery({ to, children, ...props }) {
  const { search } = useLocation();

  return (
    <NavLink to={to + search} {...props}>
      {children}
    </NavLink>
  );
}

export function LinkWithQuery({ to, children, ...props }) {
  const { search } = useLocation();

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
}
