import { createSelector, createSlice } from "@reduxjs/toolkit";
import { SORT } from "src/constants";
import { customLog } from "src/utils/utils";
import { filterSortModel } from "./models";

const filterSortSlice = createSlice({
  name: "filterSort",
  initialState: filterSortModel,
  reducers: {
    setSortType(state, action) {
      customLog("setting sort type " + action.payload);
      state.sort.type = action.payload;
    },
    resetSortType(state) {
      customLog("resetting sort type");
      state.sort.type = SORT.best;
    },
    setSortValue(state, action) {
      customLog("setting sort value " + action.payload.name);
      state.sort[action.payload.name] = action.payload.value;
    },
    resetSortValues(state) {
      customLog("resetting sort values");
      const vals = { price: -1, duration: -1 };
      state.sort.best = { ...vals };
      state.sort.cheapest = { ...vals };
      state.sort.fastest = { ...vals };
      state.sort.arrival = { ...vals };
      state.sort.departure = { ...vals };
    },
    applyFilter(state, action) {
      customLog("applying filter " + action.payload.type);
      const filterType = action.payload.type;
      const filterValue = action.payload.value;

      // assumes default value is when filter is off
      if (filterValue !== state.filters[filterType].defaultValue) {
        state.filters[filterType].isOn = true;
      } else {
        state.filters[filterType].isOn = false;
      }
      state.filters[filterType].value = action.payload.value;
    },
    clearFilter(state, action) {
      customLog("clearing filter " + action.payload.type);
      const filterType = action.payload.type;
      state.filters[filterType].value = state.filters[filterType].defaultValue;
      state.filters[filterType].isOn = false;
    },
    clearAllFilters(state) {
      customLog("clearing all filters");
      Object.keys(state.filters).forEach((key) => {
        state.filters[key].value = state.filters[key].defaultValue;
        state.filters[key].isOn = false;
      });
    },
    setMinFilterValue(state, action) {
      customLog("setting min value " + action.payload.type);
      const filterType = action.payload.type;
      state.filters[filterType].min = action.payload.value;
    },
    setMaxFilterValue(state, action) {
      customLog("setting max value " + action.payload.type);
      const filterType = action.payload.type;
      state.filters[filterType].max = action.payload.value;
    },
    setAirportOptions(state, action) {
      customLog("setting airport options");
      state.filters.airport.options = action.payload.options;
    },
    setAirlineOptions(state, action) {
      customLog("setting airline options");
      state.filters.airline.options = action.payload.options;
    },
    setIsSorting(state, action) {
      state.isSorting = action.payload;
    },
    setIsReceivingData(state, action) {
      state.isReceivingData = action.payload;
    },
    updatePinnedDepartureSegment(state, action) {
      customLog("pinning departure flight");
      state.pinned.departure = action.payload;
    },
    updatePinnedArrivalSegment(state, action) {
      customLog("pinning arrival flight");
      state.pinned.arrival = action.payload;
    },
  },
});

export const filterSortActions = filterSortSlice.actions;
export default filterSortSlice.reducer;

export const selectFilters = (state, type) => {
  if (type) {
    return state.filterSort.filters[type];
  } else {
    return state.filterSort.filters;
  }
};
export const selectSort = (state) => state.filterSort.sort.type;
export const selectIsAnyFilterOn = (state) => {
  return Object.values(state.filterSort.filters).some((f) => f.isOn);
};

export const selectActiveFilters = createSelector([selectFilters], (filters) => {
  const activeFilterTypes = Object.entries(filters)
    .filter((entry) => entry[1].isOn)
    .map((entry) => entry[0]);
  const activeFilters = {};
  activeFilterTypes.forEach((ftype) => {
    activeFilters[ftype] = filters[ftype].value;
  });
  return activeFilters;
});

const selectDepartureStart = (state) => state.filterSort.filters.departureStartTime;
const selectDepartureEnd = (state) => state.filterSort.filters.departureEndTime;
const selectReturnStart = (state) => state.filterSort.filters.returnStartTime;
const selectReturnEnd = (state) => state.filterSort.filters.returnEndTime;

export const selectDepartureTimes = createSelector(
  [selectDepartureStart, selectDepartureEnd],
  (start, end) => {
    return {
      start: start,
      end: end,
    };
  }
);

export const selectReturnTimes = createSelector(
  [selectReturnStart, selectReturnEnd],
  (start, end) => {
    return {
      start: start,
      end: end,
    };
  }
);

export const selectTimesFilters = createSelector(
  [selectDepartureStart, selectDepartureEnd, selectReturnStart, selectReturnEnd],
  (start1, end1, start2, end2) => {
    return {
      depStart: start1,
      depEnd: end1,
      retStart: start2,
      retEnd: end2,
    };
  }
);
