import { forwardRef } from "react";
import "./Button.css";
import { useDeviceContext } from "src/context/device-context";
import { motion } from "framer-motion";

/**
 * Reusable Button component. Has Primary style by default.
 * @param {string} name Button text
 * @param {string} type [type='button'] Button type
 * @param {object} opts An object with CSS properties to customize Button
 * @param {function} onClick Click event handler
 * @param {boolean} (primary | secondary | ternary | cornerSm | cornerMd | cornerLg | wide )
 * @returns <Button></Button>
 * @example
 * const myOpts = {backgroundColor: 'red', height: '200px'};
 * const onClickHandler = () => {};
 *
 * <Button onClick={onClickHandler}
 *    name={"My Button Name"} opts={myOpts}
 *    primary cornerLg wide />
 *
 */

const Button = forwardRef(function Button(
  {
    primary = true,
    secondary,
    ternary,
    cornerSm,
    cornerMd,
    cornerLg,
    wide,
    opts,
    fontclass = "",
    type = "button",
    shadow,
    disabled,
    onClick,
    onMouseUp,
    name = "",
  },
  ref
) {
  const { isMobile } = useDeviceContext();
  const styles = [];

  if (wide) {
    styles.push("wide");
  }
  if (!isMobile) {
    styles.push("hover");
  }
  if (shadow) {
    styles.push("shadow");
  }
  if (disabled) {
    styles.push("disabled");
  }

  styles.push(secondary ? "secondary" : ternary ? "ternary" : primary ? "primary" : "");

  styles.push(
    cornerSm ? "roundCornerSm" : cornerMd ? "roundCornerMd" : cornerLg ? "roundCornerLg" : ""
  );

  let clsList = [...styles, "button", fontclass]
    .filter((cls) => !!cls)
    .map((cls) => cls.trim());
  const classes = clsList.join(" ");
  return (
    <motion.button
      ref={ref}
      whileTap={disabled ? {} : { scale: 0.95 }}
      className={classes}
      style={opts}
      onClick={onClick}
      onMouseUp={onMouseUp}
      type={type}
      disabled={disabled}>
      {name}
    </motion.button>
  );
});

export default Button;
