import axios from "axios";
import { getSelectedLocale } from "src/utils/storage-utils";
import { auth } from "./firebase";

export const BASE_URL = `https://${window.SERVER_DATA.REACT_APP_API_URL}`;

const langugageInterceptor = (config) => {
  config.headers["X-Language"] = getSelectedLocale();
  return config;
};

const authInterceptor = async (config) => {
  config.headers["X-Language"] = getSelectedLocale();
  const token = await auth.currentUser?.getIdToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const apiClient = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: { "X-Company-Name": window.SERVER_DATA.REACT_APP_TENANT_NAME },
});

apiClient.interceptors.request.use(langugageInterceptor);

export const apiClientAuth = axios.create(apiClient.defaults);

apiClientAuth.interceptors.request.use(langugageInterceptor);
apiClientAuth.interceptors.request.use(authInterceptor);
