import classes from "./MultiSearchFormItem.module.css";
import SearchFormText from "src/components/features/search/SearchForm/elements/SearchFormText/SearchFormText";
import SearchFormDate from "src/components/features/search/SearchForm/elements/SearchFormDate/SearchFormDate";
import SwitchDirectionButton from "src/components/UI/SwitchDirectionButton/SwitchDirectionButton";
import { getDisplayLocationValueMobile } from "src/utils/utils";
import { useLocaleContext } from "src/context/locale-context";

export function MultiSearchFormItem({
  trip,
  tripNum,
  onRemove,
  onSwitchDirection,
  onInputClick,
}) {
  const { stringRes, currentLocale } = useLocaleContext();
  const lang = currentLocale.split("_")[0].toUpperCase();
  const srcLocationValue = getDisplayLocationValueMobile(trip.srcLocation, lang);
  const destLocationValue = getDisplayLocationValueMobile(trip.destLocation, lang);

  return (
    <div className={classes.search}>
      <div className={classes.formHeader}>
        <legend>{stringRes["sf.multi.flight"] + " " + tripNum}</legend>
        <button type="button" onClick={() => onRemove(trip.id)} hidden={tripNum === 1}>
          {stringRes["sf.button.remove"]}
        </button>
      </div>
      <SearchFormText
        id={"src-location" + tripNum}
        className={classes.src}
        value={srcLocationValue}
        label={stringRes["sf.label.from"]}
        onClick={() => onInputClick("src-location", trip.id)}
      />
      <SwitchDirectionButton
        buttonClass={classes.switchButton}
        onClick={() => onSwitchDirection(trip.id)}
      />
      <SearchFormText
        id={"dest-location" + tripNum}
        className={classes.dest}
        value={destLocationValue}
        label={stringRes["sf.label.to"]}
        onClick={() => onInputClick("dest-location", trip.id)}
      />
      <SearchFormDate
        id={"start-date" + tripNum}
        className={classes.startDate}
        value={trip.startDate}
        label={stringRes["sf.field.date.depart"]}
        onClick={() => onInputClick("start-date", trip.id)}
      />
    </div>
  );
}
