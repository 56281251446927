function RoundPlusButton({ isDisabled }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M15.9998 11.0676H12.8315V7.93376C12.8315 7.45773 12.4355 7.06104 11.9206 7.06104C11.4454 7.06104 11.0493 7.45773 11.0493 7.97343V11.1073H7.9206C7.44536 11.1073 7.04932 11.504 7.04932 12.0197C7.04932 12.4957 7.44536 12.8924 7.96021 12.8924H11.0889V16.0263C11.0889 16.5024 11.485 16.8991 11.9998 16.8991C12.4751 16.8991 12.8711 16.5024 12.8711 15.9867V12.8528H15.9998C16.4751 12.8528 16.8711 12.4561 16.8711 11.9404C16.8711 11.4643 16.4751 11.0676 15.9998 11.0676Z"
          fill={isDisabled ? "#bbbbbb" : "var(--primary-1)"}
        />
        <path
          id="Vector_2"
          d="M11.9604 0C5.34653 0 0 5.35537 0 11.9802C0 18.605 5.38614 24 12 24C18.6139 24 24 18.605 24 11.9802C23.9604 5.35537 18.5743 0 11.9604 0ZM11.9604 22.2149C6.33663 22.2149 1.78218 17.6132 1.78218 11.9802C1.78218 6.34711 6.33663 1.78512 11.9604 1.78512C17.5842 1.78512 22.1782 6.34711 22.1782 11.9802C22.1782 17.6132 17.5842 22.2149 11.9604 22.2149Z"
          fill={isDisabled ? "#bbbbbb" : "var(--primary-1)"}
        />
      </g>
    </svg>
  );
}

export default RoundPlusButton;
