import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import classes from "./AirportSelection.module.css";
import { AirportSelectionDropdown } from "./AirportSelectionDropdown";
import { AirportSelectionMobileInput } from "./AirportSelectionMobileInput";

export function AirportSelectionMobile({
  userInput,
  notFound,
  popularCityItems,
  filterItems,
  isUserFiltered,
  savedSearches,
  isDeparture,
  onChangeInput,
  onClearInput,
  onClose,
}) {
  const props = {
    isDeparture: isDeparture,
    userInput: userInput,
    notFound: notFound,
  };

  return (
    <section className={classes.content}>
      <CloseWindowButton onClick={onClose} />
      <AirportSelectionMobileInput
        {...props}
        onChange={onChangeInput}
        onClear={onClearInput}
      />
      <AirportSelectionDropdown
        {...props}
        popularCityItems={popularCityItems}
        filterItems={filterItems}
        isUserFiltered={isUserFiltered}
        savedSearches={savedSearches}
      />
    </section>
  );
}
