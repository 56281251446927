const { getBaggageInclusionListenerOpts } = require("./getBaggageInclusionListenerOpts");
const { getBaggageListenerOpts } = require("./getBaggageListenerOpts");
const { getBaggageProtectionListenerOpts } = require("./getBaggageProtectionListenerOpts");
const { getBillingInfoListenerOpts } = require("./getBillingInfoListenerOpts");
const { getContactDataListenerOpts } = require("./getContactDataListenerOpts");
const { getInsuranceServiceListenerOpts } = require("./getInsuranceServiceListenerOpts");
const { getNewsletterSubListenerOpts } = require("./getNewsletterSubListenerOpts");
const { getOffersOptInListenerOpts } = require("./getOffersOptInListenerOpts");
const { getPassengersListenerOpts } = require("./getPassengersListenerOpts");
const { getPaymentTypeListenerOpts } = require("./getPaymentTypeListenerOpts");
const { getSeatingListenerOpts } = require("./getSeatingListenerOpts");
const { getServicesListenerOpts } = require("./getServicesListenerOpts");
const { getSupportServiceListenerOpts } = require("./getSupportServiceListenerOpts");
const { getTermsAcceptanceListenerOpts } = require("./getTermsAcceptanceListenerOpts");
const { getCatalogueListenerOpts } = require("./getCatalogueListenerOpts");

export const bookingListeners = {
  getContactDataListenerOpts,
  getBaggageListenerOpts,
  getSeatingListenerOpts,
  getBillingInfoListenerOpts,
  getOffersOptInListenerOpts,
  getServicesListenerOpts,
  getPassengersListenerOpts,
  getPaymentTypeListenerOpts,
  getNewsletterSubListenerOpts,
  getSupportServiceListenerOpts,
  getTermsAcceptanceListenerOpts,
  getBaggageInclusionListenerOpts,
  getInsuranceServiceListenerOpts,
  getBaggageProtectionListenerOpts,
  getCatalogueListenerOpts,
};
