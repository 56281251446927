import { AirportSelectionDropdown } from "./AirportSelectionDropdown";

export function AirportSelectionDesktop({
  userInput,
  notFound,
  popularCityItems,
  filterItems,
  isUserFiltered,
  savedSearches,
  isDeparture,
  filterType,
}) {
  const props = {
    isDeparture: isDeparture,
    userInput: userInput,
    notFound: notFound
  };
  return (
    <AirportSelectionDropdown
      {...props}
      popularCityItems={popularCityItems}
      filterItems={filterItems}
      isUserFiltered={isUserFiltered}
      savedSearches={savedSearches}
      filterType={filterType}
    />
  );
}
