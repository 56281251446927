import { useCallback, useEffect, useState } from "react";
import classes from "./SwitchDirectionButton.module.css";
import { useDeviceContext } from "src/context/device-context";
import SwitchDirectionMobile from "./SwitchDirectionMobile";
import SwitchDirectionDesktop from "./SwitchDirectionDesktop";

function SwitchDirectionButton({ onClick, buttonClass }) {
  const { isMobile } = useDeviceContext();
  const baseClass = buttonClass ? `${buttonClass} ${classes.switch}` : classes.switchButton;

  const getClassName = useCallback(() => {
    return buttonClass ? `${buttonClass} ${classes.switch}` : classes.switchButton;
  }, [buttonClass]);

  const [classname, setClassname] = useState(getClassName(buttonClass));

  const onButtonClickHandler = () => {
    setClassname(`${baseClass} ${classes.anim}`);
    onClick();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setClassname(getClassName());
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [classname, getClassName]);

  return (
    <button type="button" onClick={onButtonClickHandler} className={classname}>
      {isMobile ? <SwitchDirectionMobile /> : <SwitchDirectionDesktop />}
    </button>
  );
}

export default SwitchDirectionButton;
