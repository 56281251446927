import { useEffect, useState } from "react";
import classes from "./AirportSelection.module.css";

export function EmptyFilterResults({ message }) {
  const [notFoundMsg, setMessage] = useState(message);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setMessage(message);
    }, 200)
    return () => clearTimeout(timerId);
  }, [message]);

  return (
    <div className={classes.emptyResult}>
      <span>{notFoundMsg}</span>
    </div>
  );
}
