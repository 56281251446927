import classes from "./DotPulseLoader.module.css";

function DotPulseLoader({ opacity = 1, color, scale = 1 }) {
  return (
    <span
      style={{ opacity: opacity, transform: `scale(${scale})` }}
      className={classes.dotPulse}>
      <span style={{ backgroundColor: color }} className={classes.bounce1}></span>
      <span style={{ backgroundColor: color }} className={classes.bounce2}></span>
      <span style={{ backgroundColor: color }} className={classes.bounce3}></span>
    </span>
  );
}

export default DotPulseLoader;
