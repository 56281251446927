import classes from "./CabinClassItemGroup.module.css";
import RadioButton from "src/components/UI/RadioButton/RadioButton";

export function CabinClassItem({ id, label, checked, onSelect }) {
  const onInputChangeHandler = (isChecked) => {
    if (isChecked) {
      onSelect(id);
    }
  };

  return (
    <li>
      <RadioButton
        id={id}
        name="cabin-class"
        onChange={onInputChangeHandler}
        checked={checked}
      >
        <span className={classes.radioText}>{label}</span>
      </RadioButton>
    </li>
  );
}
