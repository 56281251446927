import { useEffect, useState } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { staticApi } from "src/services/api";

function useStaticContent(component) {
  const { currentLocale } = useLocaleContext();
  const [data, setData] = useState({ html: "", css: "" });

  useEffect(() => {
    const lang = currentLocale.split("_")[0];
    const tenant = window.SERVER_DATA.REACT_APP_TENANT_NAME;
    staticApi
      .getStaticFiles(tenant, lang, component)
      .then((res) => {
        setData({ html: res.html, css: res.css });

        const styleTag = document.createElement("style");
        styleTag.innerHTML = res.css;
        const bulletListRule = `
        #mce-template-container-${component} ul, 
        #mce-template-container-${component} ol 
        {list-style: unset;  padding-left: 1.5rem;}
        `;
        styleTag.innerHTML += bulletListRule;
        document.head.appendChild(styleTag);

        return () => styleTag.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentLocale, component]);

  return data;
}
export default useStaticContent;
