import { BAG_WEIGHT_CATEGORY } from "src/constants";

export const BAGGAGE = {
  personal: "pi_baggage",
  cabin: "co_baggage",
  cabinBundle: "cob_baggage",
  cabinBundleUpgrade: "cob_baggage_upgrade",
  checked: "r_baggage",
};

/**CHECK IS BAGGAGE DATA AVAILABLE */
export const hasPersonalItemData = (item) => {
  const baggageData = item.baggage[BAGGAGE.personal];
  return baggageData && Array.isArray(baggageData) && baggageData.length > 0;
};

export const hasCabinBagData = (item) => {
  const baggageData = item.baggage[BAGGAGE.cabin];
  return baggageData && Array.isArray(baggageData) && baggageData.length > 0;
};

export const hasCabinBundleData = (item) => {
  const baggageData = item.baggage[BAGGAGE.cabinBundle];
  return baggageData && Array.isArray(baggageData) && baggageData.length > 0;
};

export const hasCabinBundleUpgradeData = (item) => {
  const baggageData = item.baggage[BAGGAGE.cabinBundle]?.[0]?.upgrade;
  return baggageData && Array.isArray(baggageData) && baggageData.length > 0;
};

export const hasAnyRegisteredBagData = (item) => {
  const baggageData = item.baggage[BAGGAGE.checked];
  return baggageData && Array.isArray(baggageData) && baggageData.length > 0;
};
/** --- */

export const hasFreeBaggageIncluded = (baggage, type) => {
  let hasFree = false;
  if (!baggage[type] || !baggage[type].length === 0) {
    return hasFree;
  }

  let baggageObj = baggage[type];
  if (type === BAGGAGE.checked) {
    hasFree = baggageObj.some((bag) => bag.is_r_baggage_included);
  } else if (type === BAGGAGE.cabin) {
    hasFree = baggageObj[0].is_co_baggage_included; //REVIEW: cob or co?
  } else if (type === BAGGAGE.personal) {
    hasFree = baggageObj[0].is_pi_baggage_included;
  }
  return hasFree;
};

export const getBaggageWeight = (baggage) => {
  const weightStr = `${baggage.weight} ${baggage.weight_unit}`;
  return weightStr;
};

export const getBaggageDimensions = (baggage) => {
  const dim = baggage.dimensions;
  const dimStr = `${dim?.width} × ${dim?.length} × ${
    dim?.height
  } ${dim?.measurement_unit?.toLowerCase()}`;
  return dimStr;
};

/** REGISTERED BAGGAGE */
export const hasRegisteredBaggageType = (item, isDouble, weightType) => {
  const availableBags = item.baggage.r_baggage?.filter(
    (bag) => bag.piece === (isDouble ? 2 : 1)
  );
  if (availableBags && availableBags.length > 0) {
    availableBags.sort(
      (a, b) =>
        a.weight - b.weight ||
        a.r_baggage_price.total_price_per_person.price -
          b.r_baggage_price.total_price_per_person.price
    );

    if (isDouble || weightType === BAG_WEIGHT_CATEGORY.default) {
      return true;
    } else if (weightType === BAG_WEIGHT_CATEGORY.larger) {
      return availableBags.length > 1 && availableBags[1];
    }
  }
};

export const getRegisteredBaggagePrice = (item, isDouble, weightType) => {
  const availableBags = item.baggage.r_baggage?.filter(
    (bag) => bag.piece === (isDouble ? 2 : 1)
  );
  if (availableBags && availableBags.length > 0) {
    availableBags.sort(
      (a, b) =>
        a.weight - b.weight ||
        a.r_baggage_price.total_price_per_person.price -
          b.r_baggage_price.total_price_per_person.price
    );

    let targetBag;
    if (isDouble || weightType === BAG_WEIGHT_CATEGORY.default) {
      targetBag = availableBags[0];
    } else if (weightType === BAG_WEIGHT_CATEGORY.larger) {
      targetBag = availableBags.length > 1 ? availableBags[1] : undefined;
    }

    return targetBag?.r_baggage_price.total_price_per_person.price;
  }
};

export const getRegisteredBaggageWeight = (item, isDouble, weightType) => {
  if (hasRegisteredBaggageType(item, isDouble, weightType)) {
    const availableBags = item.baggage.r_baggage.filter(
      (bag) => bag.piece === (isDouble ? 2 : 1)
    );
    availableBags.sort(
      (a, b) =>
        a.weight - b.weight ||
        a.r_baggage_price.total_price_per_person.price -
          b.r_baggage_price.total_price_per_person.price
    );
    let targetBag;
    if (isDouble || weightType === BAG_WEIGHT_CATEGORY.default) {
      targetBag = availableBags[0];
    } else if (weightType === BAG_WEIGHT_CATEGORY.larger) {
      targetBag = availableBags[1];
    }

    return getBaggageWeight(targetBag);
  }
};

export const getRegisteredBaggageDimensions = (item, isDouble, weightType) => {
  if (hasRegisteredBaggageType(item, isDouble, weightType)) {
    const availableBags = item.baggage.r_baggage.filter(
      (bag) => bag.piece === (isDouble ? 2 : 1)
    );
    availableBags.sort(
      (a, b) =>
        a.weight - b.weight ||
        a.r_baggage_price.total_price_per_person.price -
          b.r_baggage_price.total_price_per_person.price
    );
    let targetBag;
    if (isDouble || weightType === BAG_WEIGHT_CATEGORY.default) {
      targetBag = availableBags[0];
    } else if (weightType === BAG_WEIGHT_CATEGORY.larger) {
      targetBag = availableBags[1];
    }

    return getBaggageDimensions(targetBag);
  }
};

/** CABIN & CABIN BUDNLE BAGGAGE */
export const getBundledPersonalItemDimensions = (item) => {
  return getBaggageDimensions(item.baggage.cob_baggage[0].pi_baggage);
};

export const getBundledPersonalItemWeight = (item) => {
  return getBaggageWeight(item.baggage.cob_baggage[0].pi_baggage);
};

export const getBundledCabinBaggageDimensions = (item) => {
  return getBaggageDimensions(item.baggage.cob_baggage[0].co_baggage);
};

export const getBundledCabinBaggageWeight = (item) => {
  return getBaggageWeight(item.baggage.cob_baggage[0].co_baggage);
};

export const getBundledCabinBaggageUpgradeDimensions = (item) => {
  return getBaggageDimensions(item.baggage.cob_baggage[0].upgrade?.[0]);
};

export const getBundledCabinBaggageUpgradeWeight = (item) => {
  const totalWeight =
    item.baggage.cob_baggage[0].co_baggage.weight +
    item.baggage.cob_baggage[0].upgrade?.[0].weight;
  return `${totalWeight} ${item.baggage.cob_baggage[0].upgrade?.[0].weight_unit}`;
};

export const getBundledCabinBaggageUpgradeWeightDifference = (item) => {
  return getBaggageWeight(item.baggage.cob_baggage[0].upgrade?.[0]);
};

export const hasBundledCabinBaggageFastTrack = (item) => {
  return item.baggage.cob_baggage[0].fast_track?.fast_track;
};

export const hasBundledCabinBaggageUpgradeFastTrack = (item) => {
  return item.baggage.cob_baggage[0].upgrade?.[0].fast_track?.fast_track;
};

export const getCabinBaggageWeight = (item) => {
  return getBaggageWeight(item.baggage.co_baggage[0]);
};

export const getCabinBaggageBundlePrice = (item) => {
  return item.baggage.cob_baggage[0].price.total_price_per_person.price;
};

export const getCabinBaggageUpgradeBundlePrice = (item) => {
  return item.baggage.cob_baggage[0].upgrade?.[0]?.total_price.price;
};
/** PERSONAL ITEM BAGGAGE */
export const getPersonalItemDimensions = (item) => {
  return getBaggageDimensions(item.baggage.pi_baggage[0]);
};

export const getPersonalItemWeight = (item) => {
  return getBaggageWeight(item.baggage.pi_baggage[0]);
};
