import classes from "./LocationItemGroup.module.css";
import cityIcon from "src/assets/img/cityIcon.png";

export function PopularCityItem({ item, onSelectCity }) {
  return (
    <li
      key={item.city}
      className={classes.cityItem}
      onClick={() =>
        onSelectCity({
          city: item.city,
          iata: item.cityIata,
          cityIata: item.cityIata,
          names: [], //FIXME: get and pass localized names
        })
      }>
      <div className={classes.row}>
        <img height={18} alt="" src={cityIcon} />
        <p>{item.localizedCity + ", " + item.localizedCountry}</p>
      </div>
    </li>
  );
}
