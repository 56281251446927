import { getSelectedLocale } from "src/utils/storage-utils";
import { redirect } from "react-router-dom";

export function isLangAbsent(request) {
  const url = new URL(request.url);
  return !url.searchParams.has("lang");
}

export function redirectWithLang(request) {
  const url = new URL(request.url);
  const lang = getSelectedLocale().split("_")[0];
  url.searchParams.set("lang", lang);
  return redirect(`${url.pathname}?${url.searchParams.toString()}`);
}
