import { defer, json } from "react-router-dom";
import { bookingOrderApi } from "src/services/api";
import { isRoundTrip } from "src/utils/results-utils";
import { getSelectedLocale } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import { isLangAbsent, redirectWithLang } from "./utils/loader-utils";

function formatFlightDate(timestampSeconds, lang) {
  const date = new Date(timestampSeconds * 1000);
  const options = {
    day: "2-digit",
    month: "short",
  };
  const formattedDate = new Intl.DateTimeFormat(lang, options).format(date);
  return formattedDate;
}

function formatDataItem(trip, lang, i) {
  trip.from = trip.segments[0].dep.city.title?.split(",")[0];
  const desination = trip.segments_direction[0].at(-1);
  trip.to = trip.segments[desination].arr.city.title?.split(",")[0];
  const startDateStr = formatFlightDate(trip.segments[0].dep.timestamp, lang);
  const endDateStr = formatFlightDate(trip.segments.at(-1).arr.timestamp, lang);
  trip.dates = `${startDateStr} - ${endDateStr}`;
  trip.tripType = isRoundTrip(trip) ? "roundtrip" : "oneway";
  trip.imageUrl =
    `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
    `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/cities/` +
    `${trip.segments[desination].arr.city.code}.jpg`;
  trip.paid = trip.amountPaid >= trip.totalBookingPrice;
  return trip;
}

export async function loader({ request, user }) {
  console.log("userTripsLoader");
  if (isLangAbsent(request)) return redirectWithLang(request);
  const url = new URL(request.url);
  const searchParams = url.searchParams;

  const lang = searchParams.get("lang") || getSelectedLocale().split("_")[0];
  if (!user) return null;

  const allTrips = bookingOrderApi
    .getUserTrips(user.uid)
    .then((allTripsRes) => {
      const allTrips = allTripsRes.data;
      allTrips.forEach((trip, i) => formatDataItem(trip, lang, i));
      const [trips, pastTrips] = allTrips.reduce(
        (acc, trip) => {
          const targetArr = trip.segments[0].dep.timestamp * 1000 < Date.now() ? 1 : 0;
          if (targetArr === 1) trip.past = true;
          acc[targetArr].push(trip);
          return acc;
        },
        [[], []]
      );

      trips.sort((a, b) => a.segments[0].dep.timestamp - b.segments[0].dep.timestamp);
      pastTrips.sort((a, b) => b.segments[0].dep.timestamp - a.segments[0].dep.timestamp);
      console.log(trips);
      console.log(pastTrips);
      return [trips, pastTrips];
    })
    .catch((err) => {
      customLog(err);
      if (err.response?.status === 404) {
        return [[], []];
      }
      throw json({ data: err });
    });

  return defer({ allTrips });
}

// async function testLoader({ request }) {
//   if (isLangAbsent(request)) return redirectWithLang(request);
//   const url = new URL(request.url);
//   const searchParams = url.searchParams;

//   const tripsRes = Array(TRIP_NUM)
//     .fill()
//     .map((item, i) => ({
//       ...MOCK_BOOKING,
//       numberOfPassengers: TRIP_NUM + i,
//       totalBookingPrice: 88.33 + 50.13 * i,
//       checkinStatus: i % 3 === 0 ? "active" : "not_started",
//       segments: MOCK_BOOKING.segments.map((seg) => ({
//         ...seg,
//         dep: {
//           ...seg.dep,
//           date: new Date(2024, 10, (TRIP_NUM % 30) + i + 1).toISOString().substring(0, 10),
//           timestamp: new Date(2024, 10, (TRIP_NUM % 30) + i + 1),
//         },
//       })),
//     }));

//   const pastTripsRes = Array(PAST_TRIP_NUM)
//     .fill()
//     .map((item, i) => ({
//       ...MOCK_BOOKING,
//       numberOfPassengers: PAST_TRIP_NUM - i,
//       totalBookingPrice: 108.03 + 49.01 * (i + 1),
//       checkinStatus: i < PAST_TRIP_NUM - 1 ? "complete" : "missed",
//       segments: MOCK_BOOKING.segments.map((seg) => ({
//         ...seg,
//         dep: {
//           ...seg.dep,
//           date: new Date(2024, 8, (PAST_TRIP_NUM % 30) + i + 1).toISOString().substring(0, 10),
//           timestamp: new Date(2024, 8, (PAST_TRIP_NUM % 30) + i + 1),
//         },
//       })),
//     }));

//   const lang = searchParams.get("lang") || getSelectedLocale().split("_")[0];
//   const allTripsRes = tripsRes.concat(pastTripsRes);
//   allTripsRes.forEach((trip, i) => {
//     //testing
//     if (i % 3 === 0) {
//       trip.segments = trip.segments.concat(trip.segments.reverse());
//       trip.segments_count = trip.segments.length;
//       trip.segments_direction = trip.segments_direction.concat(
//         trip.segments_direction.reverse()
//       );
//     }

//     //end testing

//     trip.correlationId = nanoid();
//     trip.from = trip.segments[0].dep.city.title?.split(",")[0] + i;
//     const desination = trip.segments_direction[0].at(-1);
//     trip.to = trip.segments.at(-1).arr.city.title?.split(",")[0];
    
//     const startDateStr = formatFlightDate(trip.segments[0].dep.timestamp, lang);
//     const endDateStr = formatFlightDate(trip.segments.at(-1).arr.timestamp, lang);
//     trip.dates = `${startDateStr} - ${endDateStr}`;
//     trip.tripType = isRoundTrip(trip) ? "roundtrip" : "oneway";
//     trip.imageUrl =
//       `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
//       `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/cities/` +
//       `${trip.segments[desination].arr.city.code}.jpg`;

//     if (i % 2 > 0) {
//       trip.imageUrl = `https://picsum.photos/seed/picsum/158/142`;
//     }
//   });

//   const [trips, pastTrips] = allTripsRes.reduce(
//     (acc, trip) => {
//       const targetArr = trip.segments[0].dep.timestamp < Date.now() ? 1 : 0;
//       if (targetArr === 1) trip.past = true;
//       acc[targetArr].push(trip);
//       return acc;
//     },
//     [[], []]
//   );

//   trips.sort((a, b) => a.segments[0].dep.timestamp - b.segments[0].dep.timestamp);
//   pastTrips.sort((a, b) => b.segments[0].dep.timestamp - a.segments[0].dep.timestamp);

//   console.log(trips);
//   console.log(pastTrips);

//   return { trips, pastTrips };
// }
