import React from "react";
import ScrollCalendar from "src/components/features/search/SearchForm/elements/Calendar/ScrollCalendar";
import { TRIP_TYPE } from "src/constants";
import moment from "moment";
import { customLog } from "src/utils/utils";
import { DATE_FORMAT } from "src/utils/date-utils";
import PagedCalendar from "./PagedCalendar";

export function Calendar({
  tripType,
  minDate,
  startDate,
  endDate,
  onEndDateSelected,
  onStartDateSelected,
  isSelectingEndDate,
  isSpecificDateType,
  isScroll,
}) {
  const onSelectEndDateHandler = (date) => {
    customLog("calendar comp > onSelectEndDateHandler");
    onEndDateSelected(date ? date.format(DATE_FORMAT) : "");
  };

  const onSelectStartDateHandler = (date) => {
    customLog("calendar comp > onSelectStartDateHandler");
    onStartDateSelected(date ? date.format(DATE_FORMAT) : "");
  };

  const props = {
    startDate: startDate ? moment(startDate) : null,
    onSelectStartDate: onSelectStartDateHandler,
  };

  if (tripType === TRIP_TYPE.roundtrip) {
    props.endDate = endDate ? moment(endDate) : null;
    props.isRangeCalendar = true;
    props.onSelectEndDate = onSelectEndDateHandler;
    props.isSelectingEndDate = isSelectingEndDate;
  }

  if (tripType === TRIP_TYPE.multicity && minDate) {
    props.minDate = moment(minDate);
  }

  if (isScroll) {
    return (
      <div className="scroll-calendar">
        <ScrollCalendar {...props} />
      </div>
    );
  } else {
    return (
      <div className="paged-calendar">
        <PagedCalendar {...props} isSpecificDateType={isSpecificDateType} />
      </div>
    );
  }
}
