import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { LoadingScreen } from "src/components/shared/screen/LoadingScreen";

const GlobalLoadingContext = createContext({
  loading: false,
  setLoading: (loading, opacity, exitDuration) => {},
});

export const GlobalLoadingProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [showLoadingScreen, setShowScreen] = useState(false);
  const [scheduleClose, setScheduleClose] = useState(false);
  const [style, setStyle] = useState({ opacity: 1, exitDuration: 1 });

  useEffect(() => {
    setScheduleClose(!loading);
    const timerID = setTimeout(
      () => setShowScreen(loading),
      loading ? 0 : style.exitDuration * 1000
    );
    return () => clearTimeout(timerID);
  }, [loading]);

  const scheduleLoading = useCallback(
    (loadingStatus, opacity = 1, exitDuration = 1) => {
      setLoading(loadingStatus);
      setStyle({ opacity, exitDuration });
    },
    [setLoading, setStyle]
  );

  return (
    <GlobalLoadingContext.Provider value={{ loading, setLoading: scheduleLoading }}>
      {showLoadingScreen && (
        <LoadingScreen
          scheduleClose={scheduleClose}
          exitDuration={style.exitDuration}
          opacity={style.opacity}
        />
      )}
      {props.children}
    </GlobalLoadingContext.Provider>
  );
};

export default GlobalLoadingContext;

export const useGlobalLoading = () => useContext(GlobalLoadingContext);
