import { createSlice, nanoid } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";
import { PASSENGER } from "src/constants";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import {
  addPassenger,
  removePassenger,
  resetAfterBooking,
  updateCheckedBaggageInclusion,
  updatePassenger,
  updateTravelPackage,
} from "./actions";

const INIT_ORDER_STATE = {
  bookingSMSService: { serviceId: SERVICE_TYPE.NOT_SELECTED },
  connectionProtection: { serviceId: SERVICE_TYPE.NOT_SELECTED },
  changeProtection: { serviceId: SERVICE_TYPE.NOT_SELECTED }, //moneyback
  onlineCheckin: { serviceId: SERVICE_TYPE.NOT_SELECTED },
  travelInsurance: { serviceId: SERVICE_TYPE.NOT_SELECTED },
  seatType: [],
  supportService: { serviceId: SERVICE_TYPE.NOT_SELECTED },
  airHelpService: { serviceId: SERVICE_TYPE.NOT_SELECTED },
  flightsSMSService: { serviceId: SERVICE_TYPE.NOT_SELECTED },
  priceLock: { serviceId: SERVICE_TYPE.NOT_SELECTED },
  baggage: [],
};

function assignInstanceId(serviceId) {
  return ![SERVICE_TYPE.REFUSED, SERVICE_TYPE.NOT_SELECTED].includes(serviceId)
    ? nanoid(10)
    : "";
}

function getValues(payload) {
  let serviceId, _id;
  if (typeof payload !== "string") {
    serviceId = payload.serviceId;
    _id = payload.backend_id;
    if (!payload.hasOwnProperty("backend_id")) {
      _id = assignInstanceId(serviceId);
    }
  } else {
    serviceId = payload;
    _id = assignInstanceId(serviceId);
  }
  return {
    serviceId,
    _id,
  };
}

const orderSlice = createSlice({
  name: "order",
  initialState: INIT_ORDER_STATE,
  reducers: {
    changeBookingSMSService(state, action) {
      const { serviceId, _id } = getValues(action.payload);
      state.bookingSMSService = { serviceId: serviceId, _id: _id };
    },
    changeFlightsSMSService(state, action) {
      const { serviceId, _id } = getValues(action.payload);
      state.flightsSMSService = { serviceId: serviceId, _id: _id };
    },
    changeConnectionProtection(state, action) {
      const { serviceId, _id } = getValues(action.payload);
      state.connectionProtection = { serviceId: serviceId, _id: _id };
    },
    changeOnlineCheckinService(state, action) {
      const { serviceId, _id } = getValues(action.payload);
      state.onlineCheckin = { serviceId: serviceId, _id: _id };
    },
    changeChangeProtection(state, action) {
      const { serviceId, _id } = getValues(action.payload);
      state.changeProtection = { serviceId: serviceId, _id: _id };
    },
    changeTravelInsuranceType(state, action) {
      const { serviceId, _id } = getValues(action.payload);
      state.travelInsurance = { serviceId: serviceId, _id: _id };
    },
    changeSeatType(state, action) {
      const segment = action.payload.segment;
      if (segment < state.seatType.length) {
        const { serviceId, _id } = getValues(action.payload);
        state.seatType[segment].serviceId = serviceId;
        state.seatType[segment].airline = action.payload.airline;
        state.seatType[segment]._id = _id;
      }
    },
    changeSupportServiceType(state, action) {
      const { serviceId, _id } = getValues(action.payload);
      state.supportService = { serviceId: serviceId, _id: _id };
    },
    changeAirHelpService(state, action) {
      const { serviceId, _id } = getValues(action.payload);
      state.airHelpService = { serviceId: serviceId, _id: _id };
    },
    changePriceLockService(state, action) {
      const { serviceId, _id } = getValues(action.payload);
      state.priceLock = { serviceId: serviceId, _id: _id };
    },
    changeCabinBaggageType(state, action) {
      const { passengerId } = action.payload;
      const idx = state.baggage.findIndex((b) => b.passengerId === passengerId);
      if (idx !== -1) {
        const { serviceId, _id } = getValues(action.payload);
        state.baggage[idx].cabin.serviceId = serviceId;
        state.baggage[idx].cabin._id = _id;
      }
    },
    changeCheckedBaggageType(state, action) {
      const { passengerId } = action.payload;
      const idx = state.baggage.findIndex((b) => b.passengerId === passengerId);
      if (idx !== -1) {
        const { serviceId, _id } = getValues(action.payload);
        state.baggage[idx].checked.serviceId = serviceId;
        state.baggage[idx].checked._id = _id;
      }
    },
    changeCheckedBaggageProtection(state, action) {
      const { passengerId } = action.payload;
      const idx = state.baggage.findIndex((b) => b.passengerId === passengerId);
      if (idx !== -1) {
        const { serviceId, _id } = getValues(action.payload);
        state.baggage[idx].protection.serviceId = serviceId;
        state.baggage[idx].protection._id = _id;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPassenger, (state, action) => {
        const { passengerId, ageGroup } = action.payload;
        if (ageGroup !== PASSENGER.infant) {
          state.baggage.push({
            passengerId: passengerId,
            cabin: { serviceId: SERVICE_TYPE.NOT_SELECTED },
            checked: { serviceId: SERVICE_TYPE.NOT_SELECTED },
            protection: { serviceId: SERVICE_TYPE.NOT_SELECTED },
          });
        }
      })
      .addCase(removePassenger, (state, action) => {
        const { passengerId } = action.payload;
        const idx = state.baggage.findIndex((b) => b.passengerId === passengerId);
        if (idx !== -1) {
          state.baggage.splice(idx, 1);
        }
      })
      .addCase(updatePassenger, (state, action) => {
        const { passengerId, ageGroup } = action.payload;
        const bidx = state.baggage.findIndex((b) => b.passengerId === passengerId);

        if (bidx === -1 && ageGroup !== PASSENGER.infant) {
          state.baggage.push({
            passengerId: passengerId,
            cabin: { serviceId: SERVICE_TYPE.NOT_SELECTED },
            checked: { serviceId: SERVICE_TYPE.NOT_SELECTED },
            protection: { serviceId: SERVICE_TYPE.NOT_SELECTED },
          });
        } else if (bidx !== -1 && ageGroup === PASSENGER.infant) {
          state.baggage.splice(bidx, 1);
        }
      })
      .addCase(updateCheckedBaggageInclusion, (state, action) => {
        const { doInclude, passengerId } = action.payload;
        const idx = state.baggage.findIndex((b) => b.passengerId === passengerId);
        if (idx !== -1) {
          state.baggage[idx].checked = {
            serviceId: doInclude ? "" : SERVICE_TYPE.NOT_SELECTED,
          };
          state.baggage[idx].protection = {
            serviceId: SERVICE_TYPE.NOT_SELECTED,
          };
        }
      })
      .addCase(updateTravelPackage, (state, action) => {
        const travelPackage = action.payload.travelPackage;
        const flights = travelPackage.segments;

        if (
          state.seatType.length === 0 ||
          !isEqual(
            flights.map((fl) => fl.carrier.code),
            state.seatType.map((st) => st.airline)
          )
        ) {
          state.seatType = [];
          flights.forEach((fl, i) => {
            state.seatType.push({
              segment: i,
              serviceId: CATALOGUE_IDS.seats.random,
              airline: fl.carrier.code,
            });
          });
        }
      })
      .addCase(resetAfterBooking, (state, action) => {
        return INIT_ORDER_STATE;
      });
  },
});

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;

export const selectBaggage = (state, passengerId) => {
  return state.order.baggage.find((b) => b.passengerId === passengerId);
};

export const selectOrder = (state) => {
  return state.order;
};

export const selectAllBaggage = (state) => {
  return state.order.baggage;
};
