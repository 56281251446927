import { useDispatch, useSelector } from "react-redux";
import { PASSENGER, PASSENGER_TYPE_MAP } from "src/constants";
import { searchDataActions } from "src/store/search";
import { calculateTotalPassengers } from "src/utils/utils";
import { PassengerItem } from "./PassengerItem";
import classes from "./PassengerItemGroup.module.css";
import { useDeviceContext } from "src/context/device-context";

export const MAX_PASSENGERS = 9;

export function PassengerItemGroup() {
  const dispatch = useDispatch();
  const { isMobile } = useDeviceContext();
  const passengers = useSelector((state) => state.search.userInput.passengers);
  const passengerNum = calculateTotalPassengers(passengers);

  const canAddPassenger = (type) => {
    if (passengerNum < MAX_PASSENGERS) {
      if (type !== PASSENGER.infant) {
        return true;
      } else if (
        type === PASSENGER.infant &&
        passengers[type] < passengers[PASSENGER.adult]
      ) {
        return true;
      }
    }
    return false;
  };

  const canRemovePassenger = (type) => {
    if (type === PASSENGER.adult) {
      if (passengers[type] > 1 && passengers[type] > passengers[PASSENGER.infant]) {
        return true;
      }
    } else if (passengers[type] > 0) {
      return true;
    }
    return false;
  };

  const onAddHandler = (type) => {
    if (canAddPassenger(type)) {
      if (isMobile) {
        dispatch(searchDataActions.addUserInputPassenger(type));
      } else {
        dispatch(searchDataActions.addPassenger(type));
        dispatch(searchDataActions.fillUserInputPassengerOptions());
      }
    }
  };

  const onRemoveHandler = (type) => {
    if (canRemovePassenger(type)) {
      if (isMobile) {
        dispatch(searchDataActions.removeUserInputPassenger(type));
      } else {
        dispatch(searchDataActions.removePassenger(type));
        dispatch(searchDataActions.fillUserInputPassengerOptions());
      }
    }
  };

  return (
    <ul className={classes.container}>
      {Object.values(PASSENGER).map((type) => (
        <PassengerItem
          key={type}
          type={type}
          counter={{
            count: passengers[type],
            canAdd: canAddPassenger(type),
            canRemove: canRemovePassenger(type),
            onAdd: onAddHandler,
            onRemove: onRemoveHandler,
          }}
          displayData={PASSENGER_TYPE_MAP[type]}
        />
      ))}
    </ul>
  );
}
