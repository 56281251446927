import { BOOKING } from "src/constants/booking";
import { customLog } from "../utils";


export function getOffersOptInListenerOpts(send) {
  return {
    type: "booking/updateSkipOffersEmailsStatus",
    effect: (action, listenerApi) => {
      customLog(action);
      const skipOffers = listenerApi.getState().booking.isOffersOptedOut;
      const bookingAction = {
        command: BOOKING.UPDATE_OFFERS_SUBSCRIPTION,
        payload: { emailOffers: !skipOffers },
      };
      send(bookingAction);
    },
  };
}
