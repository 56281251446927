import { forwardRef } from "react";
import "./Card.css";

/**
 * Card Wrapper for UI elements
 * @param {object} opts An object with CSS properties to customize Card
 * @param {boolean} (shadowSm | shadowLg | cornerSm | cornerLg)
 * @returns <Card></Card>
 * @example
 * const myOpts = {backgroundColor: 'red', height: '200px'};
 *
 * <Card opts={myOpts} shadowLg cornerSm> Sample text or component </Card>
 *
 */

const Card = forwardRef(function Card(
  { shadowSm, shadowLg, cornerXSm, cornerSm, cornerLg, opts, className, children },
  ref
) {
  const styles = [];
  styles.push(
    cornerXSm
      ? "roundCornerXSm"
      : cornerSm
      ? "roundCornerSm"
      : cornerLg
      ? "roundCornerLg"
      : ""
  );
  styles.push(shadowSm ? "withShadowSm" : shadowLg ? "withShadowLg" : "");

  const classes = [...styles, className, "card"].filter((c) => !!c).join(" ");
  return (
    <div ref={ref} className={classes} style={opts}>
      {children}
    </div>
  );
});

export default Card;
