import { createSlice, nanoid } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import { TRIP_TYPE } from "src/constants";
import { secondDateIsLater } from "src/utils/date-utils";
import { getSelectedLocale } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import { airportUserInputModel, searchDataModel, tripModel } from "./models";

const initSearchData = () => {
  const initState = cloneDeep(searchDataModel);
  initState.trips[0].id = nanoid();
  return initState;
};

const fillUserInputTrips = (trips) => {
  const userInputTrips = [];
  const lang = getSelectedLocale().split("_")[0].toUpperCase();
  for (let i = 0; i < trips.length; i++) {
    const trip = trips[i];
    const src = trip.srcLocation;
    const dest = trip.destLocation;
    const localizedSrcName = src.names?.[lang] || src.city;
    const localizedDestName = dest.names?.[lang] || dest.city;
    userInputTrips.push({
      id: trip.id,
      srcLocation: localizedSrcName,
      srcIata: src.iata,
      destLocation: localizedDestName,
      destIata: dest.iata,
    });
  }
  return userInputTrips;
};

export const rehydrateSearchStore = () => {
  const initData = initSearchData();
  // user airport input for desktop form
  const initUserInputData = { cabinClass: "", passengers: {}, trips: [] };

  initUserInputData.trips = fillUserInputTrips(initData.trips);
  initUserInputData.passengers = { ...initData.passengers };
  initUserInputData.cabinClass = initData.cabinClass;
  initData.userInput = initUserInputData;

  return initData;
};

const searchDataSlice = createSlice({
  name: "search",
  initialState: rehydrateSearchStore(),
  reducers: {
    updateTripType(state, action) {
      customLog("redux is updating TRIP TYPE");
      const tripType = action.payload;
      state.tripType = tripType;
      if (tripType !== TRIP_TYPE.multicity) {
        state.trips.splice(1);
        state.userInput.trips.splice(1);
      }
    },
    updateSrcLocation(state, action) {
      customLog("redux is updating SOURCE LOCATION");
      const lang = action.payload.lang;
      const tripId = action.payload.tripId;
      const tripIndex = state.trips.findIndex((trip) => trip.id === tripId);

      state.trips[tripIndex].srcLocation = action.payload.location;
      state.trips[tripIndex].srcLocation.isCity = action.payload.isCity;

      const localizedName =
        action.payload.location.names?.[lang] || action.payload.location.city;
      state.userInput.trips[tripIndex].srcLocation = localizedName;
      state.userInput.trips[tripIndex].srcIata = action.payload.location.iata;
    },
    updateDestLocation(state, action) {
      customLog("redux is updating DESTINATION");
      const lang = action.payload.lang;
      const tripId = action.payload.tripId;
      const tripIndex = state.trips.findIndex((trip) => trip.id === tripId);

      state.trips[tripIndex].destLocation = action.payload.location;
      state.trips[tripIndex].destLocation.isCity = action.payload.isCity;

      const localizedName =
        action.payload.location.names?.[lang] || action.payload.location.city;
      state.userInput.trips[tripIndex].destLocation = localizedName;
      state.userInput.trips[tripIndex].destIata = action.payload.location.iata;
    },
    switchDestWithSrcLocation(state, action) {
      customLog("redux is SWITCHING locations");
      const tripId = action.payload.id;
      const lang = action.payload.lang;
      const tripIndex = state.trips.findIndex((trip) => trip.id === tripId);
      const src = { ...state.trips[tripIndex].srcLocation };
      const dest = { ...state.trips[tripIndex].destLocation };

      state.trips[tripIndex].srcLocation = dest;
      state.trips[tripIndex].destLocation = src;

      const localizedSrcName = dest.names?.[lang] || dest.city;
      state.userInput.trips[tripIndex].srcLocation = localizedSrcName;
      state.userInput.trips[tripIndex].srcIata = dest.iata;

      const localizedDestName = src.names?.[lang] || src.city;
      state.userInput.trips[tripIndex].destLocation = localizedDestName;
      state.userInput.trips[tripIndex].destIata = src.iata;
    },
    updateStartDate(state, action) {
      customLog("redux is updating START DATE");
      const tripId = action.payload.tripId;
      const tripIndex = state.trips.findIndex((trip) => trip.id === tripId);
      state.trips[tripIndex].startDate = action.payload.date;
    },
    updateEndDate(state, action) {
      customLog("redux is updating END DATE");
      const tripId = action.payload.tripId;
      const tripIndex = state.trips.findIndex((trip) => trip.id === tripId);
      state.trips[tripIndex].endDate = action.payload.date;
    },
    addPassenger(state, action) {
      customLog("redux is adding a PASSENGER " + action.payload);
      const passengerType = action.payload;
      state.passengers[passengerType]++;
    },
    removePassenger(state, action) {
      customLog("redux is removing a PASSENGER " + action.payload);
      const passengerType = action.payload;
      state.passengers[passengerType]--;
    },
    updatePassengers(state, action) {
      customLog("redux is updating PASSENGERS " + action.payload);
      const passengers = action.payload;
      state.passengers = { ...passengers };
      state.userInput.passengers = { ...passengers };
    },
    updateCabinClass(state, action) {
      customLog("redux is updating CABIN CLASS");
      state.cabinClass = action.payload;
      state.userInput.cabinClass = action.payload;
    },
    updateTripFromSavedSearch(state, action) {
      customLog("redux is updating a trip from HISTORY");
      const tripId = action.payload.tripId;
      const lang = action.payload.lang;
      const tripIndex = state.trips.findIndex((trip) => trip.id === tripId);
      state.trips[tripIndex] = {
        id: state.trips[tripIndex].id,
        ...action.payload.tripData,
      };
      const localizedSrcName =
        action.payload.tripData.srcLocation.names?.[lang] ||
        action.payload.tripData.srcLocation.city;
      const localizedDestName =
        action.payload.tripData.destLocation.names?.[lang] ||
        action.payload.tripData.destLocation.city;
      state.userInput.trips[tripIndex] = {
        id: state.trips[tripIndex].id,
        srcLocation: localizedSrcName,
        srcIata: action.payload.tripData.srcLocation.iata,
        destLocation: localizedDestName,
        destIata: action.payload.tripData.destLocation.iata,
      };
    },
    updateMultiTripStartDates(state, action) {
      const tripId = action.payload.tripId;
      const tripIndex = state.trips.findIndex((trip) => trip.id === tripId);
      for (let i = 0; i < state.trips.length; i++) {
        const earlierDate =
          i < tripIndex ? state.trips[i].startDate : state.trips[tripIndex].startDate;
        const laterDate =
          i > tripIndex ? state.trips[i].startDate : state.trips[tripIndex].startDate;
        if (secondDateIsLater(laterDate, earlierDate)) {
          state.trips[i].startDate = "";
        }
      }
    },
    copyState(state, action) {
      const stateToCopy = action.payload.data;

      state.tripType = stateToCopy.tripType;
      state.cabinClass = stateToCopy.cabinClass;
      state.passengers = { ...stateToCopy.passengers };

      const includedIds = [];
      const newTripsArr = [];
      for (let i = 0; i < stateToCopy.trips.length; i++) {
        const tripToCopy = cloneDeep(stateToCopy.trips[i]);
        includedIds.push(tripToCopy.id);
        newTripsArr.push(tripToCopy);
      }
      state.trips = newTripsArr;
    },
    addTrip(state) {
      customLog("redux is adding a TRIP");
      const lastTrip = state.trips[state.trips.length - 1];
      const newTrip = {
        ...tripModel,
        id: nanoid(),
        srcLocation: lastTrip.destLocation,
        startDate: lastTrip.startDate,
      };
      state.trips.push(newTrip);

      const newUserInput = {
        ...airportUserInputModel,
        id: newTrip.id,
        srcLocation: lastTrip.destLocation.city,
        srcIata: lastTrip.destLocation.iata,
      };
      state.userInput.trips.push(newUserInput);
    },
    removeTrip(state, action) {
      customLog("redux is removing a TRIP");
      const tripId = action.payload;
      const tripIndex = state.trips.findIndex((trip) => trip.id === tripId);
      if (tripIndex > 0 && state.trips.length > 1) {
        state.trips.splice(tripIndex, 1);
        state.userInput.trips.splice(tripIndex, 1);
      }
    },
    updateUserInputTrip(state, action) {
      customLog("redux is updating user input TRIP");
      const tripId = action.payload.tripId;
      const tripIndex = state.userInput.trips.findIndex((item) => item.id === tripId);
      state.userInput.trips[tripIndex] = {
        ...state.userInput.trips[tripIndex],
        ...action.payload.input,
      };
    },
    updateUserInputCabinClass(state, action) {
      customLog("redux is updating user input CABIN CLASS");
      state.userInput.cabinClass = action.payload;
    },
    addUserInputPassenger(state, action) {
      customLog("redux is adding a user input PASSENGER " + action.payload);
      const passengerType = action.payload;
      state.userInput.passengers[passengerType]++;
    },
    removeUserInputPassenger(state, action) {
      customLog("redux is removing a user input PASSENGER " + action.payload);
      const passengerType = action.payload;
      state.userInput.passengers[passengerType]--;
    },
    fillUserInputPassengerOptions(state) {
      customLog("syncing user input cabin/passengers /w search data");
      state.userInput.passengers = { ...state.passengers };
      state.userInput.cabinClass = state.cabinClass;
    },
    fillUserInputTrips(state, action) {
      const lang = action.payload;
      const newUserInputTrips = [];
      for (let i = 0; i < state.trips.length; i++) {
        const trip = state.trips[i];
        newUserInputTrips.push({
          id: trip.id,
          srcLocation: trip.srcLocation?.names[lang] || trip.srcLocation.city,
          srcIata: trip.srcLocation.iata,
          destLocation: trip.destLocation?.names[lang] || trip.destLocation.city,
          destIata: trip.destLocation.iata,
        });
      }
      state.userInput.trips = newUserInputTrips;
    },
    clearUserInputDestLocation(state, action) {
      const tripId = action.payload.tripId;
      const tripIndex = state.userInput.trips.findIndex((item) => item.id === tripId);
      state.userInput.trips[tripIndex].destLocation = "";
      state.userInput.trips[tripIndex].destIata = "";
    },
    clearUserInputSrcLocation(state, action) {
      const tripId = action.payload.tripId;
      const tripIndex = state.userInput.trips.findIndex((item) => item.id === tripId);
      state.userInput.trips[tripIndex].srcLocation = "";
      state.userInput.trips[tripIndex].srcIata = "";
    },
    addUserInputTrip(state, action) {
      const newUserInput = {
        ...airportUserInputModel,
        id: action.payload.tripId,
        srcLocation: action.payload.city,
        srcIata: action.payload.iata,
      };
      state.userInput.trips.push(newUserInput);
    },
    removeUserInputTrip(state, action) {
      const tripId = action.payload.tripId;
      const tripIndex = state.userInput.trips.findIndex((item) => item.id === tripId);
      if (tripIndex > 0 && state.userInput.trips.length > 1) {
        state.userInput.trips.splice(tripIndex, 1);
      }
    },
    changeCityDisplayLanguage(state, action) {
      const lang = action.payload;
      state.userInput.trips.forEach((trip) => {
        const tripFromState = state.trips.find((t) => t.id === trip.id);
        const srcName =
          tripFromState.srcLocation.names?.[lang] || tripFromState.srcLocation.city;
        const destName =
          tripFromState.destLocation.names?.[lang] || tripFromState.destLocation.city;
        trip.srcLocation = srcName;
        trip.destLocation = destName;
      });
    },
  },
});

export const searchDataActions = searchDataSlice.actions;
export default searchDataSlice.reducer;

export const selectTripType = (state) => state.search.tripType;
