import { Interweave } from "interweave";
import React, { useEffect } from "react";
import { STATIC_ELEMENT } from "src/constants/static";
import { useDeviceContext } from "src/context/device-context";
import useStaticContent from "src/hooks/useStaticContent";
import "./Footer.css";
import { useTenantContext } from "src/context/tenant-context";
import { useLocation } from "react-router-dom";

function MobileFooter({ template }) {
  return (
    <div style={{ display: "contents" }} className="footer-mobile">
      <Interweave
        content={template}
        transform={(node, children) => {
          if (node.classList.contains("footer-heading")) {
            return React.createElement(
              node.tagName.toLowerCase(),
              {
                className: "footer-heading collapsed",
                onClick: (e) => {
                  const willExpand = e.currentTarget.classList.contains("collapsed");
                  if (willExpand) {
                    const footer = document.querySelector("footer");
                    const otherHeadings = footer.querySelectorAll(".footer-heading");
                    otherHeadings.forEach((el) => el.classList.add("collapsed"));
                  }
                  e.currentTarget.classList.toggle("collapsed");
                },
              },
              children
            );
          }
        }}
      />
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          height: "4rem",
          paddingBottom: "1rem",
          // marginTop: "1rem",
        }}>
        <img height={50} alt="" src={require(`src/assets/img/images/logo1.png`)} />
        <img height={50} alt="" src={require(`src/assets/img/images/logo2.jpg`)} />
        <img height={50} alt="" src={require(`src/assets/img/images/logo3.jpg`)} />
      </div>
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          justifyContent: "right",
          columnGap: "2rem",
          height: "4rem",
          padding: "0 1rem 1rem",
          // marginTop: "0",
          color: "var(--ui-5",
        }}>
        {`SIA "LATVIA TRAVEL D" īsteno projektu Atveseļošanas un noturības mehānisma plāna 2. komponentes "Digitālā transformācija" 2.2. reformu un investīciju virziena "Uzņēmumu digitālā transformācija un inovācijas" 2.2.1.r. "Uzņēmējdarbības digitālās transformācijas pilna cikla atbalsta izveide ar reģionālo tvērumu" 2.2.1.2.i. investīcijas "Atbalsts procesu digitalizācijai komercdarbībā" programmas ietvaros, projekta līguma Nr. Nr.17.2-5-L-2024/277. ` +
          `Projekta ietvaros uzņēmums veica komercdarbības procesu uzlabošanu: pārdošanas procesi, proti, tiešsaistes lidojumu rezervācijas programmatūras iegāde.`}
      </div>
    </div>
  );
}

function Footer({ onLoad }) {
  const { isMobile } = useDeviceContext();
  const { footer } = useTenantContext();
  const { pathname } = useLocation();
  const { html: template } = useStaticContent(STATIC_ELEMENT.footer);

  const hideFooter = pathname === "/search/results" || !footer;

  useEffect(() => {
    if (!template) return;
    if (onLoad) onLoad("footer");
  }, [onLoad, template]);

  if (hideFooter) return null;
  if (isMobile) return <MobileFooter template={template} />;

  return (
    <>
      <div
        style={{ display: "contents" }}
        className={isMobile ? "footer-mobile" : ""}
        dangerouslySetInnerHTML={{ __html: template }}></div>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          columnGap: "2rem",
          paddingBottom: "1rem",
          marginTop: "0",
          paddingRight: "5rem",
          justifyContent: "flex-end",
        }}>
        <img
          style={{ height: "4rem", width: "auto" }}
          alt=""
          src={require(`src/assets/img/images/logo1.png`)}
        />
        <img
          style={{ height: "4rem", width: "auto" }}
          alt=""
          src={require(`src/assets/img/images/logo2.jpg`)}
        />
        <img
          style={{ height: "4rem", width: "auto" }}
          alt=""
          src={require(`src/assets/img/images/logo3.jpg`)}
        />
      </div>
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          justifyContent: "right",
          columnGap: "2rem",
          height: "4rem",
          padding: "0 5rem 1rem",
          marginTop: "0",
          color: "var(--ui-5)",
        }}>
        {`SIA "LATVIA TRAVEL D" īsteno projektu Atveseļošanas un noturības mehānisma plāna 2. komponentes "Digitālā transformācija" 2.2. reformu un investīciju virziena "Uzņēmumu digitālā transformācija un inovācijas" 2.2.1.r. "Uzņēmējdarbības digitālās transformācijas pilna cikla atbalsta izveide ar reģionālo tvērumu" 2.2.1.2.i. investīcijas "Atbalsts procesu digitalizācijai komercdarbībā" programmas ietvaros, projekta līguma Nr. Nr.17.2-5-L-2024/277. ` +
          `Projekta ietvaros uzņēmums veica komercdarbības procesu uzlabošanu: pārdošanas procesi, proti, tiešsaistes lidojumu rezervācijas programmatūras iegāde.`}
      </div>
    </>
  );
}

export default Footer;
