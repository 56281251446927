import { json } from "react-router-dom";
import store from "src/store";
import { resetAfterBooking } from "src/store/actions";
import { clearBookingSessionData } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import { isLangAbsent, redirectWithLang } from "./utils/loader-utils";

export async function loader({ request, params }) {
  if (isLangAbsent(request)) return redirectWithLang(request);
  customLog(request);
  // const FIVE_MINUTES_IN_SECS = 5 * 60;

  clearBookingSessionData();
  store.dispatch(resetAfterBooking());

  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const paymentType = searchParams.get("type");
  const bookingNumber = searchParams.get("booking-number");
  if (!paymentType) {
    throw json(null, { status: 404 });
  }

  const bookingID = params.orderId;

  //TODO: after leaving checkout, 
  // request a signed token that will be parsed here 
  // check token expiration time & allow page load if not expired

  // if (Date.now() / 1000 - decodedOrderData.iat > FIVE_MINUTES_IN_SECS) {
  //   throw json(null, { status: 410 });
  // }

  return { paymentType, bookingID, bookingNumber };
}
