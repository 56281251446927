import React from "react";

export function InnerCheckmark({ color = "var(--primary-1)", thickness = 2, size = 16 }) {
  return (
    <svg
      style={{ pointerEvents: "none" }}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0.75 0 24 22"
      fill="none"
      stroke={color}
      strokeWidth={thickness}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M5 12l5 5l10 -10" />
    </svg>
  );
}
