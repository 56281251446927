function SwitchDirectionMobile() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="28"
        y="28"
        width="28"
        height="28"
        rx="14"
        transform="rotate(-180 28 28)"
        fill="white"
      />
      <rect
        x="27"
        y="27"
        width="26"
        height="26"
        rx="13"
        transform="rotate(-180 27 27)"
        stroke="var(--primary-1)"
        strokeOpacity="0.3"
        strokeWidth="2"
      />
      <path
        d="M13.3103 11.6533L11.6552 10M11.6552 10L10 11.6533M11.6552 10L11.6552 18M14.6897 16.3467L16.3448 18M16.3448 18L18 16.3467M16.3448 18V10"
        stroke="#001B38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SwitchDirectionMobile;
