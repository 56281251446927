export const ERROR_STATUS_MESSAGE = {
  en: {
    general: "An error occurred",
    booking: "Booking error",
    order: "Order error",
    service: "Service error",
    notfound: "Page not found",
    notauthorized: "You aren't authorized to see this 👀",
    apidown: "Looks like our API is down",
    expired: "Link has expired",
    unavailable: "We're Working on It! 🚧",
  },
  ru: {
    general: "Произошла ошибка",
    booking: "Ошибка при бронировании",
    order: "Ошибка при оформлении заказа",
    service: "Ошибка при получении услуги",
    notfound: "Страница не найдена",
    notauthorized: "Вы не имеете права доступа к этой странице 👀",
    apidown: "Похоже наш API недоступен",
    expired: "Ссылка устарела",
    unavailable: "Мы работаем над этим! 🚧",
  },
  lv: {
    general: "Radās kļūda",
    booking: "Rezervācijas kļūda",
    order: "Pasūtījuma kļūda",
    service: "Pakalpojuma kļūda",
    notfound: "Lapa nav atrasta",
    notauthorized: "Jums nav tiesību to skatīt 👀",
    apidown: "Izskatās, ka mūsu API nedarbojas",
    expired: "Saite ir pabeigusi",
    unavailable: "Mēs pie tā strādājam! 🚧",
  },
  lt: {
    general: "Įvyko klaida",
    booking: "Rezervacijos klaida",
    order: "Užsakymo klaida",
    service: "Paslaugos klaida",
    notfound: "Puslapis nerastas",
    notauthorized: "Jūs neturite teisės to matyti 👀",
    apidown: "Atrodo, kad mūsų API neveikia",
    expired: "Nuoroda pasibaigė",
    unavailable: "Mes dirbame! 🚧",
  },
  et: {
    general: "Tekkis viga",
    booking: "Broneerimisviga",
    order: "Tellimuse viga",
    service: "Teenuse viga",
    notfound: "Lehekülge ei leitud",
    notauthorized: "Teil ei ole õigust seda näha 👀",
    apidown: "Tundub, et meie API ei tööta",
    expired: "Link on aegunud",
    unavailable: "Me töötame selle kallal! 🚧",
  },
};

export const ERROR_MESSAGES = {
  en: {
    general: [
      "Uh-oh! It seems like something unexpected happened on our end.",
      "Please try again later or contact support if the issue persists.",
    ],
    booking: [
      "Oops! Your flight booking took a wrong turn. Let's find you a new one!",
      "Head back to the homepage or give it another go with our flight search.",
    ],
    notfound: [
      "Looks like you've wandered off the beaten path. Let's get you back on track!",
      "Please double-check the URL or return to the homepage.",
    ],
    notauthorized: [
      "Looks like you need to sign in to access this page. Let's get you back on track!",
      "Please log in or ensure you have the necessary permissions.",
    ],
    apidown: [
      "Looks like our servers are a bit overwhelmed. Let's get you back on track!",
      "Please try refreshing the page or come back later.",
    ],
    expired: [
      "Whoops! This link has expired. Let's get you back on track!",
      "Please return to the homepage, or contact us for further assistance.",
    ],
    unavailable: [
      "This page is currently under development and will be available soon. ",
      "Thanks for your patience while we make things better for you.",
      "Please check back later!",
    ],
  },
  ru: {
    general: [
      "Упс! Кажется, у нас произошла неожиданная ошибка.",
      "Пожалуйста, попробуйте позже или свяжитесь с поддержкой, если проблема не исчезнет.",
    ],
    booking: [
      "Упс! Что-то пошло не так с вашим бронированием. Давайте найдем вам новый рейс!",
      "Вернитесь на главную страницу или попробуйте снова с нашим поиском рейсов.",
    ],
    notfound: [
      "Похоже, вы свернули не туда. Давайте вернем вас на правильный путь!",
      "Пожалуйста, проверьте URL или вернитесь на главную страницу.",
    ],
    notauthorized: [
      "Похоже, вам нужно войти, чтобы получить доступ к этой странице.",
      "Пожалуйста, войдите в систему или убедитесь, что у вас есть необходимые разрешения.",
    ],
    apidown: [
      "Похоже, наши серверы перегружены.",
      "Пожалуйста, попробуйте обновить страницу или зайдите позже.",
    ],
    expired: [
      "Упс! Эта ссылка истекла. Давайте вернем вас на правильный путь!",
      "Пожалуйста, вернитесь на главную страницу или свяжитесь с нами для дальнейшей помощи.",
    ],
    unavailable: [
      "Эта страница находится в стадии разработки и скоро будет доступна.",
      "Спасибо за терпение, пока мы делаем вещи лучше для вас.",
      "Пожалуйста, загляните позже!",
    ],
  },
  lv: {
    general: [
      "Ak! Izskatās, ka radās neparedzēta kļūda.",
      "Lūdzu, mēģiniet vēlāk vai sazinieties ar atbalstu, ja problēma nepazudīs.",
    ],
    booking: [
      "Ak! Kaut kas nogāja greizi ar jūsu rezervāciju. Atradīsim jums jaunu reisu!",
      "Atgriezieties sākumlapā vai mēģiniet vēlreiz ar mūsu lidojumu meklēšanu.",
    ],
    notfound: [
      "Izskatās, ka esat nogriezies nepareizā virzienā. Atgriezīsim jūs uz pareizā ceļa!",
      "Lūdzu, pārbaudiet URL vai atgriezieties sākumlapā.",
    ],
    notauthorized: [
      "Izskatās, ka jums ir jāielogojas, lai piekļūtu šai lapai.",
      "Lūdzu, ieejiet sistēmā vai pārliecinieties, ka jums ir nepieciešamās atļaujas.",
    ],
    apidown: [
      "Izskatās, ka mūsu serveri ir pārpildīti.",
      "Lūdzu, mēģiniet atjaunināt lapu vai mēģiniet vēlāk.",
    ],
    expired: [
      "Ak! Šī saite ir beigusies. Atgriezīsim jūs uz pareizā ceļa!",
      "Lūdzu, atgriezieties sākumlapā vai sazinieties ar mums, lai saņemtu papildu palīdzību.",
    ],
    unavailable: [
      "Šī lapa pašlaik tiek izstrādāta un drīzumā būs pieejama.",
      "Paldies par jūsu pacietību, kamēr mēs jums nodrošināsim labākas iespējas.",
      "Lūdzu, pārbaudiet vēlāk!",
    ],
  },
  lt: {
    general: [
      "Oi! Panašu, kad įvyko netikėta klaida.",
      "Prašome pabandyti vėliau arba susisiekti su palaikymu, jei problema išlieka.",
    ],
    booking: [
      "Oi! Kažkas nepavyko su jūsų rezervacija. Raskime jums naują skrydį!",
      "Grįžkite į pagrindinį puslapį arba pabandykite dar kartą su mūsų skrydžių paieška.",
    ],
    notfound: [
      "Panašu, kad nuklydote nuo kelio. Grąžinkime jus į teisingą kelią!",
      "Prašome patikrinti URL arba grįžkite į pagrindinį puslapį.",
    ],
    notauthorized: [
      "Panašu, kad jums reikia prisijungti, kad galėtumėte pasiekti šį puslapį.",
      "Prašome prisijungti arba įsitikinkite, kad turite reikiamus leidimus.",
    ],
    apidown: [
      "Panašu, kad mūsų serveriai yra perkrauti.",
      "Prašome pabandyti atnaujinti puslapį arba grįžti vėliau.",
    ],
    expired: [
      "Oi! Ši nuoroda baigė galioti. Grąžinkime jus į teisingą kelią!",
      "Prašome grįžti į pagrindinį puslapį arba susisiekite su mumis dėl papildomos pagalbos.",
    ],
    unavailable: [
      "Šis puslapis šiuo metu kuriamas ir netrukus bus prieinamas.",
      "Dėkojame už kantrybę, kol viską tobulinsime.",
      "Prašome patikrinti vėliau!",
    ],
  },
  et: {
    general: [
      "Oi! Tundub, et meil tekkis ootamatu viga.",
      "Palun proovige hiljem uuesti või võtke ühendust toega, kui probleem püsib.",
    ],
    booking: [
      "Oi! Midagi läks valesti teie broneeringuga. Leiame teile uue lennu!",
      "Minge tagasi avalehele või proovige uuesti meie lennuotsinguga.",
    ],
    notfound: [
      "Tundub, et olete eksinud rajalt. Viime teid tagasi õigesse suunda!",
      "Palun kontrollige URL-i või minge tagasi avalehele.",
    ],
    notauthorized: [
      "Tundub, et peate sellele lehele pääsemiseks sisse logima.",
      "Palun logige sisse või veenduge, et teil on vajalikud õigused.",
    ],
    apidown: [
      "Tundub, et meie serverid on ülekoormatud.",
      "Palun proovige lehte värskendada või tulge hiljem tagasi.",
    ],
    expired: [
      "Oi! See link on aegunud. Viime teid tagasi õigesse suunda!",
      "Palun minge tagasi avalehele või võtke meiega ühendust, et saada lisateavet.",
    ],
    unavailable: [
      "See lehekülg on praegu arendamisel ja on peagi saadaval.",
      "Täname teid kannatlikkuse eest, kuni me teeme asju teie jaoks paremaks.",
      "Palun vaadake hiljem tagasi!",
    ],
  },
};

export const TRANSALTED_STRINGS = {
  en: { homepage: "Homepage", back: "Go Back" },
  lv: { homepage: "Sākumlapa", back: "Atpakaļ" },
  ru: { homepage: "Главная страница", back: "Назад" },
  lt: { homepage: "Pagrindinis puslapis", back: "Grįžti atgal" },
  et: { homepage: "Avaleht", back: "Mine tagasi" },
};
