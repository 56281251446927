import { IconPhotoFilled } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "src/components/UI/Button/Button";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import { useDeviceContext } from "src/context/device-context";
import { destinationsApi } from "src/services/api";
import { searchDataModel } from "src/store/models";
import { buildSearchQuery } from "src/utils/query-utils";
import { customLog } from "src/utils/utils";
import classes from "./TopDestinations.module.css";
import { useLocaleContext } from "src/context/locale-context";

function Destination({ deal }) {
  const [imgErr, setImgErr] = useState(false);
  const { stringRes } = useLocaleContext();

  const getSeachUrl = () => {
    const searchData = { ...searchDataModel };
    searchData.tripType = "oneway";
    searchData.cabinClass = "all";
    searchData.passengers.adult = 1;
    searchData.passengers.child = 0;
    searchData.passengers.infant = 0;
    searchData.trips = [
      {
        srcLocation: {
          city: deal.city,
          iata: deal.iata,
          cityIata: deal.iata, //TODO: get city iata from API
        },
        destLocation: {
          city: deal.city,
          iata: deal.iata,
          cityIata: deal.iata,
        },
        startDate: deal.date,
        endDate: "",
      },
    ];
    return buildSearchQuery(searchData);
  };

  return (
    <NavLink to={deal.link.startsWith("/") ? deal.link : `/${deal.link}`}>
      {imgErr ? (
        <div className={classes.imgError}>
          <div className={classes.frame}>
            <IconPhotoFilled size={"100%"} color="var(--ui-4)" />
          </div>
        </div>
      ) : (
        <img
          onError={() => setImgErr(true)}
          alt={deal.city}
          src={
            `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
            `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/cities/` +
            `${deal.iata}.jpg`
          }
        />
      )}
      <div className={classes.destination}>
        <h2>{deal.city}</h2>
        <p>
          {stringRes["cheap.travel.deals.item.flightsFrom"]} {deal.price} €
        </p>
      </div>
    </NavLink>
  );
}

function DestinationGroup({ rows, cols, totalVisible, travelDeals }) {
  return (
    <ul
      style={{
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gridTemplateRows: `repeat(${rows}, 1fr)`,
        width: `calc(${cols} )`,
      }}
      className={classes.dealsList}>
      {travelDeals.slice(0, totalVisible).map((deal) => (
        <motion.li key={deal.id}>
          <Destination deal={deal} />
        </motion.li>
      ))}
    </ul>
  );
}

const FILTER_CAT = ["50orLess", "citybreaks", "onlydirect", "family"];

function TopDestinations() {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const [currentFilter, setSelectedFilter] = useState(0);
  const [visibleItemNum, setVisibleItemNum] = useState(0);

  const getInitialItemNum = useCallback((col, row, total) => {
    return Math.min(col * row, total);
  }, []);

  const [travelDeals, setTravelDeals] = useState([]);
  const hasMoreOffers = travelDeals.length > visibleItemNum;
  const COL_NUM = Math.min(isMobile ? 2 : 5, travelDeals.length);
  const ROW_NUM = Math.min(isMobile ? 3 : 2, Math.ceil(travelDeals.length / COL_NUM));

  useEffect(() => {
    destinationsApi
      .getTravelDeals()
      .then((deals) => {
        setTravelDeals(deals.data);
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  }, []);

  useEffect(() => {
    setVisibleItemNum(getInitialItemNum(COL_NUM, ROW_NUM, travelDeals.length));
  }, [COL_NUM, ROW_NUM, travelDeals.length, getInitialItemNum]);

  const onClickFilterHandler = (selectedFilter) => {
    customLog("touched");
    setSelectedFilter(selectedFilter);
    // get filtered data
  };

  const onSeeMoreOffersHandler = () => {
    customLog("see more offers");
    setVisibleItemNum((visible) => Math.min(visible + ROW_NUM * COL_NUM, travelDeals.length));
    // load more data
  };

  const onCollapseOffersHandler = () => {
    setVisibleItemNum(COL_NUM * ROW_NUM);
  };

  if (travelDeals.length === 0) {
    return null;
  }

  return (
    <section className={`${classes.deals} ${isMobile ? classes.mobile : classes.desktop}`}>
      <PaddedView>
        <h1>{stringRes["cheap.travel.deals.title"]}</h1>
        <p className={classes.desc}>{stringRes["cheap.travel.deals.description"]}</p>
        <div className={`${classes.container} ${classes.filterGroup}`}>
          {FILTER_CAT.slice(0, 1).map((cat, i) => (
            <Button
              key={cat}
              name={stringRes[`cheap.travel.deals.filter.${cat}`]}
              fontclass={`${classes.filterButtonFont} ${
                !isMobile && i === currentFilter ? classes.active : ""
              }`}
              onClick={(e) => onClickFilterHandler(i)}
              {...(i === currentFilter ? { ternary: true } : { secondary: true })}
            />
          ))}
        </div>
        <DestinationGroup
          cols={COL_NUM}
          rows={ROW_NUM}
          totalVisible={visibleItemNum}
          travelDeals={travelDeals}
        />
        {travelDeals.length > ROW_NUM * COL_NUM && (
          <div style={{ textAlign: "center" }}>
            <Button
              name={
                hasMoreOffers
                  ? stringRes["cheap.travel.deals.button.more"]
                  : stringRes["cheap.travel.deals.button.less"]
              }
              opts={{ padding: "1rem 1.25rem", marginBottom: "1.5rem" }}
              onClick={hasMoreOffers ? onSeeMoreOffersHandler : onCollapseOffersHandler}
              secondary
            />
          </div>
        )}
      </PaddedView>
    </section>
  );
}

export default TopDestinations;
