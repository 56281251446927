import classes from "./CounterItem.module.css";
import IncrementButton from "src/components/UI/IncrementButton/IncrementButton";
import DecrementButton from "src/components/UI/DecrementButton/DecrementButton";

export function CounterItem({ value, onAdd, onRemove, canAdd, canRemove, className }) {
  return (
    <div
      className={`${classes.container} ${classes.counter} ${className ? className : ""}`}
    >
      <DecrementButton onClick={onRemove} disabled={!canRemove} />
      <span className={classes.count}>{value}</span>
      <IncrementButton onClick={onAdd} disabled={!canAdd} />
    </div>
  );
}
