import classes from "./ButtonWithIcon.module.css";

function ButtonWithIcon(props) {
  const ImageElement = ({ imgClass }) => {
    if (props.imgEl) {
      const ImgComponent = props.imgEl;
      return <ImgComponent imageClass={imgClass} />;
    } else {
      return (
        <img
          className={imgClass}
          width={props.imgWidth}
          height={props.imgHeight}
          alt={props.name}
          src={props.img}
        />
      );
    }
  };

  return (
    <button
      type="button"
      aria-label={props.name}
      className={`${classes.button} ${props.className ? props.className : ""}`}
      onClick={props.onClick}>
      <span className={classes.container}>
        <ImageElement imgClass={classes.icon} />
        {props.children && <span>{props.children}</span>}
      </span>
    </button>
  );
}

export default ButtonWithIcon;
