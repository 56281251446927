import { Fragment } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { FilteredLocationItemGroup } from "../LocationItemGroup/FilteredLocationItemGroup";
import { PopularCityItemGroup } from "../LocationItemGroup/PopularCityItemGroup";
import { SavedSearchItemGroup } from "../SavedSearchItemGroup/SavedSearchItemGroup";
import { userInputTag } from "./AirportSelection";
import { EmptyFilterResults } from "./EmptyFilterResults";

export function AirportSelectionDropdown({
  userInput,
  popularCityItems,
  filterItems,
  isUserFiltered,
  notFound,
  savedSearches,
  isDeparture,
}) {
  const { stringRes } = useLocaleContext();
  const hasSavedSearch = savedSearches && savedSearches.length > 0;
  return (
    <Fragment>
      {isDeparture && !isUserFiltered && hasSavedSearch && (
        <SavedSearchItemGroup
          items={savedSearches}
          title={stringRes["sf.savedSearch.title"]}
        />
      )}
      {isUserFiltered && filterItems.length > 0 && (
        <FilteredLocationItemGroup items={filterItems} isDeparture={isDeparture} />
      )}
      {!isUserFiltered && (
        <PopularCityItemGroup
          title={stringRes[isDeparture ? "sf.mobile.subtitle.from" : "sf.mobile.subtitle.to"]}
          items={popularCityItems}
          isDeparture={isDeparture}
        />
      )}
      {notFound && (
        <EmptyFilterResults message={userInputTag(stringRes["sf.result.empty"], userInput)} />
      )}
    </Fragment>
  );
}
