import { XIconMobile } from "../Icon/XIcon";
import classes from "./CloseWindowButton.module.css";
import { useDeviceContext } from "src/context/device-context";

export function CloseWindowButton({ onClick }) {
  const { isMobile } = useDeviceContext();
  return (
    <div
      role="button"
      className={`${classes.exit} ${isMobile ? classes.mobile : ""}`}
      onClick={() => onClick()}>
      <XIconMobile />
    </div>
  );
}
