import { forwardRef } from "react";
import Card from "src/components/UI/Card/Card";

const SidePanel = forwardRef(function SidePanel({ opts, isClosing, children }, ref) {
  return (
    <Card
      ref={ref}
      className={"sidebar " + (isClosing ? "close" : "open")}
      opts={{ ...opts }}
      shadowLg>
      {children}
    </Card>
  );
});
export default SidePanel;
