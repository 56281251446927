import { BOOKING } from "src/constants/booking";
import { customLog } from "../utils";
import {
  DIGIT_PATTERN,
  EMAIL_PATTERN
} from "src/constants/validation";


export function getContactDataListenerOpts(send) {
  return {
    type: "booking/updateContactData",
    effect: (action, listenerApi) => {
      customLog(action);
      const _contact = listenerApi.getState().booking.contact;
      const bookingCommands = [];

      bookingCommands.push({
        command: BOOKING.UPDATE_CONTACT_INFO,
        payload: { ..._contact },
      });

      if (_contact.email &&
        _contact.tel &&
        EMAIL_PATTERN.test(_contact.email) &&
        DIGIT_PATTERN.test(_contact.tel) &&
        _contact.tel.length >= 8) {
        bookingCommands.push({ command: BOOKING.CONTACT_DATA_COMPLETE });
      }

      bookingCommands.forEach((nextAction) => send(nextAction));
    },
  };
}
