import { createContext, useCallback, useState } from "react";
import { customLog } from "src/utils/utils";

export const PriceExpiryContext = createContext({
  searchExpired: false,
  bookingExpired: false,
  setBookingPriceTimer: (expiry, callback) => {},
  setSearchExpiryTimer: (expiry, callback) => {},
  clearSearchTimer: () => {},
  clearBookingTimer: () => {},
});

const MILLIS_TO_EXPIRY = 30 * 60 * 1000;

export const PriceExpiryManager = (props) => {
  const [state, setState] = useState({
    search: { expiryTime: 0, hasExpired: false, timerRef: null },
    booking: { expiryTime: 0, hasExpired: false, timerRef: null },
  });

  const clearTimer = useCallback((type) => {
    let timerRef;
    setState((prev) => {
      timerRef = prev[type].timerRef;
      return {
        ...prev,
        [type]: {
          ...prev[type],
          expiryTime: 0,
          hasExpired: false,
          timerRef: null,
        },
      };
    });
    clearTimeout(timerRef);
  }, []);

  const clearBookingTimer = useCallback(() => {
    clearTimer("booking");
  }, [clearTimer]);

  const clearSearchTimer = useCallback(() => {
    clearTimer("search");
  }, [clearTimer]);

  const setSearchResultsTimer = useCallback(
    (expiry, callback) => {
      customLog("resetting search results timer; expiry val: " + expiry);
      clearTimer("search");

      let flightTicketTimeToExpiry = expiry * 1000 - Date.now();
      let timerLength =
        flightTicketTimeToExpiry >= MILLIS_TO_EXPIRY
          ? MILLIS_TO_EXPIRY
          : flightTicketTimeToExpiry > 0
          ? flightTicketTimeToExpiry
          : 0;
      customLog("search res timer (min): " + timerLength / (1000 * 60));
      const timerId = setTimeout(() => {
        setState((prev) => {
          return {
            ...prev,
            search: { ...prev.search, hasExpired: true, timerRef: null },
          };
        });
        customLog("search res timer has been cleared");
        if (callback) callback();
      }, timerLength);

      setState((prev) => {
        return {
          ...prev,
          search: {
            ...prev.search,
            expiryTime: Date.now() + MILLIS_TO_EXPIRY,
            hasExpired: false,
            timerRef: timerId,
          },
        };
      });
    },
    [clearTimer]
  );

  const setBookingTimer = useCallback(
    (expiry, callback) => {
      customLog("resetting booking timer; expiry val: " + expiry);
      clearTimer("booking");

      let flightTicketTimeToExpiry = expiry * 1000 - Date.now();
      let timerLength =
        flightTicketTimeToExpiry >= MILLIS_TO_EXPIRY
          ? MILLIS_TO_EXPIRY
          : flightTicketTimeToExpiry > 0
          ? flightTicketTimeToExpiry
          : 0;
      customLog("booking timer (min): " + timerLength / (1000 * 60));
      const timerId = setTimeout(() => {
        setState((prev) => {
          return {
            ...prev,
            booking: {
              ...prev.booking,
              hasExpired: true,
              timerRef: null,
            },
          };
        });
        customLog("booking timer has been cleared");
        if (callback) callback();
      }, timerLength);

      setState((prev) => {
        return {
          ...prev,
          booking: {
            ...prev.booking,
            expiryTime: Date.now() + flightTicketTimeToExpiry,
            hasExpired: false,
            timerRef: timerId,
          },
        };
      });
    },
    [clearTimer]
  );

  return (
    <PriceExpiryContext.Provider
      value={{
        searchExpired: state.search.hasExpired,
        bookingExpired: state.booking.hasExpired,
        setSearchExpiryTimer: setSearchResultsTimer,
        setBookingPriceTimer: setBookingTimer,
        clearSearchTimer,
        clearBookingTimer,
      }}>
      {props.children}
    </PriceExpiryContext.Provider>
  );
};
