import { createSlice } from "@reduxjs/toolkit";
import { BAG_WEIGHT_CATEGORY, PASSENGER } from "src/constants";
import { CATALOGUE_IDS, FULL_CATALOGUE } from "src/constants/services";
import {
  getCabinBaggageBundlePrice,
  getCabinBaggageUpgradeBundlePrice,
  getRegisteredBaggagePrice,
} from "src/utils/baggage-utils";
import { getDateDifferenceInDays } from "src/utils/date-utils";
import { getAllFlights, getPricePerPerson } from "src/utils/results-utils";
import {
  addPassenger,
  removePassenger,
  updatePassenger,
  updateTravelPackage,
} from "./actions";

const calculatePricePerUnit = (base, markup, isPercent) => {
  let price;
  if (isPercent) {
    price = (base * markup) / 100;
  } else {
    price = base + markup;
  }
  return Math.round(price * 100) / 100;
};

const calculatePricePerSeatUnit = (base, markup, isPercent) => {
  let price;
  if (isPercent) {
    price = base + (base * markup) / 100;
  } else {
    price = base + markup;
  }
  return Math.round(price * 100) / 100;
};

// const priceTerms = [
//   "per_piece",
//   "per_person",
//   "per_order",
//   "whole_flight",
//   "per_segment",
//   "per_airport",
//   "per_day",
// ];

const catalogueSlice = createSlice({
  name: "catalogue",
  initialState: { catalogue: FULL_CATALOGUE, serviceData: null, ancillary: null },
  reducers: {
    loadServiceData(state, action) {
      const serviceData = action.payload;
      state.serviceData = serviceData;
    },
    loadAncillaryData(state, action) {
      const ancillaryData = action.payload;
      state.ancillary = ancillaryData;
    },
    loadCatalogue(state, action) {
      const newProducts = action.payload;
      state.catalogue = newProducts;
    },
    calculateCatalogue(state, action) {
      const travelPackage = action.payload.travelPackage;
      const paxNum = action.payload.paxNum;
      const infantNum = action.payload.infantNum;
      const avgFarePrice = getPricePerPerson(travelPackage);
      const segments = getAllFlights(travelPackage);

      const travelDays =
        getDateDifferenceInDays(
          segments[0].dep.date,
          segments[segments.length - 1].arr.date,
          "DD.MM.YYYY"
        ) + 1;

      for (let i = 0; i < state.catalogue.length; i++) {
        if (state.catalogue[i].isAdditionalService) {
          const isPercent = state.catalogue[i].data.agent_markup.currency === "PERCENTAGE";
          const markup = state.catalogue[i].data.agent_markup.price;
          const policy = state.catalogue[i].data.policy;
          const base = isPercent
            ? avgFarePrice * (policy?.whole_flight ? paxNum : 1)
            : state.catalogue[i].data.net_price.price;

          const vtrip_policy = state.catalogue[i].data.vtrip_policy;
          const isVTrip = travelPackage.is_vtrip;
          const minPrice = state.catalogue[i].data.min_price?.price || 0;

          let unitPrice = 0;
          let netUnitPrice = 0;
          let totalPrice = 0;
          let netTotalPrice = 0;

          const vars = [base, markup, isPercent];
          const multipliers = [1];
          unitPrice = calculatePricePerUnit(...vars);
          unitPrice = unitPrice < minPrice ? minPrice : unitPrice;

          const targetPolicy = isVTrip && vtrip_policy ? vtrip_policy : policy;
          if (targetPolicy) {
            if (targetPolicy.per_order || targetPolicy.per_piece) {
              multipliers.push(1);
            }
            if (targetPolicy.per_person) {
              multipliers.push(paxNum);
            }
            if (targetPolicy.per_day) {
              multipliers.push(travelDays);
            }
            if (targetPolicy.per_segment || targetPolicy.per_airport) {
              multipliers.push(segments.length);
            }
          }

          const unitWholes = Math.floor(unitPrice);
          const unitCents = Math.round((unitPrice % 1) * 100);
          const factor = multipliers.reduce((a, b) => a * b);
          totalPrice = unitWholes * factor + (unitCents * factor) / 100;

          netUnitPrice = state.catalogue[i].data.net_price.price;
          const netUnitWholes = Math.floor(netUnitPrice);
          const netUnitCents = Math.round((netUnitPrice % 1) * 100);
          netTotalPrice = netUnitWholes * factor + (netUnitCents * factor) / 100;

          state.catalogue[i].data.total_price = {
            ...state.catalogue[i].data.total_price,
            price: totalPrice,
          };
          state.catalogue[i].data.net_total_price = {
            currency: state.catalogue[i].data.total_price.currency,
            price: netTotalPrice,
          };
          state.catalogue[i].unit_price = unitPrice;
          state.catalogue[i].sum_total = totalPrice;
        } else if (state.catalogue[i].isSeatsService) {
          Object.keys(state.catalogue[i].data).forEach((airline) => {
            const isPercent =
              state.catalogue[i].data[airline].markup.currency === "PERCENTAGE";
            const markup = state.catalogue[i].data[airline].markup.price;
            const base = state.catalogue[i].data[airline].net_price.price;

            const vars = [base, markup, isPercent];
            const multipliers = [paxNum - infantNum];
            let totalPrice = calculatePricePerSeatUnit(...vars);

            const priceWholes = Math.floor(totalPrice);
            const priceCents = Math.round((totalPrice % 1) * 100);
            const factor = multipliers.reduce((a, b) => a * b);
            totalPrice = priceWholes * factor + (priceCents * factor) / 100;

            let netUnitPrice = state.catalogue[i].data[airline].net_price.price;
            const netUnitWholes = Math.floor(netUnitPrice);
            const netUnitCents = Math.round((netUnitPrice % 1) * 100);
            let netTotalPrice = netUnitWholes * factor + (netUnitCents * factor) / 100;

            state.catalogue[i].data[airline].total_price = {
              ...state.catalogue[i].data[airline].total_price,
              price: totalPrice,
            };
            state.catalogue[i].data[airline].net_total_price = {
              currency: state.catalogue[i].data[airline].total_price.currency,
              price: netTotalPrice,
            };
            state.catalogue[i].data[airline].unit_price = totalPrice;
            state.catalogue[i].data[airline].sum_total = totalPrice;
          });
        } else {
          state.catalogue[i].unit_price = 0;
          state.catalogue[i].sum_total = 0;
        }
      }

      const targetServicesIds = [
        CATALOGUE_IDS.pi_baggage,
        CATALOGUE_IDS.cob_baggage,
        CATALOGUE_IDS.cob_baggage_upgrade,
        CATALOGUE_IDS.r_baggage_def,
        CATALOGUE_IDS.r_baggage_larger,
        CATALOGUE_IDS.r_baggage_double,
      ];

      const targetServicesPrices = [
        0,
        getCabinBaggageBundlePrice(travelPackage),
        getCabinBaggageUpgradeBundlePrice(travelPackage),
        getRegisteredBaggagePrice(travelPackage, false, BAG_WEIGHT_CATEGORY.default),
        getRegisteredBaggagePrice(travelPackage, false, BAG_WEIGHT_CATEGORY.larger),
        getRegisteredBaggagePrice(travelPackage, true),
      ];

      const targetServicesIdx = targetServicesIds.map((targetId) =>
        state.catalogue.findIndex((item) => item.id === targetId)
      );

      targetServicesIdx.forEach((serviceIdx, i) => {
        if (serviceIdx !== -1) {
          state.catalogue[serviceIdx].unit_price = targetServicesPrices[i];
          state.catalogue[serviceIdx].sum_total = targetServicesPrices[i];
        }
      });
    },
  },
});

export const catalogueActions = catalogueSlice.actions;
export default catalogueSlice.reducer;

export const selectCatalogue = (state) => {
  return state.catalogue.catalogue;
};

export const selectAncillaryData = (state) => {
  return state.catalogue.ancillary;
};

export const selectCatalogueItem = (catalogue, serviceId) => {
  return catalogue.find((service) => service.id === serviceId);
};

export const selectServicePrice = (catalogue, serviceId, identifier) => {
  const serviceObj = catalogue.find((service) => service.id === serviceId);
  if (Object.values(CATALOGUE_IDS.seats).includes(serviceId)) {
    return serviceObj.data[identifier].sum_total;
  } else {
    return serviceObj ? serviceObj.sum_total : undefined;
  }
};

export const selectServiceUnitPrice = (catalogue, serviceId) => {
  const serviceObj = catalogue.find((service) => service.id === serviceId);
  return serviceObj ? serviceObj.unit_price : undefined;
};

export const catalogueMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  // actions that trigger ctalogue recalculation
  const actionsToRecalculate = [
    addPassenger.type,
    updatePassenger.type,
    removePassenger.type,
    updateTravelPackage.type,
  ];

  const currentUrl = window.location.href;
  const currentPage = currentUrl
    .split(window.location.origin)[1]
    ?.split("?")[0]
    .split("/")
    .filter((path) => path)?.[0];

  if (currentPage === "booking" && actionsToRecalculate.includes(action.type)) {
    console.log("catalogue middleware working");
    const travelPackage = store.getState().booking.travelPackage;
    const passengers = store.getState().booking.passengers;
    const paxNum = passengers.length;
    const infantNum = passengers.filter((f) => f.ageGroup === PASSENGER.infant).length;
    store.dispatch(catalogueActions.calculateCatalogue({ travelPackage, paxNum, infantNum }));
  }

  return result;
};
