function SwitchDirectionDesktop() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="32"
        y="32"
        width="32"
        height="32"
        rx="16"
        transform="rotate(-180 32 32)"
        fill="white"
      />
      <rect
        x="31"
        y="31"
        width="30"
        height="30"
        rx="15"
        transform="rotate(-180 31 31)"
        stroke="var(--primary-1)"
        strokeOpacity="0.3"
        strokeWidth="2"
      />
      <path
        d="M12.48 17.0345L10 19.5172M10 19.5172L12.48 22M10 19.5172L22 19.5172M19.52 14.9655L22 12.4828M22 12.4828L19.52 10M22 12.4828L10 12.4828"
        stroke="#001B38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SwitchDirectionDesktop;
