import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import TagManager from "react-gtm-module";

const currentDomain = window.location.origin;

// INIT GOOGLE TAG MANAGER & ATTACH COOKIEBOT SCRIPT
// let scriptTag = document.createElement("script");
// scriptTag.id = "Cookiebot";
// scriptTag.src = "https://consent.cookiebot.com/uc.js";
// scriptTag.setAttribute(
//   "data-cbid",
//   currentDomain === "https://avion.toposoft.lv"
//     ? "adfaec8b-9426-4f46-88e9-5e1bd593337a"
//     : "3aa9b4b7-accf-4958-868d-ef877d7cb018"
// );
// scriptTag.type = "text/javascript";
// document.head.appendChild(scriptTag);

const tagManagerArgs = { gtmId: window.SERVER_DATA.REACT_APP_GTM_ID };
TagManager.initialize(tagManagerArgs);

// ATTACH CUSTOM TENANT STYLES
const cssScript = document.createElement("link");
cssScript.rel = "stylesheet";
cssScript.type = "text/css";
cssScript.href =
  `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
  `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/static/` +
  `${window.SERVER_DATA.REACT_APP_TENANT_NAME}/en/assets/styles.css`;
document.head.appendChild(cssScript);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals(console.log);
