import SaleBanners from "src/components/features/home/SaleBanners/SaleBanners";
import TopDestinations from "src/components/features/home/TopDestinations/TopDestinations";
import SearchForm from "src/components/features/search/SearchForm/SearchForm";
import Footer from "src/components/shared/footer/Footer/Footer";
import { useDeviceContext } from "src/context/device-context";
import useSyncChildren from "src/hooks/useSyncChildren";

function HomePage() {
  const { isMobile } = useDeviceContext();
  const { onLoad, isSynced } = useSyncChildren(["footer"]);

  return (
    <div style={{ display: isSynced ? "contents" : "none" }}>
      <main id="tickets-home-page" className={isMobile ? "mobile" : "desktop"}>
        <SearchForm />
        <div style={{ backgroundColor: "#fff" }}>
          <SaleBanners />
          <TopDestinations />

          {/* <section style={{ width: "100%", margin: isMobile ? "3rem 0 0" : "5rem 0" }}>
            <div style={{ padding: isMobile ? "0" : "0 15.25rem" }}>
              <h1 style={{ fontSize: "20px", marginBottom: "1rem" }}>
                Tailor your travel experience to your individual needs See more services
              </h1>
              <div
                style={{
                  height: "220px",
                  border: "1px solid var(--ui-2)",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  borderRadius: "10px",
                }}>
                {[1, 2, 3, 4].map((i) => (
                  <div
                    key={i}
                    style={{
                      width: "100%",
                      backgroundColor: "#f0f3f570",
                      borderRadius: "10px",
                      margin: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                    }}>
                    {[1, 2, 3, 4].map((j) => (
                      <div
                        key={`${i}-${j}`}
                        style={{
                          height: "20px",
                          width: (100 * 1) / (j + 1 / 4) + "%",
                          backgroundColor: "#bac7d51f",
                          borderRadius: "10px",
                          margin: "10px",
                        }}></div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </section> */}
        </div>
      </main>
      <Footer onLoad={onLoad} />
    </div>
  );
}

export default HomePage;
