import { useCallback, useState } from "react";

export default function useSyncChildren(requiredChildren = []) {
  const [childrenAvailable, setChildrenAvailable] = useState([]);
  const isSynced = requiredChildren.every((name) => childrenAvailable.includes(name));

  const resetAvailableChildren = useCallback(() => setChildrenAvailable([]), []);

  const onLoad = useCallback(
    (elementName) =>
      setChildrenAvailable((currentlyLoaded) => [...currentlyLoaded, elementName]),
    []
  );

  return { onLoad, isSynced, resetAvailableChildren };
}
