import { resultsApi } from "src/services/api";
import store from "src/store";
import { updateTravelPackage } from "src/store/actions";
import { resetCartLoading, setCartItemLoading } from "src/store/cartOverview";
import {
  customLog,
  doMoneyMultiplication,
  doPriceAddition,
  toTitleCase,
} from "src/utils/utils";

export function getFlightDataUpdate(
  flightWsRef,
  searchQuery,
  mergeKey,
  dataProvider,
  onSuccess,
  onError
) {
  customLog(searchQuery);
  store.dispatch(setCartItemLoading({ keys: ["adultFare", "childFare", "infantFare"] }));
  resultsApi
    .getSpecificFlight(searchQuery, mergeKey, dataProvider)
    .then((res) => {
      const wsPath = res.data;
      customLog(wsPath);
      flightWsRef.current = new WebSocket(
        `wss://${window.SERVER_DATA.REACT_APP_API_URL}/search/websocket/${wsPath}`
      );

      flightWsRef.current.onopen = () => customLog("websocket open");
      flightWsRef.current.onclose = () => customLog("WebSocket connection closed");
      flightWsRef.current.onerror = (error) => customLog(`WebSocket error: ${error}`);
      flightWsRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.data?.flights?.[0]) {
          const updatedFlight = message.data.flights[0];
          updatedFlight.data_provider = message.data_provider;
          updatedFlight.segments.forEach((segment) => {
            segment.arr.city.title = toTitleCase(segment.arr.city.title);
            segment.dep.city.title = toTitleCase(segment.dep.city.title);
          });

          customLog(updatedFlight);
          if (flightWsRef.current) {
            flightWsRef.current.close();
            flightWsRef.current = null;
            store.dispatch(updateTravelPackage(updatedFlight));
            store.dispatch(resetCartLoading());
            if (onSuccess) onSuccess(updatedFlight, searchQuery);
          }
        }
      };
    })
    .catch((err) => {
      customLog(err);
      if (onError) onError();
    });
}

export function onFlightPriceUpdate(wsRef, query, mergeKey, dataProvider, cb, errCb) {
  // HACK: temporary solutions until fix is ready on server-side
  console.log(query);
  const flightData = store.getState().booking.travelPackage;
  const params = new URLSearchParams(query);

  const new_adult = params.get("p_adult") || 1;
  const new_child = params.get("p_child") || 0;
  const new_infant = params.get("p_infant") || 0;

  const referenceFares = flightData.originalPrice.EUR.passengers_amounts_details;
  const currentFares = [...flightData.price.EUR.passengers_amounts_details];

  const old_adult = currentFares.filter((p) => p.type === "adt");
  const old_child = currentFares.filter((p) => p.type === "chd");
  const old_infant = currentFares.filter((p) => p.type === "inf");

  const adtFare = old_adult[0] || referenceFares.find((f) => f.type === "adt");
  const chdFare = old_child[0] || referenceFares.find((f) => f.type === "chd");
  const infFare = old_infant[0] || referenceFares.find((f) => f.type === "inf");

  const adultDelta = new_adult - old_adult.length;
  const adultAction = adultDelta > 0 ? "add" : adultDelta < 0 ? "remove" : null;
  if (adultAction) {
    for (let i = 0; i < Math.abs(adultDelta); i++) {
      if (adultAction === "add") {
        currentFares.push({ ...adtFare });
      } else if (adultAction === "remove") {
        const lastAdultFare = currentFares
          .slice()
          .reverse()
          .find((f) => f.type === "adt");
        if (lastAdultFare) {
          const idx = currentFares.indexOf(lastAdultFare);
          currentFares.splice(idx, 1);
        }
      }
    }
  }

  const childDelta = new_child - old_child.length;
  const childAction = childDelta > 0 ? "add" : childDelta < 0 ? "remove" : null;
  if (childAction) {
    for (let i = 0; i < Math.abs(childDelta); i++) {
      if (childAction === "add") {
        currentFares.push(chdFare ? { ...chdFare } : { ...adtFare, type: "chd" });
      } else if (childAction === "remove") {
        const lastChildFare = currentFares
          .slice()
          .reverse()
          .find((f) => f.type === "chd");
        if (lastChildFare) {
          const idx = currentFares.indexOf(lastChildFare);
          currentFares.splice(idx, 1);
        }
      }
    }
  }

  const infantDelta = new_infant - old_infant.length;
  const infantAction = infantDelta > 0 ? "add" : infantDelta < 0 ? "remove" : null;
  if (infantAction) {
    for (let i = 0; i < Math.abs(infantDelta); i++) {
      if (infantAction === "add") {
        currentFares.push(
          infFare
            ? { ...infFare }
            : { type: "inf", amount: 25, fee: 10, tax: 0, baggage_price: 0, tariff: 15 }
        );
      } else if (infantAction === "remove") {
        const lastInfantFare = currentFares
          .slice()
          .reverse()
          .find((f) => f.type === "inf");
        if (lastInfantFare) {
          const idx = currentFares.indexOf(lastInfantFare);
          currentFares.splice(idx, 1);
        }
      }
    }
  }

  const allFaresTotal = currentFares.reduce((a, b) => doPriceAddition(a, b.amount), 0);
  const avgPrice = doMoneyMultiplication(allFaresTotal, 1 / currentFares.length);
  const updatedFlight = {
    ...flightData,
    price: {
      EUR: {
        ...flightData.price.EUR,
        passengers_amounts_details: currentFares,
        total_price_per_person: avgPrice,
        price_per_person: avgPrice,
      },
    },
  };

  store.dispatch(updateTravelPackage(updatedFlight));
  store.dispatch(resetCartLoading());
  if (cb) cb(updatedFlight, query);

  // getFlightDataUpdate(
  //   wsRef,
  //   query,
  //   mergeKey,
  //   dataProvider,
  //   (updFl, updSQ) => cb(updFl, updSQ),
  //   errCb
  // );
}
