import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import TopNavigation from "src/components/shared/navigation/TopNavigation/TopNavigation";

function WithNavigationLayout() {
  return (
    <Fragment>
      <TopNavigation />
      <Outlet />
    </Fragment>
  );
}

export default WithNavigationLayout;
