import { UserAvatar } from "src/components/features/dashboard/UserAvatar/UserAvatar";

export function FavoritedIcon() {
  return (
    <svg
      style={{
        backgroundColor: "var(--ui-3)",
        borderRadius: "2px",
        outline: "2px var(--ui-3) solid",
      }}
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={19}
      viewBox="0 0 24 24"
      className="icon icon-tabler icons-tabler-filled icon-tabler-heart">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        fill="var(--primary-2)"
        opacity={0.7}
        d="M6.979 3.074a6 6 0 0 1 4.988 1.425l.037 .033l.034 -.03a6 6 0 0 1 4.733 -1.44l.246 .036a6 6 0 0 1 3.364 10.008l-.18 .185l-.048 .041l-7.45 7.379a1 1 0 0 1 -1.313 .082l-.094 -.082l-7.493 -7.422a6 6 0 0 1 3.176 -10.215z"
      />
    </svg>
  );
}
export function BurgerMenuIcon({ imageClass }) {
  return (
    <svg
      className={imageClass}
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M4 6l16 0" />
      <path d="M4 12l16 0" />
      <path d="M4 18l16 0" />
    </svg>
  );
}
export function UserAnonymousIcon({ imageClass }) {
  return (
    <svg
      className={imageClass}
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
      <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
      <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
    </svg>
  );
}
export function UserURLPhoto({ imageClass, photoUrl }) {
  return (
    <img
      className={imageClass}
      style={{
        borderRadius: "100%",
        maxHeight: 19,
        minHeight: 19,
        minWidth: 19,
        backgroundColor: "var(--ui-4)",
      }}
      alt="user"
      src={photoUrl}
    />
  );
}
export function UserAvatarIcon({ isMobile }) {
  if (isMobile) {
    return <UserAvatar />;
  }
  return (
    <>
      <UserAvatar />
      <span style={{ marginRight: "0.3rem" }}></span>
    </>
  );
}
