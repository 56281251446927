import { Fragment, useContext } from "react";
import classes from "./SavedSearchItemGroup.module.css";
import { SavedSearchItem } from "./SavedSearchItem";
import { GroupHeading } from "../AirportSelection/AirportSelection";
import { useDispatch } from "react-redux";
import { searchDataActions } from "src/store/search";
import { ModalElementContext } from "src/context/modal-element-context";
import { isTodayOrLater } from "src/utils/date-utils";
import { customLog } from "src/utils/utils";
import { useLocaleContext } from "src/context/locale-context";

const MAX_SAVED_SEARCHES = 3;

export function SavedSearchItemGroup({ items, title }) {
  const { onClose, targetTripId, isResultsModal, dispatchLocalState } =
    useContext(ModalElementContext);
  const dispatch = useDispatch();
  const { currentLocale } = useLocaleContext();

  const onSelectHandler = (item) => {
    const lang = currentLocale.split("_")[0].toUpperCase();
    const payload = {
      tripId: targetTripId,
      lang: lang,
      tripData: {
        srcLocation: item.srcLocation,
        destLocation: item.destLocation,
        startDate: isTodayOrLater(item.startDate) ? item.startDate : "",
        endDate: isTodayOrLater(item.endDate) ? item.endDate : "",
      },
    };
    customLog(payload);
    if (isResultsModal) {
      dispatchLocalState({ type: "UPDATE_PROPERTY", payload: { tripType: item.tripType } });
      dispatchLocalState({
        type: "UPDATE_TRIP",
        id: targetTripId,
        payload: payload.tripData,
      });
      dispatchLocalState({ type: "UPDATE_MULTI_START_DATES", id: targetTripId });
      dispatch(
        searchDataActions.updateUserInputTrip({
          tripId: targetTripId,
          input: {
            id: targetTripId,
            srcLocation: payload.tripData.srcLocation.city,
            srcIata: payload.tripData.srcLocation.iata,
            destLocation: payload.tripData.destLocation.city,
            destIata: payload.tripData.destLocation.iata,
          },
        })
      );
    } else {
      dispatch(searchDataActions.updateTripType(item.tripType));
      dispatch(searchDataActions.updateTripFromSavedSearch(payload));
      dispatch(searchDataActions.updateMultiTripStartDates({ tripId: targetTripId }));
    }
    onClose();
  };

  return (
    <Fragment>
      <GroupHeading title={title} />
      <ul className={`${classes.savedSearchesList} ${classes.container}`}>
        {items
          .slice(-MAX_SAVED_SEARCHES)
          .reverse()
          .map((item, i) => (
            <SavedSearchItem key={i} item={item} onSelect={() => onSelectHandler(item)} />
          ))}
      </ul>
    </Fragment>
  );
}
