import classes from "./LoadingScreen.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";

const portalElement = document.getElementById("overlays");

export function LoadingScreen({ opacity = 0.5, exitDuration, scheduleClose }) {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    document.body.classList.add(classes.body);
    document.getElementsByTagName("html")[0].style.scrollbarWidth = "none";
    return () => {
      document.body.classList.remove(classes.body);
      document.getElementsByTagName("html")[0].style.scrollbarWidth = "unset";
    };
  }, []);

  useEffect(() => {
    setIsActive(!scheduleClose);
  }, [scheduleClose]);

  return (
    <Fragment>
      {createPortal(
        <AnimatePresence>
          {isActive && (
            <motion.div
              style={{ backgroundColor: `rgba(90, 104, 114, ${opacity})` }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 1 } }}
              exit={{ opacity: 0, transition: { duration: exitDuration } }}
              className={classes.container}>
              <div className={classes.content}>
                {!scheduleClose && <DotPulseLoader opacity={1} color={"var(--ui-1)"} />}
              </div>
            </motion.div>
          )}
        </AnimatePresence>,
        portalElement
      )}
    </Fragment>
  );
}
