import { BOOKING } from "src/constants/booking";
import { customLog } from "../utils";
import { CATALOGUE_IDS } from "src/constants/services";
import { getCartOverview } from "src/store/cartOverview";
import { selectCatalogueItem } from "src/store/catalogue";

function checkInnerData(data, name) {
  if (!data.options) return;
  const values = Object.values(data.options);
  return values.find((o) => o.name === name || o.name?.toUpperCase() === name);
}

export function getCatalogueListenerOpts(send) {
  return {
    type: "catalogue/calculateCatalogue",
    effect: (action, listenerApi) => {
      customLog(action);

      const shoppingCart = getCartOverview(listenerApi.getState());
      const activeCartItems = [];
      const airlinesAffected = new Set();
      shoppingCart.items
        .filter((ci) => ci.isActiveOrderItem)
        .forEach((ci) => {
          activeCartItems.push(ci.serviceId);
          if (Object.values(CATALOGUE_IDS.seats).includes(ci.serviceId)) {
            airlinesAffected.add(ci.airline);
          }
        });

      const addServicePrices = {};
      const seatPrices = { old: {}, new: {} };
      const serviceData = listenerApi.getState().catalogue.serviceData;
      const catalogue = listenerApi.getState().catalogue.catalogue;
      const oldCatalogue = listenerApi.getOriginalState().catalogue.catalogue;

      oldCatalogue
        .filter((service) => activeCartItems.includes(service.id))
        .forEach((service) => {
          if (service.isSeatsService) {
            const oldPrices = {};
            airlinesAffected.forEach((airline) => {
              oldPrices[airline] = service.data[airline].sum_total;
            });
            seatPrices.old[service.id] = oldPrices;
          } else if (service.isAdditionalService) {
            addServicePrices[service.id] = [service.sum_total];
          }
        });

      catalogue
        .filter((service) => activeCartItems.includes(service.id))
        .forEach((service) => {
          if (service.isSeatsService) {
            const newPrices = {};
            airlinesAffected.forEach((airline) => {
              newPrices[airline] = service.data[airline].sum_total;
            });
            seatPrices.new[service.id] = newPrices;
          } else if (service.isAdditionalService) {
            addServicePrices[service.id].push(service.sum_total);
          }
        });

      const order = listenerApi.getState().order;
      const passengers = listenerApi.getState().booking.passengers;
      const bookingActionsQueue = [];
      const changedAddServices = Object.keys(addServicePrices).filter(
        (service) => addServicePrices[service][0] !== addServicePrices[service][1]
      );
      changedAddServices.forEach((serviceId) => {
        // send additional service price change command
        const catItem = selectCatalogueItem(catalogue, serviceId);
        const origData = serviceData.find(
          (d) => d.name === catItem.data.name || checkInnerData(d, catItem.data.name)
        );
        const payload = { ...catItem.data, id: origData.id };
        if (serviceId === CATALOGUE_IDS.BAG_PROTECTION) {
          const paxIds = order.baggage
            .filter((b) => b.protection.serviceId === serviceId)
            .map((b) => b.passengerId);
          payload.passengers = passengers
            .filter((p) => paxIds.includes(p.id))
            .map((p) => ({
              passenger_id: p.passenger_id,
              passenger_client_id: p.id,
            }));
        }
        bookingActionsQueue.push({ command: BOOKING.UPDATE_SERVICE_PRICE, payload });
      });

      console.log(seatPrices);
      const changedSeats = [];
      airlinesAffected.forEach((airline) => {
        Object.keys(seatPrices.new).forEach((seatType) => {
          if (seatPrices.old[seatType][airline] !== seatPrices.new[seatType][airline]) {
            const segments = order.seatType
              .filter((st) => st.serviceId === seatType && st.airline === airline)
              .map((st) => st.segment);
            if (segments.length > 0) changedSeats.push({ seatType, airline, segments });
          }
        });
      });

      changedSeats.forEach(({ seatType, airline, segments }) => {
        const catItem = selectCatalogueItem(catalogue, seatType);
        const seatId = Object.keys(CATALOGUE_IDS.seats).find(
          (k) => CATALOGUE_IDS.seats[k] === seatType
        );
        // send seat price change command
        bookingActionsQueue.push({
          command: BOOKING.UPDATE_SERVICE_PRICE,
          payload: {
            type: "seats",
            id: seatId,
            data: catItem.data[airline],
            airline,
            segments,
          },
        });
      });
      bookingActionsQueue.forEach((nextAction) => send(nextAction));
    },
  };
}
