import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  useNavigate,
  useNavigation,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { ERROR_MESSAGES, ERROR_STATUS_MESSAGE } from "src/assets/lang/errorMessages";
import DesktopBookingHeader from "src/components/features/booking/DesktopBookingHeader/DesktopBookingHeader";
import MobileBookingHeader from "src/components/features/booking/MobileBookingHeader/MobileBookingHeader";
import TravelSummary from "src/components/features/booking/TravelSummary/TravelSummary";
import { BOOKING_STEP } from "src/constants";
import { BOOKING } from "src/constants/booking";
import { CATALOGUE_IDS } from "src/constants/services";
import { useAuth } from "src/context/auth-context";
import { useBookingFlow } from "src/context/booking-flow-context";
import { useDeviceContext } from "src/context/device-context";
import { useModalContext } from "src/context/modal-stack-context";
import { MODAL_TYPE } from "src/constants/modal-definitions";
import { PriceExpiryContext } from "src/context/refresh-price-context";
import usePrevious from "src/hooks/usePrevious";
import { addPassenger } from "src/store/actions";
import { bookingActions, selectTravelPackage } from "src/store/booking";
import { orderActions } from "src/store/order";
import {
  getPassengersFromFaresList,
  listenToPassengersUpdates,
  setUpBookingListeners,
} from "src/utils/booking-utils";
import { buildSearchModelFromTravelPackage, buildSearchQuery } from "src/utils/query-utils";
import {
  getSelectedLocale,
  markSessionIsReloading,
  markSessionReloaded,
  markSessionReuseBlocked,
  markSessionReuseUnblocked,
} from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import { ErrorComponent } from "./ErrorBoundary";
import { onFlightPriceUpdate } from "./loaders/utils/booking-refresh-price-utils";
import LoadingBookingContent from "src/components/features/booking/LoadingBookingContent/LoadingBookingContent";
import { AnimatePresence } from "framer-motion";
import {
  addBookingListener,
  clearBookingListeners,
  unsubBookingListeners,
} from "./loaders/BookingPageLoader";

function getTimerExpirySeconds() {
  return Date.now() / 1000 + 30 * 60;
}

export const rebuildSearchQuery = (travelPackage, passengerData) => {
  let rebuiltSearchQ, searchModel;
  searchModel = buildSearchModelFromTravelPackage(travelPackage, passengerData);
  rebuiltSearchQ = buildSearchQuery(searchModel);
  rebuiltSearchQ = rebuiltSearchQ
    .split("&")
    .filter((p) => !p.startsWith("d_ct") || !p.startsWith("s_ct"))
    .join("&");
  return rebuiltSearchQ;
};

function BookingLayout({ send }) {
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();
  const { user, loaded } = useAuth();
  const navigate = useNavigate();
  const loaderData = useRouteLoaderData("bookingRouteController");
  const { setBookingPriceTimer, clearBookingTimer } = useContext(PriceExpiryContext);
  const { setSearchQuery, markAsLoaded, getPageId } = useBookingFlow();
  const { openModal, closeModal } = useModalContext();
  const [connectionError, setConnectionError] = useState(null);
  const flightWsRef = useRef(null);
  const navigation = useNavigation();

  const { bookingStep } = useParams();
  const prevStep = usePrevious(bookingStep);
  const paxState = useSelector((store) => store.booking.passengerState);
  const travelPackage = useSelector(selectTravelPackage);

  useEffect(() => {
    send({
      command: BOOKING.SET_DEVICE_TYPE,
      payload: { device: isMobile ? "mobile" : "desktop" },
    });
  }, [isMobile, send]);

  useEffect(() => {
    if (prevStep === bookingStep) return;
    if (
      prevStep === BOOKING_STEP.passengerDetailsForm.path &&
      (!paxState.commitedPaxs || paxState.paxsChangedSinceCommit)
    ) {
      send({ command: BOOKING.ALL_PASSENGERS_COMPLETE });
    }

    const pageId = getPageId(bookingStep);
    if (pageId) {
      send({ command: BOOKING.UPDATE_CURRENT_STEP, payload: { step: pageId } });
    }
  }, [dispatch, send, getPageId, bookingStep, prevStep, paxState]);

  const onRefreshError = useCallback(() => {
    closeModal(MODAL_TYPE.refreshPrice);
    clearBookingTimer();
    const lang = getSelectedLocale()?.split("_")[0] || "en";
    setConnectionError({
      errorMessage: ERROR_MESSAGES[lang].booking,
      statusText: ERROR_STATUS_MESSAGE[lang].booking,
    });
    markSessionReuseUnblocked();
  }, [clearBookingTimer, closeModal]);

  const schedulePriceUpdateTimer = useCallback(
    (searchQuery, mergeKey, dataProvider, onSuccess) => {
      closeModal(MODAL_TYPE.refreshPrice);
      setBookingPriceTimer(getTimerExpirySeconds(), () => {
        if (!(flightWsRef.current && flightWsRef.current.readyState === WebSocket.OPEN)) {
          openModal(MODAL_TYPE.refreshPrice, {
            onRefresh: () =>
              onFlightPriceUpdate(
                flightWsRef,
                searchQuery,
                mergeKey,
                dataProvider,
                onSuccess,
                onRefreshError
              ),
          });
        }
      });
    },
    [closeModal, onRefreshError, openModal, setBookingPriceTimer]
  );

  const onSuccessfulPriceUpdate = useCallback(
    (updatedFlight, updatedSearchQuery, replaceQuery = false) => {
      send({
        command: BOOKING.UPDATE_TICKET_PRICES,
        payload: { price: updatedFlight.price },
      });
      schedulePriceUpdateTimer(
        updatedSearchQuery,
        updatedFlight.mergeKey,
        updatedFlight.data_provider,
        onSuccessfulPriceUpdate
      );
      if (replaceQuery) setSearchQuery(updatedSearchQuery);
    },
    [send, schedulePriceUpdateTimer, setSearchQuery]
  );

  useEffect(() => {
    const travelPackage = loaderData.travelPackage;
    if (loaded) {
      if (travelPackage.userId && (!user || user.uid !== travelPackage.userId)) {
        navigate("/");
      } else if (!travelPackage.userId && user) {
        send({ command: BOOKING.LINK_USER_ID, payload: { userId: user.uid } });
        dispatch(bookingActions.setUserId(user.uid));
      }
    }
  }, [dispatch, navigate, user, send, loaderData.travelPackage, loaded]);

  function startBookingDataListeners() {
    const listenersCleanup = setUpBookingListeners(dispatch, send);
    addBookingListener(listenersCleanup);
  }

  /** This will listen to passengers updates:
   *  price updates & cart updates are triggered by passengers num/age groups */
  function startPassengerListener() {
    const passengerUpdateUnsub = listenToPassengersUpdates(
      dispatch,
      (searchQ, mergeKey, dataProvider) =>
        onFlightPriceUpdate(
          flightWsRef,
          searchQ,
          mergeKey,
          dataProvider,
          (...args) => onSuccessfulPriceUpdate(...args, true),
          onRefreshError
        )
    );
    addBookingListener(passengerUpdateUnsub);
  }

  /** INIT SETUP LOGIC */
  const runInitSetup = useCallback(() => {
    /** This will allow session reuse on page reload */
    function beforeUnloadHandler(event) {
      customLog("onbeforeunload + session reuse UNBLOCK + mark RELOADING");
      markSessionReuseUnblocked();
      markSessionIsReloading();
      event.returnValue = "";
    }
    window.addEventListener("beforeunload", beforeUnloadHandler);
    /** This will prevent session duplication in new tabs */
    markSessionReuseBlocked();
    markSessionReloaded();

    startBookingDataListeners();
    /** This will init passengers in Redux when booking is new/not restored from server */
    let passengerData = loaderData.passengerLoadState;
    if (!loaderData.isBookingUpdate) {
      const passengerData = getPassengersFromFaresList(travelPackage);
      passengerData.forEach((p) => dispatch(addPassenger(p.ageGroup, p.id)));
      dispatch(orderActions.changeBookingSMSService(CATALOGUE_IDS.SMS_TICKET));
    }

    /** Needed for breadcrumb nav link back to search results */
    let sq = loaderData.searchQ || rebuildSearchQuery(travelPackage, passengerData);
    setSearchQuery(sq);

    schedulePriceUpdateTimer(
      sq,
      travelPackage.mergeKey,
      travelPackage.data_provider,
      onSuccessfulPriceUpdate
    );

    startPassengerListener();
    markAsLoaded();

    function bookingCleanupFunc() {
      flightWsRef.current = null;
      clearBookingTimer();
      unsubBookingListeners();
      clearBookingListeners();
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }

    return bookingCleanupFunc;
  }, [loaderData]);
  /** INIT SETUP LOGIC END */

  useEffect(runInitSetup, [runInitSetup]);

  if (connectionError) {
    return (
      <ErrorComponent
        errorHeader={connectionError.statusText}
        errorMessages={[connectionError.errorMessage]}
      />
    );
  }

  return (
    <>
      {navigation.state === "loading" ? (
        <AnimatePresence>
          <LoadingBookingContent />
        </AnimatePresence>
      ) : (
        <main id="booking-page" className={isMobile ? "mobile" : "desktop"}>
          {!isMobile ? (
            <Fragment>
              <DesktopBookingHeader />
              <TravelSummary />
            </Fragment>
          ) : (
            <MobileBookingHeader />
          )}
          <section className="booking-content">
            <Outlet context={{ send }} />
          </section>
        </main>
      )}
    </>
  );
}

export default BookingLayout;
