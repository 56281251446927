import { customLog } from "src/utils/utils";
import classes from "./RadioButton.module.css";
import { useDeviceContext } from "src/context/device-context";

function RadioButton({ id, name, children, onChange, checked, className = "", opts }) {
  const { isMobile } = useDeviceContext();

  const onChangeHandler = (e) => {
    const isChecked = e.target.checked;
    customLog(isChecked);
    onChange(isChecked);
  };

  const onSelectElement = (e) => {
    customLog("radio selected");
    onChange(true);
  };

  const onKeyUpHandler = (e) => {
    customLog("radio key action");
    if (e.key === "Enter") {
      onChange(true);
    }
  };

  const classname = [
    classes.container,
    classes.radio,
    !isMobile ? classes.desktop : "",
    className ? className : "",
  ]
    .join(" ")
    .trimEnd();

  return (
    <div style={opts} className={classname} onClick={onSelectElement}>
      <label htmlFor={id} hidden />
      <input
        id={id}
        type="radio"
        name={name}
        value={id}
        checked={checked}
        className={classes.container}
        onChange={onChangeHandler}
        onKeyUp={onKeyUpHandler}
      />
      {children}
    </div>
  );
}

export default RadioButton;
