import { redirect } from "react-router-dom";

export async function loader({ request }) {
  const url = new URL(request.url);
  const pathname = url.pathname.endsWith("/") ? url.pathname.slice(0, -1) : url.pathname;
  if (pathname === "/dashboard") {
    return redirect("/dashboard/trips");
  }
  return null;
}
