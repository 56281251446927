import { json } from "react-router-dom";
import { ERROR_MESSAGES, ERROR_STATUS_MESSAGE } from "src/assets/lang/errorMessages";
import { bookingOrderApi } from "src/services/api";
import {
  getSessionReloadTimestamp,
  isSessionReuseBlocked,
  setSessionId,
  getSessionId,
  getSelectedLocale,
} from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";

export function processSessionId() {
  let correlationID;
  const lockTimeThreshold = 5 * 60 * 1000; // 5 minutes max
  const sessionReloadTime = getSessionReloadTimestamp();

  if (
    isSessionReuseBlocked() ||
    (sessionReloadTime && sessionReloadTime < Date.now() - lockTimeThreshold)
  ) {
    setSessionId("");
    correlationID = "";
    console.warn("Found a reuse block in session storage. The correlation ID was cleared.");
  } else {
    correlationID = getSessionId();
    console.warn("Not locked. Correlation ID: " + correlationID);
  }
  return correlationID;
}

export async function getBookingIDKey(travelPackage) {
  // return new Promise((resolve) => resolve(nanoid()));
  return bookingOrderApi
    .startBooking(travelPackage)
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((err) => {
      customLog("Booking correlation ID request error occured: " + err);
      let lang = getSelectedLocale()?.split("_")[0] || "en";
      throw json({
        data: err,
        errorMessage: ERROR_MESSAGES[lang].booking,
        statusText: ERROR_STATUS_MESSAGE[lang].booking,
      });
    });
}
