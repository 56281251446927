import React from "react";
import { Provider } from "react-redux";
import store from "src/store";
import { RouterProvider } from "react-router-dom";

import router from "./Routes";

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
