export const CATALOGUE_IDS = {
  pi_baggage: "piBaggage",
  cob_baggage: "coBaggageBundle",
  cob_baggage_upgrade: "coBaggageBundleUpgrade",
  r_baggage_def: "rBaggageSmall",
  r_baggage_larger: "rBaggageLarge",
  r_baggage_double: "rBaggageDoubleLarge",

  TRAVEL_INSURANCE: {
    TRAVEL_BASIC: "travelInsuranceBasic",
    TRAVEL_PLUS: "travelInsurancePlus",
  },
  SUPPORT: {
    basic: "supportServiceBasic",
    standart: "supportServicePlus",
    premium: "supportServicePremium",
  },

  seats: {
    random: "seatTypeRandom",
    window: "seatTypeWindow",
    aisle: "seatTypeAisle",
    extra_legroom: "seatTypeExtraLegroom",
    sitting_together: "seatTypeTogether",
  },

  BAG_PROTECTION: "checkedBaggageProtection",
  SMS_TICKET: "bookingSMSService",
  CONNECTION_PROTECTION_SERVICE: "connectionProtection",
  "ONLINE_CHECK-IN": "onlineCheckinService",
  MBG_SERVICE: "changeProtection",
  AIRHELP_PLUS: "airHelpService",
  SMS_MOBILE_ROUTE: "flightsSMSService",
};

// const SERVICE_NAME_KEY = {
//   TRIP_CHANGE: "",
//   NO_TRIP_CHANGE: "",
//   SMS_CANCELATION_RESCHEDULING: "",
//   SMART_TICKET: "",
//   HIGHPASS: "",
//   FASTTRACK: "",
// };

export const PRICE_POLICY = {
  per_person: "booking.service.price.perPassenger",
  per_order: "booking.service.price.perBooking",
  whole_flight: "booking.service.price.perDirection",
  per_segment: "booking.service.price.perSegment",
  per_airport: "booking.service.price.perAirport",
  per_day: "booking.service.price.perDay",
};

export const CATALOGUE_IDS_STR_MAP = {
  [CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC]:
    "booking.checkout.summary.insuranceBasic",
  [CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_PLUS]: "booking.checkout.summary.insurancePlus",
  [CATALOGUE_IDS.seats.random]: "booking.checkout.summary.randomSeats",
  [CATALOGUE_IDS.seats.window]: "booking.checkout.summary.windowSeats",
  [CATALOGUE_IDS.seats.aisle]: "booking.checkout.summary.aisleSeats",
  [CATALOGUE_IDS.seats.extra_legroom]: "booking.checkout.summary.randomSlegroomSeatseats",
  [CATALOGUE_IDS.seats.sitting_together]: "booking.checkout.summary.togetherSeats",
  [CATALOGUE_IDS.SUPPORT.basic]: "booking.checkout.summary.supportBasic",
  [CATALOGUE_IDS.SUPPORT.standart]: "booking.checkout.summary.supportPlus",
  [CATALOGUE_IDS.SUPPORT.premium]: "booking.checkout.summary.supportPremium",
};

export const SERVICE_TYPE = {
  REFUSED: "user_refused_service",
  NOT_SELECTED: "",
};

export const ORDER_SUMMARY_GROUP = {
  personalDetails: {
    key: "personalDetails",
    strKey: "booking.checkout.summary.category.personal",
  },
  onBoardService: {
    key: "onBoardService",
    strKey: "booking.checkout.summary.category.onboard",
  },
  travelInsurance: {
    key: "travelInsurance",
    strKey: "booking.checkout.summary.category.insurance",
  },
  servicePackage: {
    key: "servicePackage",
    strKey: "booking.checkout.summary.category.services",
  },
  extrasServices: {
    key: "extrasServices",
    strKey: "booking.checkout.summary.category.extras",
  },
};

export const FULL_CATALOGUE = [
  {
    id: CATALOGUE_IDS.pi_baggage,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.cob_baggage,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.cob_baggage_upgrade,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.r_baggage_def,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.r_baggage_larger,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.r_baggage_double,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.BAG_PROTECTION,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.SMS_MOBILE_ROUTE,
    category: ORDER_SUMMARY_GROUP.extras,
  },
  {
    id: CATALOGUE_IDS.CONNECTION_PROTECTION_SERVICE,
    category: ORDER_SUMMARY_GROUP.extras,
  },
  {
    id: CATALOGUE_IDS["ONLINE_CHECK-IN"],
    category: ORDER_SUMMARY_GROUP.extras,
  },
  {
    id: CATALOGUE_IDS.MBG_SERVICE,
    category: ORDER_SUMMARY_GROUP.extras,
  },
  {
    id: CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC,
    category: ORDER_SUMMARY_GROUP.travelInsurance,
  },
  {
    id: CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_PLUS,
    category: ORDER_SUMMARY_GROUP.travelInsurance,
  },
  {
    id: CATALOGUE_IDS.seats.random,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.seats.window,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.seats.aisle,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.seats.extra_legroom,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.seats.sitting_together,
    category: ORDER_SUMMARY_GROUP.onBoardService,
  },
  {
    id: CATALOGUE_IDS.SUPPORT.basic,
    category: ORDER_SUMMARY_GROUP.servicePackage,
  },
  {
    id: CATALOGUE_IDS.SUPPORT.standart,
    category: ORDER_SUMMARY_GROUP.servicePackage,
  },
  {
    id: CATALOGUE_IDS.SUPPORT.premium,
    category: ORDER_SUMMARY_GROUP.servicePackage,
  },
  {
    id: CATALOGUE_IDS.AIRHELP_PLUS,
    category: ORDER_SUMMARY_GROUP.extras,
  },
  {
    id: CATALOGUE_IDS.SMS_TICKET,
    category: ORDER_SUMMARY_GROUP.extras,
  },
];
