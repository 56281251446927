import { Fragment } from "react";
import { useDeviceContext } from "src/context/device-context";

export default function PaddedView({ children }) {
  const { isMobile } = useDeviceContext();
  if (isMobile) {
    return <Fragment>{children}</Fragment>;
  } else {
    return <div className="padded-view">{children}</div>;
  }
}
