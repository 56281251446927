import { json, redirect } from "react-router-dom";
import { ERROR_MESSAGES, ERROR_STATUS_MESSAGE } from "src/assets/lang/errorMessages";
import { FULL_CATALOGUE } from "src/constants/services";
import store from "src/store";
import { updateTravelPackage } from "src/store/actions";
import { catalogueActions } from "src/store/catalogue";
import { getSelectedLocale } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import {
  processBaggageServices,
  processRestoredAdditionalServices,
  processRestoredSeatsServices,
} from "./utils/booking-catalogue-utils";
import { RestoreUtil } from "./utils/booking-restore-utils";
import { isLangAbsent, redirectWithLang } from "./utils/loader-utils";

export async function loader({ request }) {
  customLog(request);

  if (isLangAbsent(request)) return redirectWithLang(request);

  const url = new URL(request.url);
  const searchParams = url.searchParams;
  // const token = searchParams.get("token");
  const correlationID = searchParams.get("booking");
  if (!correlationID) return redirect("/");

  let travelPackage;
  let existingBookingUpdates = [];
  const { booking, updates, redirectData } = await RestoreUtil.getExistingBooking(
    correlationID
  );
  if (redirectData) return redirect(redirectData.path);

  travelPackage = booking;
  existingBookingUpdates = updates || [];

  if (!travelPackage || Object.keys(travelPackage).length === 0) {
    let lang = getSelectedLocale()?.split("_")[0] || "en";
    throw json({
      data: null,
      errorMessage: ERROR_MESSAGES[lang].booking,
      statusText: [ERROR_STATUS_MESSAGE[lang].booking],
    });
  }

  let isBookingUpdate = existingBookingUpdates.length > 0;
  if (!isBookingUpdate) {
    return redirect("/");
  }

  const restoredData = RestoreUtil.restoreBookingData(existingBookingUpdates);
  console.log(restoredData);

  const fullCatalogue = [...FULL_CATALOGUE];

  processRestoredSeatsServices(
    restoredData.order.ancillary.seats,
    travelPackage,
    fullCatalogue
  );
  processRestoredAdditionalServices(restoredData.order.additional, fullCatalogue);
  processBaggageServices(travelPackage, fullCatalogue);

  store.dispatch(catalogueActions.loadCatalogue(fullCatalogue));
  store.dispatch(updateTravelPackage(travelPackage));

  RestoreUtil.updateStoreWithContact(restoredData.contact);
  RestoreUtil.updateStoreWithBilling(restoredData.billing);
  RestoreUtil.updateStoreWithPassengers(restoredData.passengers);
  RestoreUtil.updateStoreWithAdditionalServices(restoredData.order.additional);
  RestoreUtil.updateStoreWithAncillaryServices(restoredData.order.ancillary, travelPackage);
  RestoreUtil.updateStoreWithExplicitlyRefusedServices(existingBookingUpdates);

  return { correlationID, travelPackage };
}
