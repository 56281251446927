import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CABIN_CLASS, CABIN_CLASS_MAP } from "src/constants";
import { searchDataActions } from "src/store/search";
import { CabinClassItem } from "./CabinClassItem";
import classes from "./CabinClassItemGroup.module.css";
import { customLog } from "src/utils/utils";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";

export function CabinClassItemGroup() {
  const dispatch = useDispatch();
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const cabinClassValue = useSelector((state) => state.search.userInput.cabinClass);

  const onItemSelectedHandler = (value) => {
    customLog("rerender on onItemSelectedHandler CabinClassItemGroup DISPATCH");
    if (isMobile) {
      dispatch(searchDataActions.updateUserInputCabinClass(value));
    } else {
      dispatch(searchDataActions.updateCabinClass(value));
      dispatch(searchDataActions.fillUserInputPassengerOptions());
    }
  };

  useEffect(() => {
    if (!cabinClassValue) {
      dispatch(searchDataActions.updateCabinClass(CABIN_CLASS.economy));
    }
  });

  return (
    <ul className={classes.container}>
      {Object.values(CABIN_CLASS).map((key) => (
        <CabinClassItem
          key={key}
          id={key}
          label={stringRes[CABIN_CLASS_MAP[key]]}
          checked={key === cabinClassValue}
          onSelect={onItemSelectedHandler}
        />
      ))}
    </ul>
  );
}
