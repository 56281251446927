import { useAuth } from "src/context/auth-context";
import classes from "./UserAvatar.module.css";

export function UserAvatar() {
  const { user } = useAuth();
  const firstLetter = (user?.displayName || user.email).charAt(0).toUpperCase();

  return (
    <div className={classes.avatar}>
      <span className={classes.letter}>{firstLetter}</span>
    </div>
  );
}
