import { BOOKING } from "src/constants/booking";
import { SERVICE_TYPE } from "src/constants/services";
import { selectCatalogueItem } from "src/store/catalogue";
import { customLog } from "../utils";

export function getSupportServiceListenerOpts(send) {
  return {
    type: "order/changeSupportServiceType",
    effect: (action, listenerApi) => {
      customLog(action);

      const _new = listenerApi.getState().order.supportService;
      const _old = listenerApi.getOriginalState().order.supportService;

      if (_new.serviceId !== _old.serviceId) {
        const bookingActionsQueue = [];
        const catalogue = listenerApi.getState().catalogue.catalogue;
        const supportData = listenerApi
          .getState()
          .catalogue.serviceData.find((d) => d.name === "SUPPORT");

        //if old service was concrete/selected before
        if (_old.serviceId !== SERVICE_TYPE.NOT_SELECTED) {
          bookingActionsQueue.push({
            command: BOOKING.REMOVE_SERVICE_ADDITIONAL,
            payload: { id: supportData.id, backend_id: _old._id },
          });
        }

        const serviceDetails = selectCatalogueItem(catalogue, _new.serviceId);
        const bookingAction = {
          command: BOOKING.ADD_SERVICE_ADDITIONAL,
          payload: { ...serviceDetails.data, id: supportData.id, backend_id: _new._id },
        };
        bookingActionsQueue.push(bookingAction);

        bookingActionsQueue.forEach((nextAction) => send(nextAction));
      }
    },
  };
}
