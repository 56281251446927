export function RountripArrow() {
  return (
    <svg
      className="iconsax-linear-arrangevertical"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.36 6.775L1.5 8.635M1.5 8.635L3.36 10.495M1.5 8.635H10.5M8.64 5.225L10.5 3.365M10.5 3.365L8.64 1.505M10.5 3.365L1.5 3.365"
        stroke="#001B38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function OnewayArrow() {
  return (
    <svg
      className="iconsax-linear-arrangevertical"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.14 7.72L11 5.86M11 5.86L9.14 4M11 5.86L2 5.86"
        stroke="#001B38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ExpandArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
    >
      <path
        d="M1 0.999999L5 5L9 0.999999"
        stroke="#001B38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CollapseArrow() {
  return (
    <svg
      className="vector"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5L5 1L1 5"
        stroke="#001B38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SortIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.22488 3.36L3.36488 1.5M3.36488 1.5L1.50488 3.36M3.36488 1.5V10.5M6.77488 8.64L8.63488 10.5M8.63488 10.5L10.4949 8.64M8.63488 10.5V1.5"
        stroke="#001B38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DropdownArrow({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="29"
      height="34"
      viewBox="0 0 29 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14L14 20L20 14"
        stroke="#152649"
        strokeOpacity="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LongArrow() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3648_5264)">
        <path
          d="M17.043 8.6827C17.0979 8.62497 17.164 8.57901 17.2372 8.5476C17.3105 8.5162 17.3893 8.5 17.469 8.5C17.5487 8.5 17.6275 8.5162 17.7008 8.5476C17.774 8.57901 17.8401 8.62497 17.895 8.6827L20.817 11.6037C21.059 11.8477 21.059 12.2127 20.817 12.3957L17.895 15.3177C17.8413 15.377 17.7755 15.4239 17.7019 15.4554C17.6284 15.4868 17.5489 15.5019 17.469 15.4997C17.103 15.4997 16.86 15.2567 16.86 14.8907V12.8997H3.9C3.68085 12.8997 3.46923 12.8198 3.30482 12.6749C3.14041 12.53 3.03451 12.3301 3.007 12.1127L3 11.9997C3 11.761 3.09482 11.5321 3.2636 11.3633C3.43239 11.1945 3.66131 11.0997 3.9 11.0997L16.86 11.0987V9.1087C16.86 8.9627 16.899 8.8547 16.977 8.7557L17.043 8.6827Z"
          fill="#001B38"
        />
        <path
          d="M17.043 8.6827C17.0979 8.62497 17.164 8.57901 17.2372 8.5476C17.3105 8.5162 17.3893 8.5 17.469 8.5C17.5487 8.5 17.6275 8.5162 17.7008 8.5476C17.774 8.57901 17.8401 8.62497 17.895 8.6827L20.817 11.6037C21.059 11.8477 21.059 12.2127 20.817 12.3957L17.895 15.3177C17.8413 15.377 17.7755 15.4239 17.7019 15.4554C17.6284 15.4868 17.5489 15.5019 17.469 15.4997C17.103 15.4997 16.86 15.2567 16.86 14.8907V12.8997H3.9C3.68085 12.8997 3.46923 12.8198 3.30482 12.6749C3.14041 12.53 3.03451 12.3301 3.007 12.1127L3 11.9997C3 11.761 3.09482 11.5321 3.2636 11.3633C3.43239 11.1945 3.66131 11.0997 3.9 11.0997L16.86 11.0987V9.1087C16.86 8.9627 16.899 8.8547 16.977 8.7557L17.043 8.6827Z"
          fill="#001B38"
        />
      </g>
      <defs>
        <clipPath id="clip0_3648_5264">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function RightArrowLogin({ color = "#001B38" }) {
  return (
    <svg
      className="vector"
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.43 0L17.5 6.07L11.43 12.14M0.5 6.07H17.33"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function StepDirectionArrow({ forward, back }) {
  return (
    <svg
      style={{ rotate: back ? "180deg" : "0deg" }}
      width="8"
      height="11.25"
      viewBox="12 23 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 31.5L18 27.5L14 23.5"
        stroke={forward ? "white" : "#001B38"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
