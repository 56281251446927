import React, { Fragment, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/UI/Button/Button";
import { ModalElementContext } from "src/context/modal-element-context";
import { Calendar } from "src/components/features/search/SearchForm/elements/Calendar/Calendar";
import SearchFormDate from "src/components/features/search/SearchForm/elements/SearchFormDate/SearchFormDate";
import { TRIP_TYPE } from "src/constants";
import { searchDataActions, selectTripType } from "src/store/search";
import classes from "./DateSelection.module.css";
import { secondDateIsLater } from "src/utils/date-utils";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { useDeviceContext } from "src/context/device-context";
import { WEEKDAY_SHORT_STR_MAP } from "src/constants/string-res-map";
import { useLocaleContext } from "src/context/locale-context";

function DateSelection({ isDeparture }) {
  const dispatch = useDispatch();
  const { onClose, targetTripId, isResultsModal, dispatchLocalState, resultsSFData } =
    useContext(ModalElementContext);
  const { isMobile } = useDeviceContext();

  const tripType = useSelector((state) => {
    if (isResultsModal) {
      return resultsSFData.tripType;
    } else {
      return selectTripType(state);
    }
  });
  const allTrips = useSelector((state) => {
    if (isResultsModal) {
      return resultsSFData.trips;
    } else {
      return state.search.trips;
    }
  });
  const tripNum = allTrips.findIndex((trip) => trip.id === targetTripId);

  const trip = allTrips[tripNum];
  const isRoundTrip = tripType === TRIP_TYPE.roundtrip;
  const isMultiTrip = tripType === TRIP_TYPE.multicity;

  const [startDate, setStartDate] = useState(trip.startDate);
  const [endDate, setEndDate] = useState(trip.endDate);
  const [fieldFocus, setFieldFocus] = useState(
    !startDate || !isRoundTrip ? { start: true, end: false } : { start: false, end: true }
  );
  const hasBothDatesOnOpen = !!startDate && !!endDate;
  const [isSelectingEndDate, setIsSelectingEndDate] = useState(
    tripType === TRIP_TYPE.roundtrip &&
      ((hasBothDatesOnOpen && !isDeparture) || (startDate && !endDate))
  );

  const onEndDateSelectedHandler = (date) => {
    if (startDate && secondDateIsLater(date, startDate)) {
      setStartDate(date);
    } else {
      setEndDate(date);
      focusStartField();
    }
  };

  const onStartDateSelectedHandler = (date) => {
    setStartDate(date);
    if (endDate) {
      setEndDate("");
    }
    if (isRoundTrip) {
      focusEndField();
    }
  };

  const onDesktopStartDateSelectedHandler = (date) => {
    setStartDate(date);

    if (hasBothDatesOnOpen || isRoundTrip) {
      setEndDate("");
      focusEndField();
    } else {
      const payload = { tripId: targetTripId, date };
      dispatch(searchDataActions.updateStartDate(payload));
      if (isMultiTrip) {
        dispatch(searchDataActions.updateMultiTripStartDates({ tripId: targetTripId }));
      }
      onClose();
    }
  };

  const onDesktopEndDateSelectedHandler = (date) => {
    if (startDate && secondDateIsLater(date, startDate)) {
      setStartDate(date);
    } else {
      setEndDate(date);
      const payload = { tripId: targetTripId, date: startDate };
      dispatch(searchDataActions.updateStartDate(payload));
      dispatch(searchDataActions.updateEndDate({ ...payload, date }));
      onClose();
    }
  };

  const onCloseWithoutSaving = () => {
    onClose();
  };

  const onConfirmDates = () => {
    if (isResultsModal) {
      const dispatchObj = { type: "UPDATE_TRIP", id: targetTripId };
      dispatchLocalState({ ...dispatchObj, payload: { startDate: startDate } });
      if (isRoundTrip) {
        dispatchLocalState({ ...dispatchObj, payload: { endDate: endDate } });
      } else if (isMultiTrip) {
        dispatchLocalState({ type: "UPDATE_MULTI_START_DATES", id: targetTripId });
      }
    } else {
      const payload = { tripId: targetTripId, date: startDate };
      dispatch(searchDataActions.updateStartDate({ ...payload }));
      if (isRoundTrip) {
        payload.date = endDate;
        dispatch(searchDataActions.updateEndDate({ ...payload }));
      } else if (isMultiTrip) {
        dispatch(searchDataActions.updateMultiTripStartDates({ tripId: targetTripId }));
      }
    }
    onClose();
  };

  const onClearInput = (id) => {
    setEndDate("");
    focusEndField();
  };

  const focusStartField = () => {
    if (!startDate) {
      setFieldFocus({ start: true, end: false });
    }
    setIsSelectingEndDate(false);
  };

  const focusEndField = () => {
    setFieldFocus({ start: false, end: true });
    setIsSelectingEndDate(true);
  };

  const latestMinDate =
    tripNum > 0
      ? allTrips
          .slice(0, tripNum)
          .map((t) => t.startDate)
          .reduce((latest, date) => (secondDateIsLater(latest, date) ? date : latest))
      : "";

  // console.log(allTrips.slice(0, tripNum));
  // console.log(latestMinDate);

  const props = {
    minDate: isMultiTrip && tripNum > 0 ? latestMinDate : "",
    onCloseWithoutSaving: onCloseWithoutSaving,
    tripType: tripType,
    startDate: startDate,
    endDate: endDate,
    isSelectingEndDate: isSelectingEndDate,
    onClearInput: onClearInput,
    startDateClass: fieldFocus.start ? classes.selected : "",
    endDateClass: fieldFocus.end ? classes.selected : "",
  };

  if (isMobile) {
    props.onStartDateSelectedHandler = onStartDateSelectedHandler;
    props.onEndDateSelectedHandler = onEndDateSelectedHandler;
    props.onConfirmDates = onConfirmDates;
  } else {
    props.onStartDateSelectedHandler = onDesktopStartDateSelectedHandler;
    props.onEndDateSelectedHandler = onDesktopEndDateSelectedHandler;
    props.isSpecificDateType = hasBothDatesOnOpen;
  }

  return (
    <Fragment>
      {isMobile && <MobileCalendar {...props} />}
      {!isMobile && <DesktopCalendar {...props} />}
    </Fragment>
  );
}

function DesktopCalendar({
  tripType,
  minDate,
  startDate,
  endDate,
  onEndDateSelectedHandler,
  onStartDateSelectedHandler,
  isSelectingEndDate,
  isSpecificDateType,
}) {
  // customLog([minDate, startDate, endDate, isSelectingEndDate, isSpecificDateType]);
  return (
    <Fragment>
      <Calendar
        tripType={tripType}
        minDate={minDate}
        startDate={startDate}
        endDate={endDate}
        onEndDateSelected={onEndDateSelectedHandler}
        onStartDateSelected={onStartDateSelectedHandler}
        isSelectingEndDate={isSelectingEndDate}
        isSpecificDateType={isSpecificDateType}
      />
    </Fragment>
  );
}

function MobileCalendar({
  onCloseWithoutSaving,
  tripType,
  minDate,
  startDate,
  endDate,
  onEndDateSelectedHandler,
  onStartDateSelectedHandler,
  isSelectingEndDate,
  onClearInput,
  startDateClass,
  endDateClass,
  onConfirmDates,
}) {
  const { stringRes } = useLocaleContext();
  const weekdays = WEEKDAY_SHORT_STR_MAP.map((wkd) => stringRes[wkd]);
  const isRoundTrip = tripType === TRIP_TYPE.roundtrip;

  return (
    <Fragment>
      <CloseWindowButton onClick={onCloseWithoutSaving} />
      <div className={classes.weekdays}>
        {weekdays.map((wd) => (
          <span key={wd}>{wd}</span>
        ))}
      </div>
      <Calendar
        tripType={tripType}
        minDate={minDate}
        startDate={startDate}
        endDate={endDate}
        onEndDateSelected={onEndDateSelectedHandler}
        onStartDateSelected={onStartDateSelectedHandler}
        isSelectingEndDate={isSelectingEndDate}
        isScroll
      />
      <div className={classes.drawer}>
        <div className={classes.datepicker}>
          <SearchFormDate
            id="calendar-start-date"
            className={`${classes.startDate} ${startDateClass}`}
            value={startDate}
            label={stringRes["sf.field.date.depart"]}
          />
          {isRoundTrip && (
            <SearchFormDate
              id="calendar-end-date"
              className={`${classes.endDate} ${endDateClass}`}
              value={endDate}
              label={stringRes["sf.field.date.return"]}
              onClear={onClearInput}
            />
          )}
        </div>
        <Button
          name={stringRes["sf.mob.datepicker.select"]}
          onClick={onConfirmDates}
          opts={{ height: "3.5rem" }}
          fontclass={classes.fontclass}
          cornerSm
          wide
        />
      </div>
    </Fragment>
  );
}

export default DateSelection;
