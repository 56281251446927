import { BOOKING } from "src/constants/booking";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { BAGGAGE } from "../baggage-utils";
import { customLog } from "../utils";
import { selectCatalogueItem } from "src/store/catalogue";

function checkedBaggageListenerEffect(action, listenerApi, send) {
  customLog(action);
  const bookingActionsQueue = [];

  const passengerId = action.payload.passengerId;
  const paxData = listenerApi
    .getState()
    .booking.passengers.find((p) => p.id === action.payload.passengerId);
  if (!paxData.passenger_id) return;

  const catalogue = listenerApi.getState().catalogue.catalogue;
  const _new = listenerApi.getState().order.baggage.find((b) => b.passengerId === passengerId);
  const _old = listenerApi
    .getOriginalState()
    .order.baggage.find((b) => b.passengerId === passengerId);

  if (_old.checked.serviceId !== _new.checked.serviceId && paxData) {
    if (_old.checked.serviceId !== SERVICE_TYPE.NOT_SELECTED) {
      bookingActionsQueue.push({
        command: BOOKING.REMOVE_SERVICE_ANCILLARY,
        payload: {
          type: "baggage",
          id: BAGGAGE.checked,
          passenger_id: paxData.passenger_id,
          passenger_client_id: paxData.id,
          backend_id: _old.checked._id,
        },
      });
    }

    const bagData = catalogue.find((b) => b.id === _new.checked.serviceId);
    bookingActionsQueue.push({
      command: BOOKING.ADD_SERVICE_ANCILLARY,
      payload: {
        type: "baggage",
        id: BAGGAGE.checked,
        passenger_id: paxData.passenger_id,
        passenger_client_id: paxData.id,
        data: { ...bagData.data },
        backend_id: _new.checked._id,
      },
    });
  }

  bookingActionsQueue.forEach((nextAction) => send(nextAction));
}

function cabinBaggageListenerEffect(action, listenerApi, send) {
  customLog(action);
  const bookingActionsQueue = [];

  const passengerId = action.payload.passengerId;
  const paxData = listenerApi
    .getState()
    .booking.passengers.find((p) => p.id === action.payload.passengerId);
  if (!paxData.passenger_id) return;

  const travelOfferBags = listenerApi.getState().booking.travelPackage.baggage;
  const _new = listenerApi.getState().order.baggage.find((b) => b.passengerId === passengerId);
  const _old = listenerApi
    .getOriginalState()
    .order.baggage.find((b) => b.passengerId === passengerId);

  if (_old.cabin.serviceId !== _new.cabin.serviceId && paxData) {
    if (_old.cabin.serviceId !== SERVICE_TYPE.NOT_SELECTED) {
      const oldServiceSubtype =
        _old.cabin.serviceId === CATALOGUE_IDS.pi_baggage
          ? BAGGAGE.personal
          : _old.cabin.serviceId === CATALOGUE_IDS.cob_baggage_upgrade
          ? BAGGAGE.cabinBundleUpgrade
          : BAGGAGE.cabinBundle;

      bookingActionsQueue.push({
        command: BOOKING.REMOVE_SERVICE_ANCILLARY,
        payload: {
          type: "baggage",
          id: oldServiceSubtype,
          passenger_id: paxData.passenger_id,
          passenger_client_id: paxData.id,
          backend_id: _old.cabin._id,
        },
      });
    }

    const serviceSubtype =
      _new.cabin.serviceId === CATALOGUE_IDS.pi_baggage
        ? BAGGAGE.personal
        : _new.cabin.serviceId === CATALOGUE_IDS.cob_baggage_upgrade
        ? BAGGAGE.cabinBundleUpgrade
        : BAGGAGE.cabinBundle;

    let targetData =
      serviceSubtype === BAGGAGE.personal
        ? travelOfferBags[serviceSubtype][0]
        : travelOfferBags[BAGGAGE.cabinBundle][0];

    bookingActionsQueue.push({
      command: BOOKING.ADD_SERVICE_ANCILLARY,
      payload: {
        type: "baggage",
        id: serviceSubtype,
        passenger_id: paxData.passenger_id,
        passenger_client_id: paxData.id,
        data: { ...targetData },
        backend_id: _new.cabin._id,
      },
    });
  }

  bookingActionsQueue.forEach((nextAction) => send(nextAction));
}

function confirmedPassengerListenerEffect(action, listenerApi, send) {
  customLog(action);
  const bookingActionsQueue = [];

  const passengerId = action.payload.id;
  const currentBaggage = listenerApi
    .getOriginalState()
    .order.baggage.find((b) => b.passengerId === passengerId);

  const paxData = listenerApi.getState().booking.passengers.find((p) => p.id === passengerId);
  const catalogue = listenerApi.getState().catalogue.catalogue;

  const cabinBagSubtype =
    currentBaggage.cabin.serviceId === CATALOGUE_IDS.pi_baggage
      ? BAGGAGE.personal
      : currentBaggage.cabin.serviceId === CATALOGUE_IDS.cob_baggage_upgrade
      ? BAGGAGE.cabinBundleUpgrade
      : BAGGAGE.cabinBundle;

  const cabinBag = currentBaggage.cabin.serviceId;
  const checkedBag = currentBaggage.checked.serviceId;
  const bagProtection = currentBaggage.protection.serviceId;

  if (cabinBag !== SERVICE_TYPE.NOT_SELECTED) {
    const travelOfferBags = listenerApi.getState().booking.travelPackage.baggage;
    const targetData =
      cabinBagSubtype === BAGGAGE.personal
        ? travelOfferBags[cabinBagSubtype][0]
        : travelOfferBags[BAGGAGE.cabinBundle][0];

    bookingActionsQueue.push({
      command: BOOKING.ADD_SERVICE_ANCILLARY,
      payload: {
        type: "baggage",
        id: cabinBagSubtype,
        passenger_id: paxData.passenger_id,
        passenger_client_id: paxData.id,
        data: { ...targetData },
        backend_id: currentBaggage.cabin._id,
      },
    });
  }

  if (checkedBag !== SERVICE_TYPE.NOT_SELECTED) {
    const bagData = catalogue.find((b) => b.id === checkedBag);
    bookingActionsQueue.push({
      command: BOOKING.ADD_SERVICE_ANCILLARY,
      payload: {
        type: "baggage",
        id: BAGGAGE.checked,
        passenger_id: paxData.passenger_id,
        passenger_client_id: paxData.id,
        data: { ...bagData.data },
        backend_id: currentBaggage.checked._id,
      },
    });
  }

  if (bagProtection !== SERVICE_TYPE.NOT_SELECTED) {
    const serviceDetails = selectCatalogueItem(catalogue, CATALOGUE_IDS.BAG_PROTECTION);
    bookingActionsQueue.push({
      command: BOOKING.ADD_SERVICE_ADDITIONAL,
      payload: {
        ...serviceDetails.data,
        backend_id: currentBaggage.protection._id,
        passenger_id: paxData.passenger_id,
        passenger_client_id: paxData.id,
      },
    });
  }

  bookingActionsQueue.forEach((nextAction) => send(nextAction));
}

export function getBaggageListenerOpts(send) {
  const types = [
    "order/changeCabinBaggageType",
    "order/changeCheckedBaggageType",
    "booking/setPassengerServerIdentifier",
  ];
  const effects = [
    cabinBaggageListenerEffect,
    checkedBaggageListenerEffect,
    confirmedPassengerListenerEffect,
  ];
  const baggageListenerOpts = types.map((type, i) => {
    return {
      type: type,
      effect: (action, listenerApi) => effects[i](action, listenerApi, send),
    };
  });
  return baggageListenerOpts;
}
