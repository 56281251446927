import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { STATIC_ELEMENT } from "src/constants/static";
import useStaticContent from "src/hooks/useStaticContent";

function AboutUs() {
  const { onLoad } = useOutletContext();
  const { html: template } = useStaticContent(STATIC_ELEMENT.about_us);

  useEffect(() => {
    if (!template) return;
    onLoad("content");
  }, [onLoad, template]);

  return <div dangerouslySetInnerHTML={{ __html: template }}></div>;
}

export default AboutUs;
