import classes from "./DesktopMultiSearchForm.module.css";
import SearchFormDate from "src/components/features/search/SearchForm/elements/SearchFormDate/SearchFormDate";
import { useDispatch, useSelector } from "react-redux";
import { compareToValidatedValue } from "src/utils/utils";
import { forwardRef } from "react";
import { SearchFormTextInputDesktop } from "../SingleSearchForm/SearchFormTextInputDesktop";
import { searchDataActions } from "src/store/search";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import { useLocaleContext } from "src/context/locale-context";

export const DesktopMultiSearchFormItem = forwardRef(function DesktopMultiSearchFormItem(
  { trip, tripNum, onRemove, onSwitchDirection, onSearchFormClick },
  ref
) {
  const dispatch = useDispatch();
  const { stringRes, currentLocale } = useLocaleContext();
  const lang = currentLocale.split("_")[0].toUpperCase();
  const typedUserInput = useSelector((state) =>
    state.search.userInput.trips.find((item) => item.id === trip.id)
  );

  const onChangeTextInputHandler = (id, input) => {
    if (input) {
      const userInpPayload = {
        tripId: trip.id,
      };

      const tripDataPayload = {
        tripId: trip.id,
        location: { city: "", iata: "", cityIata: "", names: [] },
        isCity: false,
      };

      if (id === "src-location") {
        userInpPayload.input = { srcLocation: input, srcIata: "" };
        dispatch(searchDataActions.updateSrcLocation(tripDataPayload));
      }
      if (id === "dest-location") {
        userInpPayload.input = { destLocation: input, destIata: "" };
        dispatch(searchDataActions.updateDestLocation(tripDataPayload));
      }
      dispatch(searchDataActions.updateUserInputTrip(userInpPayload));
    } else {
      onClearTextInputHandler(id);
    }
  };

  const onClearTextInputHandler = (id) => {
    const payload = {
      tripId: trip.id,
      location: { city: "", iata: "", cityIata: "", names: [] },
      isCity: false,
    };
    if (id === "src-location") {
      dispatch(searchDataActions.updateSrcLocation(payload));
    } else if (id === "dest-location") {
      dispatch(searchDataActions.updateDestLocation(payload));
    }
  };

  const inputProps = {
    tripId: trip.id,
    onSwitchDirection: () => onSwitchDirection(trip.id),
    values: {
      src: typedUserInput.srcLocation,
      dest: typedUserInput.destLocation,
      srcIata: compareToValidatedValue(
        typedUserInput.srcLocation,
        typedUserInput.srcIata,
        trip.srcLocation,
        lang
      )
        ? trip.srcLocation.iata
        : "",
      destIata: compareToValidatedValue(
        typedUserInput.destLocation,
        typedUserInput.destIata,
        trip.destLocation,
        lang
      )
        ? trip.destLocation.iata
        : "",
    },
  };

  return (
    <div className="grid-row-multi">
      <SearchFormTextInputDesktop
        ref={ref}
        {...inputProps}
        onClick={onSearchFormClick}
        onChangeInput={onChangeTextInputHandler}
      />
      <SearchFormDate
        ref={ref}
        id="start-date"
        className={classes.startDate}
        value={trip.startDate}
        label={stringRes["sf.field.date.depart"]}
        onClick={() => onSearchFormClick("start-date", trip.id)}
      />
      {tripNum !== 1 && (
        <button type="button" className={classes.remove} onClick={() => onRemove(trip.id)}>
          <XIconDesktop />
        </button>
      )}
    </div>
  );
});
