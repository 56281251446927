import { useDeviceContext } from "src/context/device-context";
import DesktopBookingHeader from "../DesktopBookingHeader/DesktopBookingHeader";
import MobileBookingHeader from "../MobileBookingHeader/MobileBookingHeader";
import TravelSummary from "../TravelSummary/TravelSummary";
import { motion } from "framer-motion";

function LoadingBookingContent() {
  const { isMobile } = useDeviceContext();

  if (isMobile) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        id="booking-page"
        className="mobile">
        <MobileBookingHeader />
        <section className="booking-content">
          <section className="booking-content-loading">
            <div className="main-panel">
              {[3, 1, 2.25].map((i) => (
                <div key={i} className="main-panel-item">
                  <div
                    style={{
                      height: "1.75rem",
                      width: isMobile ? "80%" : i * 30 + "%",
                    }}></div>
                  <div
                    style={{
                      height: "3rem",
                      width: isMobile ? "100%" : "80%",
                      marginTop: "1rem",
                    }}></div>
                  <div style={{ height: "3rem", width: isMobile ? "100%" : "80%" }}></div>
                  <div
                    style={{
                      height: "1.5rem",
                      width: "30%",
                      marginTop: "1.5rem",
                    }}></div>
                </div>
              ))}
            </div>
          </section>
        </section>
      </motion.main>
    );
  }

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      id="booking-page"
      className="desktop">
      <DesktopBookingHeader isLoading />
      <TravelSummary />
      <section className="booking-content">
        <section className="booking-content-loading">
          <div className="main-panel">
            {[3, 1, 2.25].map((i) => (
              <div key={i} className="main-panel-item">
                <div style={{ height: "1.75rem", width: i * 30 + "%" }}></div>
                <div style={{ height: "3rem", width: "80%", marginTop: "1rem" }}></div>
                <div style={{ height: "3rem", width: "80%" }}></div>
                <div style={{ height: "1.5rem", width: "30%", marginTop: "1.5rem" }}></div>
              </div>
            ))}
          </div>
          <div className="side-panel">
            <div style={{ height: "1.5rem", width: "10rem" }}></div>
            <div style={{ height: "3rem", marginTop: "1rem" }}></div>
            <div style={{ height: "3rem" }}></div>
            <div style={{ height: "2.5rem", marginTop: "2.5rem", width: "10rem" }}></div>
            {[1, 2, 3].map((i) => (
              <div key={i} style={{ height: "1.5rem" }}></div>
            ))}
            <div style={{ height: "1.5rem", marginTop: "1.5rem", width: "10rem" }}></div>
          </div>
        </section>
      </section>
    </motion.main>
  );
}

export default LoadingBookingContent;
