import { useSelector } from "react-redux";
import classes from "./TravelSummary.module.css";
import { selectTravelPackage } from "src/store/booking";
import { getFirstFlightChain, getLastFlightChain, isRoundTrip } from "src/utils/results-utils";
import { Fragment } from "react";
import { LongArrow } from "src/components/UI/Arrow/Arrow";

function TravelSummary() {
  const travelPackage = useSelector(selectTravelPackage);
  if (!travelPackage) return;

  const isReturn = isRoundTrip(travelPackage);
  const firstChain = getFirstFlightChain(travelPackage);
  const lastChain = getLastFlightChain(travelPackage);

  return (
    <section className={`booking-summary ${classes.summary}`}>
      <h1>
        {isReturn && (
          <Fragment>
            {firstChain[0].dep.city.title}
            <LongArrow />
            {firstChain[firstChain.length - 1].arr.city.title}
            <LongArrow />
            {lastChain[lastChain.length - 1].arr.city.title}
          </Fragment>
        )}

        {!isReturn && (
          <Fragment>
            {firstChain[0].dep.city.title}
            <LongArrow />
            {lastChain[lastChain.length - 1].arr.city.title}
          </Fragment>
        )}
      </h1>
    </section>
  );
}

export default TravelSummary;
