import classes from "./CarouselIndicatorGroup.module.css";

function CarouselIndicatorGroup({ count, current }) {
  const indicators = [];
  for (let i = 0; i < count; i++) {
    indicators.push(
      <div
        key={i}
        className={`${classes.indicator} ${
          i === current && classes.active
        }`}
      />
    );
  }
  return <div className={classes.indicatorGroup}>{indicators}</div>;
}

export default CarouselIndicatorGroup;
