import classes from "./SearchFormDate.module.css";
import { useEffect, useState } from "react";
import iconCalendar from "src/assets/img/calendarIcon.png";
import { getDisplayDateValue } from "src/utils/date-utils";
import { forwardRef } from "react";
import { useDeviceContext } from "src/context/device-context";
import { XIconDesktop, XIconMobile } from "src/components/UI/Icon/XIcon";
import { useTenantContext } from "src/context/tenant-context";

const SearchFormDate = forwardRef(function SearchFormDate(
  { id, value, label, onClick, className, onClear },
  ref
) {
  const { isMobile } = useDeviceContext();
  const { dateFormat } = useTenantContext();
  const [hasInput, setHasInput] = useState(!!value);

  const onClickIcon = (e) => {
    e.stopPropagation();
    onClear(id);
  };

  useEffect(() => {
    setHasInput(!!value);
  }, [value]);

  const setRef = (r) => {
    if (ref) {
      ref[id] = r;
    }
  };

  return (
    <div
      ref={(r) => setRef(r)}
      className={`${classes.frame} ${className ?? ""} frame-sf-field`}
      onClick={onClick}>
      <div className={classes.dateItem}>
        <label
          htmlFor={id}
          className={
            hasInput
              ? onClick
                ? classes.smallText
                : `${classes.smallText} ${classes.datePicker}`
              : classes.bigText
          }>
          <span>{label}</span>
        </label>
        <input
          id={id}
          type="text"
          readOnly
          value={getDisplayDateValue(value, dateFormat)}
          hidden={!value}
        />
      </div>

      {hasInput && onClear ? (
        <button type="button" className={classes.icon} onClick={onClickIcon}>
          {isMobile ? <XIconMobile /> : <XIconDesktop />}
        </button>
      ) : (
        <button type="button" className={classes.icon}>
          <img alt="" width={20} src={iconCalendar} />
        </button>
      )}
    </div>
  );
});

export default SearchFormDate;
