import classes from "./BackButton.module.css";

function BackArrow({ invertColor }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.979"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 7.72113C17 7.90322 17 8.08527 17 8.26736C16.8918 8.64801 16.6705 8.88858 16.3359 8.98917C11.9531 9.00216 7.57031 9.01519 3.1875 9.02818C4.88813 10.8042 6.59809 12.5665 8.31738 14.3149C8.51912 14.6837 8.55233 15.0739 8.41699 15.4854C8.11929 15.9834 7.7264 16.1199 7.23828 15.8951C4.88763 13.5101 2.54682 11.1106 0.21582 8.69654C0.13412 8.55624 0.0621795 8.41317 0 8.26736C0 8.08527 0 7.90322 0 7.72113C0.0866047 7.49491 0.208349 7.29335 0.365234 7.11638C2.58861 4.85494 4.80213 2.579 7.00586 0.288495C7.47887 -0.126317 7.92711 -0.0938003 8.35059 0.386036C8.55867 0.824577 8.54761 1.25376 8.31738 1.67358C6.59809 3.42202 4.88813 5.18428 3.1875 6.96031C7.57031 6.9733 11.9531 6.98633 16.3359 6.99933C16.6705 7.09991 16.8918 7.34049 17 7.72113Z"
        fill={invertColor ? "white" : "var(--primary-1)"}
      />
    </svg>
  );
}

function BackButton({ onClick, invertColor }) {
  return (
    <button className={classes.back} onClick={onClick}>
      <BackArrow invertColor={invertColor} />
    </button>
  );
}

export default BackButton;
