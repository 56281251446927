import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "src/context/auth-context";

const withAuthControl = (Component) => (props) => {
  const { isAuthenticated, loaded } = useAuth();

  if (loaded) {
    if (!isAuthenticated) {
      return <Navigate to="/" replace />;
    }

    return <Component {...props} />;
  }
};

withAuthControl.displayName = "withAuthControl";
export default withAuthControl;
